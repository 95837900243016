import React, { Component } from "react";
import styled, { css } from "styled-components";
import ComponentGoodItem from "../ComponentGoodItem";
import { ThemeContext } from '../../contexts';
import { Container } from "@material-ui/core";

function OtherGoods(props) {
  return (
    <ThemeContext.Consumer>
      {(context) => {
        let goods = context.base.goods
          return(
              <RectOtherGoods
              style={{
                width: document.body.clientWidth < 450 ? 'calc(100vw - 30px)' : 600,
              }}>
                <RectOtherGoodsTxt>Другие товары</RectOtherGoodsTxt>
                <ContainerGoods
                  style={{
                    width: document.body.clientWidth < 450 ? 'calc(100vw - 48px)' : 576,
                  }}>
                  {goods.map((item, index) =>
                    <ComponentGoodItem
                      updateCabinet={()=>{props.updateCabinet()}}
                      route={props.route}
                      key={index}
                      item={item}
                      style={{
                        width: document.body.clientWidth < 450 ? 'calc(100vw - 60px)' : 550,
                        height: 300,
                        backgroundColor: "rgba(192,189,189,1)",
                        margin: 8
                      }}
                    />
                  )}
                  </ContainerGoods>
              </RectOtherGoods>
          )
        }
      }        
    </ThemeContext.Consumer>
  );
}

const ContainerGoods = styled.div`
  width: 576px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  display: flex;
`;

const RectOtherGoods = styled.div`
  width: 600px;
  min-height: 450px;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  overflow-y: scroll;
  display: flex;
`;

const RectOtherGoodsTxt = styled.span`
  font-family: Roboto-500;
  margin: 8px;
  height: 26px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 26px;
  font-weight: regular;
  font-style: normal;
  line-height: 26px;
  text-align: center;
`;


export default OtherGoods;
