import React, { Component } from 'react';
import styled, { css } from "styled-components";
import PropTypes from 'prop-types';
import MaterialCommunityIconsIcon from "react-native-vector-icons/dist/MaterialCommunityIcons";

class VkAuth extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isProcessing: false,
        }
    }

    componentDidMount() {
        if(document.getElementById('vk-sdk')) { this.sdkLoaded() };
        this.asyncInit();
        this.loadSdkAsync();
    }

    asyncInit() {
        const { apiId } = this.props;
        window.vkAsyncInit = () => {
        window.VK.init({ apiId });
        this.setState({ isLoaded: true });
        };
    }

    sdkLoaded() {
        this.setState({ isLoaded: true });
    }

    loadSdkAsync() {
        const el = document.createElement('script');
        el.type = 'text/javascript';
        el.src = 'https://vk.com/js/api/openapi.js?';
        el.async = true;
        el.id = 'vk-sdk';
        document.getElementsByTagName('head')[0].appendChild(el);
    }

    checkLoginState = (response) => {
        window.VK.Api.call('users.get', {
            "fields": "photo_400_orig", 
            "v": "5.120"
            }, (data) => {
                response = {...response, ...data}
               this.setState({ isProcessing: false });
               if (this.props.callback) { this.props.callback(response) };
            })

    };

    handleClick = () => {
        if (!this.state.isLoaded || this.state.isProcessing || this.props.disabled) {
        return;
        }
        this.setState({ isProcessing: true });
        window.VK.Auth.login(this.checkLoginState, 4194308);
    };

    render() {
        const { disabled, callback, apiId, containerStyle, ...buttonProps } = this.props;
        return (
            <div
            {...buttonProps}
            onClick={this.handleClick}
                className='ButtonAuth'
            >
                <MaterialCommunityIconsIcon
                    name="vk"
                    style={{
                        color: "rgba(53,14,14,1)",
                        fontSize: 40
                    }}
                />
        {/* <span style={containerStyle}>
     
        </span> */}

            </div>
        );
    }
}

VkAuth.propTypes = {
  disabled: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  apiId: PropTypes.string.isRequired,
  containerStyle: PropTypes.object
};


const ButtonVK = styled.div`
//display: flex;
  height: 40px;
  background-color: rgba(215,213,21,1);
  flex-direction: row;
  border-radius: 40px;
  width: 40px;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: nowrap;
  justify-content: center;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
  cursor: pointer;
`;

export default VkAuth;
