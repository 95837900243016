import React, { Component } from "react";
import styled, { css } from "styled-components";
import axi from '../functions/axiosf';
import { ThemeContext } from '../contexts';
import EntypoIcon from "react-native-vector-icons/dist/Entypo";

class ApprovingMessages extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      mess: []
    }
  }

  componentDidMount() {
  }


  _approvingAdminForThisCity(item){
    if (((this.context.user != null) && this.context.user.role == 'root')){
      let p = {
        city: +item.city.idCity,
        userID: +item.userID,
        uid: this.context.user.id,
      }
      axi("approveCityToAdmin.php", "null", p).then((result) => {
        if (result != null) {
          if (result.type === 'nobody') {
            alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
            this.context.addUser(null)
          } else {
            if (result.cities != undefined) {
              this.context.addCities(result.cities)
            } 
            if (result.approved != undefined) {
              this.props.setApprovingMessages(result.approved)
            }
          }
        }
      },
        (e) => { console.log(e) })
    }
  }



  _rejectAdminForThisCity(item){
      let p = {
        city: item, uid: this.context.user.id
      }
      axi("deleteCityToRoot.php", "null", p).then((result) => {
        if (result != null) {
          if (result.cities != undefined) {
            this.context.addCities(result.cities)
          }
          if (result.approved != undefined) {
            this.props.setApprovingMessages(result.approved)
          }
        }
      },
        (e) => { console.log(e) })
  }

  render(){
    return <Container style={document.body.clientWidth < 900 ?
      {
        ...this.props.style,
        width: document.body.clientWidth - 40,
        margin: 0,
      }
      : {...this.props.style}}>
      <p style={{ fontFamily: 'roboto-700',
                  height: 'auto',
                  color: "rgba(228,212,212,1)",
                  fontSize: 19,
                  margin: 12}}>{"Одобрение прав доступа к админке городов"}</p>
      {this.props.mess.map((item,index)=>
        <div
            key={index}
            style={{
              margin: 10,
              marginBottom: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#0009',
              borderRadius: 10,
              height: 90,
              width: 270,
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'flex-start',
                borderRadius: 10,
                height: 80,
                width: 200,
                }}>
                  <b style={{ color: 'rgba(228,212,212,1)', marginLeft: 10, }}>{item.name}</b>
                  <span style={{ color: '#ffcb17', marginLeft: 10, }}>{"просит открыть доступ к"}</span>
                  <span style={{ color: 'rgba(228,212,212,1)', marginLeft: 10, }}>{item.city.name}</span>
              </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'flex-end',
                borderRadius: 10,
                height: 80,
                width: 65,
                }}>
              <EntypoIcon
                name="add-user"
                style={{
                  color: "#ffcb17",
                  fontSize: 30,
                  marginRight: 10,
                  cursor: "pointer"
                }}
                onClick={() => { this._approvingAdminForThisCity(item) }}
              />
              <EntypoIcon
                name="remove-user"
                style={{
                  color: "rgba(53,14,14,1);",
                  fontSize: 30,
                  marginRight: 10,
                  cursor: "pointer"
                }}
                onClick={() => { this._rejectAdminForThisCity(item) }}
              />
              </div>
          </div>        
      )
      }
    </Container>
  }
}
ApprovingMessages.contextType = ThemeContext;

const Container = styled.div`
  background-color: rgba(4,4,4,0.5);
  margin: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  align-items: stretch;
`;

export default ApprovingMessages;
