import React, { Component } from "react";
import styled, { css } from "styled-components";

function Chat(props) {
  return <Container {...props}></Container>;
}

const Container = styled.div`
  background-color: rgba(4,4,4,0.5);
  margin: 20px;
  border-radius: 20px;
`;

export default Chat;
