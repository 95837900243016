import React, { Component } from "react";
import styled, { css } from "styled-components";
import DataSelector from "./DataSelector";
import EntypoIcon from "react-native-vector-icons/dist/Entypo";
import { ThemeContext } from '../contexts';
import axi from '../functions/axiosf';
import russia from '../assets/maps/russia.json';
import france from '../assets/maps/france-new.json'
import ukraine from '../assets/maps/ukraine.json'
import belarus from '../assets/maps/belarus.json'
import YouTube from 'react-youtube';
let citiesBase = [...russia.layers, ...ukraine.layers, ...belarus.layers, ...france.layers];

class AddAboutCityComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      idRegion: '',
      concertDay: null,
      description: '',
      fullDescription: '',
      cityPhotos: [],
      cityVideos: [],
      success: false,

      cityName: "",
      cityID: "",
      selectionCity: [],
      cityIdStyleSelectionsProcess: '',
      cityIdStyleSelected: '',
      selectedRegion: [],
      txtColor1: '#d4e4d4',
      newPhotoUrl: '',
      newVideoUrl: '',
    }
  }

  componentDidMount(){
    if (typeof(this.props.tab) == "number") {
      for(let i=0; i<this.context.cities.length; i++){
        if (+this.context.cities[i].idCity == this.props.tab) { 
          let selectedRegion = []
          citiesBase.map((item, index) => {
            if (item.id == this.context.cities[i].idRegion) {
              selectedRegion = item
            }
          })
          this.setState({
            ...this.context.cities[i], 
            concertDay: +this.context.cities[i].concertDay,
            selectedRegion: selectedRegion,
            cityName: selectedRegion.name
          }) 
        }
      }
    }else{
      this.setState({
        concertDay: +new Date(),
      }) 
    }

  }

  axiAdd(url){
    if(!this.state.success)
    {
      let p ={
        ...this.state,
        uid: this.context.user.id,
        tab: this.props.tab,
      }
      axi(url, "null", p).then((result) => {
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с другого устройства. Для подтверждения, что это Вы войдите заново')
          this.context.addUser(null)
        } else {
          if(result.type == "error"){
            alert('Ошибка добавдения города '+this.state.name)
          }else{
            alert(' ' + this.state.name + ' отредактирован успешно')
          }
        }
        if (url == 'addNewCity.php'){
          this.setState({
            name: '',
            idRegion: '',
            concertDay: null,
            description: '',
            fullDescription: '',
            cityPhotos: [],
            cityVideos: [],
            success: true,
          })
        }

      },
        (e) => { console.log('ошибка создание нового города', e) })
    } 
  }

  _textChangeSelectionCity = (value) => {
    let includes = []
    if (value != '') {
      citiesBase.map((item, index) => {
        if (item.name.toLowerCase().includes(value.toLowerCase())) {
          includes = [...includes, item]
        }
      })
    }
    if (includes.length==1){
      this.setState({
        cityName: value,
        selectionCity: (includes[0].name == value) ? [] : includes,
        idRegion: includes.id,
        regionWarning: false,
        selectedRegion: includes,
        txtColor1: '#d4e4d4'
      })
    }else{
      this.setState({
        cityName: value,
        selectionCity: includes,
        idRegion: '',
        regionWarning: false,
        selectedRegion: [],
        txtColor1: '#000'
    })
  }
  }

  _textSelectedCity = (item) => {
    this.setState({
      selectedRegion: item,
      idRegion: item.id,
      regionWarning: false,
      selectionCity: [],
      cityName: item.name,
      //txtColor1: '#000'
    })
  }

  _delPhoto = (item, index) => {
    console.info(index)
    let copy = this.state.cityPhotos
    copy.splice(index, 1)
    this.setState({ cityPhotos: copy})
  }

  _delVideo = (item, index) => {
    console.info(index)
    let copy = this.state.cityVideos
    copy.splice(index, 1)
    this.setState({ cityVideos: copy })
  }

  _onReadyVideo(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  _getYoutubeID(link) {
    return link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
  }

  render(){
  return (
    <Container
      style={this.props.style}
    >
      <RectCityсolumnDescription
        style={document.body.clientWidth < 900 ?
          {
            width: document.body.clientWidth - 58,
          }
          : {}}
      >
        <TitleRegion>Регион</TitleRegion>
        <TextInputRegion 
          style={{backgroundColor: this.state.regionWarning && '#ff00004f'}}
          placeholder="начните вводить регион"
          value={this.state.cityName}
          onChange={(e) => { this._textChangeSelectionCity(e.target.value) }}
          ></TextInputRegion>
        <div
          className={'selectionCityCont'}
          style={{ 
            maxHeight: document.body.clientWidth > 1360 ? 0 : 600 ,
            backgroundColor: '#5559'
            }}
        >
          {this.state.selectionCity.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  width: 320,
                  height: 35,
                  marginTop: 3,
                  display: "flex",
                  alignItems: 'center',
                  backgroundColor: (this.state.cityName == item.name) && '#ffcb17',
                }}
                className={'selectionCityDiv'}
                onClick={() => {
                  this._textSelectedCity(item)
                }}
              >
                <p style={{}}
                  className={'selectionCityText'}
                  style={{ color: (this.state.cityName == item.name) && '#000' }}
                >{item.name}</p>
              </div>
            )
          })}
        </div>
        <TitleCity>Название города</TitleCity>
        <TextInputCity style={{ backgroundColor: this.state.cityWarning && '#ff00004f' }}
          placeholder="введите город"
          value={this.state.name}
          onChange={(e) => { this.setState({ name: e.target.value, cityWarning: false})}}
        ></TextInputCity>
        <TitleDateConcert>Дата проведения концерта</TitleDateConcert>
        <DataSelector
          style={{
            width: 235,
            height: 35,
            alignSelf: "center",
            marginTop: 8,
            marginBottom: 8
          }}
          colorBack={'#fff7'}
          setDate={(concertDay) => this.setState({ concertDay: concertDay})}
          concertDay={this.state.concertDay}
        ></DataSelector>
        <TitleBriefDescription>Краткое описание</TitleBriefDescription>
        <TextInputBriefDescription
          placeholder="Введите ключевые данные о концерте"
          maxLength={255}
          numberOfLines={0}
          value={this.state.description}
          onChange={(e) => { this.setState({ description: e.target.value }) }}
        ></TextInputBriefDescription>
        <TitleFullDescription>Подробное описание</TitleFullDescription>
        <TextInputFullDescription
          placeholder="Опишите в подробностях детали проводимого фестиваля в городе"
          maxLength={4096}
          value={this.state.fullDescription}
          onChange={(e) => { this.setState({ fullDescription: e.target.value }) }}
        ></TextInputFullDescription>
      </RectCityсolumnDescription>
      <RectCityсolumnMedia
        style={document.body.clientWidth < 900 ?
          {
            width: document.body.clientWidth - 58,
          }
          : {}}>
        <TitlePhoto>Фото</TitlePhoto>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
          <TextInputCity
            style={{ alignSelf: 'flex-start', width: 230}}
            placeholder="вставьте ссылку на фото"
            value={this.state.newPhotoUrl}
            onChange={(e) => { this.setState({ newPhotoUrl: e.target.value }) }}
          ></TextInputCity>
        {this.state.newPhotoUrl != '' &&
          <EntypoIcon
              name="squared-plus"
              style={{
                color: "rgba(211,149,0,1)",
                fontSize: 40,
                margin: 5,
                width: 40,
                height: 40,
                //marginRight: 40,
                //marginLeft: 40,
                cursor: "pointer"
              }}
              onClick={() => { this.setState({ cityPhotos: [this.state.newPhotoUrl, ...this.state.cityPhotos], newPhotoUrl: '' })}}
            ></EntypoIcon>
          }
        </div>  
        <RectPhotos>
          {this.state.cityPhotos.map((item,index)=>{
            return (
              <RectPhoto 
                key={index}
                style={{ 
                  backgroundImage: 'url(' + item + ')', 
                  backgroundSize: 'contain', 
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',}} >
                <EntypoIcon
                  name="circle-with-cross"
                  style={{
                    fontSize: 20,
                    color: '#ffcb17',
                    margin: 4,
                    cursor: 'pointer'
                  }}
                  onClick={() => this._delPhoto(item, index)}
                ></EntypoIcon>
              </RectPhoto>)}
            )}
        </RectPhotos>
        <TitleVideo>Видео</TitleVideo>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <TextInputCity
            style={{ alignSelf: 'flex-start', width: 230 }}
            placeholder="вставьте ссылку на видео"
            value={this.state.newVideoUrl}
            onChange={(e) => { this.setState({ newVideoUrl: e.target.value }) }}
          ></TextInputCity>
          {this.state.newVideoUrl != '' &&
          <EntypoIcon
            name="squared-plus"
            style={{
              color: "rgba(211,149,0,1)",
              fontSize: 40,
              margin: 5,
              width: 40,
              height: 40,
              cursor: "pointer"
            }}
            onClick={() => { this.setState({ cityVideos: [this._getYoutubeID(this.state.newVideoUrl), ...this.state.cityVideos], newVideoUrl: '' }) }}
          ></EntypoIcon>
          }
        </div>  
        <RectVideos
        
        >
          <div style={{
            width: this.state.cityVideos.length*165,
            display: 'flex',
            flexDirection: "row",
            }}>
            {this.state.cityVideos.map((item, index) => { return (
              <div style={{position: 'relative'}}>
                <YouTube 
                  className={'smallVideo'}
                  videoId={item[1]} 
                  onReady={this._onReadyVideo} 
                  key={index}
                >
                  
                </YouTube>     
                <EntypoIcon
                  name="circle-with-cross"
                  style={{
                    fontSize: 20,
                    color: '#ffcb17',
                    margin: 4,
                    cursor: 'pointer',
                    position: 'absolute',
                    right: 5,
                    top: 0,
                    zIndex: 10,
                  }}
                  onClick={() => this._delVideo(item, index)}
                ></EntypoIcon>
              </div>       
              ) })}
          </div>
        </RectVideos>
        <div
          className={'ButtonSupport'}
          style={document.body.clientWidth < 900 ?
            {
              width: document.body.clientWidth - 88,
            }
            : {}
          }
          onClick={() => { 
              let f = false, fr = false
              if (this.state.name.length>1){
                fr=true
              } else { this.setState({ cityWarning: true }) }
              if (this.state.idRegion.length>3){
                f=true
              }
              else{ this.setState({regionWarning: true}) }
              if (f&&fr) {this.axiAdd(this.props.clickAddFunc)}
            }}
          >
          <span className='ButtonSupportText' >{(this.props.clickAddFunc == 'editedCity.php')?'Изменить':'Добавить'}</span>

        </div>
      </RectCityсolumnMedia>
    </Container>
  )}
}

AddAboutCityComponent.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  background-color: rgba(4,4,4,0.5);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
`;

const RectCityсolumnDescription = styled.div`
  width: 300px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 15px;
  display: flex;
`;

const TitleRegion = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputRegion = styled.input`
  font-family: Roboto;
  height: 24px;
  background-color: #000000a6 ;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: regular;
  font-style: normal;
  line-height: 16px;
  border: none;
`;

const TitleCity = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputCity = styled.input`
  font-family: Roboto;
  height: 24px;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: regular;
  font-style: normal;
  line-height: 16px;
  border: none;
`;

const TitleDateConcert = styled.span`
  font-family: Roboto;
  width: 256px;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TitleBriefDescription = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputBriefDescription = styled.textarea`
  font-family: Roboto;
  height: 54px;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: regular;
  font-style: normal;
  line-height: 14px;
  border: none;
`;

const TitleFullDescription = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputFullDescription = styled.textarea`
  font-family: Roboto;
  height: 104px;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: regular;
  font-style: normal;
  line-height: 14px;
  border: none;
`;

const RectCityсolumnMedia = styled.div`
  width: 300px;
  flex-direction: column;
  align-self: stretch;
  justify-content: flex-start;
  margin: 0px;
  padding: 15px;
  display: flex;
`;

const TitlePhoto = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const RectPhotos = styled.div`
  flex: 1 1 0%;
  height: 100px;
  flex-direction: row;
  //align-self: stretch;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
      overflow-y: scroll;
`;

const RectPhoto = styled.div`
  width: 120px;
  height: 80px;
  background-color: rgba(4,4,4,1);
  border-radius: 8px;
  margin-bottom: 10px;
`;

const TitleVideo = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const RectVideos = styled.div`
  height: 120px;
  //width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  overflow-x: scroll;
`;


export default AddAboutCityComponent;
