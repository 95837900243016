import React, { Component } from "react";
import styled, { css } from "styled-components";
import  StyledLink  from "./StyledLink";
import { useHistory } from "react-router-dom";
import axi from '../functions/axiosf';
import AvaIcon from './ui/AvaIcon'
import { ThemeContext } from '../contexts'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      visibilityLogInBlackOut: 'hidden',
      txtColorHome: '#d4e4d4',
      txtColorCities: '#d4e4d4',
      txtColorShop: '#d4e4d4',
      txtColorNews: '#d4e4d4',
      txtColorLogIn: '#d4e4d4',
      txtColorFAQ: '#d4e4d4',

    }
  }

  componentDidMount() {
    //console.log(this.props)
  }
 
_goTo = (to) => {
  this.context.toggleLandingAnchor(to)
  if (this.props.scrollLand) {
    this.props.scrollLand()
  }
  this.props.route.history.push('/')
}

_goToAdmin=()=>{
  let p = {
    uid: this.context.user.id,
  }
  axi("updateUserRole.php", "null", p).then((result) => {
    if (result != null) {
      if (result.type === 'nobody') {
        alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
        this.context.addUser(null)
      } else {
        this.context.addUser(result.user)
          if (result.user.role == 'admin' || result.user.role == 'root') {
            this.props.route.history.push('/Admin')
          }else{
            this.props.route.history.push({ pathname: '/Cabinet', state: { tab: "history" } })
          }
      }
    }
  },
    (e) => { console.log(e) })

}

  render(){
    let mobW=0
    if (this.state.open){
      mobW=170
    }
  return (
    <div>
      <div className="burgerButton"
      onClick={()=>{this.setState({open: !this.state.open})}}
      >
        <div className="centerized" data-open={this.state.open+''}>
          <div className="bar1"> </div>
          <div className="bar2"> </div>
          <div className="bar3"> </div>
        </div>
      </div>
    <Container
      className= 'headerContainer'
      style={{
          position: "absolute",
          zIndex: 100,
          width: document.body.clientWidth<450 ? mobW: '100vw',
        }}>
      <HeadButtonHome 
        className='HeadButton'
        onMouseMove ={() => { this.setState({ txtColorHome: '#ffcb17'})}} 
        onMouseLeave ={() => { this.setState({ txtColorHome: '#d4e4d4' }) }} 
        onClick={() => {this._goTo('Top')}}
        >
          <MenuHomeTxt3 style={{ color: this.state.txtColorHome}}> Главная</MenuHomeTxt3>
        
      </HeadButtonHome>
      <HeadButtonCities
        className='HeadButton'
        onMouseMove={() => { this.setState({ txtColorCities: '#ffcb17' }) }}
        onMouseLeave={() => { this.setState({ txtColorCities: '#d4e4d4' }) }}
        onClick={() => {
          this._goTo('Map')
        }}
        >
          <MenuCitiesTxt3 style={{ color: this.state.txtColorCities }}>Города</MenuCitiesTxt3>
       
      </HeadButtonCities>
      <HeadButtonShop
        className='HeadButton'
        onMouseMove={() => { this.setState({ txtColorShop: '#ffcb17' }) }}
        onMouseLeave={() => { this.setState({ txtColorShop: '#d4e4d4' }) }} 
        onClick={() => {
          this._goTo('Shop')
        }}
        >    
          <MenuShopTxt3 style={{ color: this.state.txtColorShop }}>Витрина</MenuShopTxt3>  
      </HeadButtonShop>
      <HeadButtonNews
        className='HeadButton'
        onMouseMove={() => { this.setState({ txtColorNews: '#ffcb17' }) }}
        onMouseLeave={() => { this.setState({ txtColorNews: '#d4e4d4' }) }}
        onClick={() => {
          this._goTo('News')
        }}
        >
          <MenuNewsTxt3 style={{ color: this.state.txtColorNews }}>Новости</MenuNewsTxt3>
        
      </HeadButtonNews>
      <HeadButtonLogIn
        className='HeadButton'
        onMouseMove={() => { this.setState({ txtColorLogIn: '#ffcb17' }) }}
        onMouseLeave={() => { this.setState({ txtColorLogIn: '#d4e4d4' }) }}
        onClick={() => { 
          if (this.context.user == null) {this.context.toggleVisibilityLogInBlackOut()} 
          }}
        >
        {(this.context.user != null) ?
            <AvaIcon 
            style={{ 
              borderColor: this.state.txtColorLogIn,
              backgroundImage: this.context.user.avatar ? 'url(' + this.context.user.avatar + ')' : 'url("https://yt3.ggpht.com/a/AATXAJxe7LZyFkx72Rt-xxiJswJwp-3UKM0VrPl_hoqe=s900-c-k-c0xffffffff-no-rj-mo")'
              }}
              onClick = {()=> {
                this._goToAdmin()
              }} 
            /> 
            :
            <TxtSignIn style={{ color: this.state.txtColorLogIn }}>Войти</TxtSignIn>
        }
      </HeadButtonLogIn>
{(this.context.user != null) &&
      <HeadButtonLogIn
        className='HeadButton'
        onMouseMove={() => { this.setState({ txtColorLogIn: '#ffcb17' }) }}
        onMouseLeave={() => { this.setState({ txtColorLogIn: '#d4e4d4' }) }}
        onClick={() => { 
            this._goTo('Top')
            this.context.addUser(null)            
          }}
        >
            <TxtSignIn style={{ color: this.state.txtColorLogIn }}>Выйти</TxtSignIn>
      </HeadButtonLogIn>
}
{/*          <ButtonSupport
          className={'ButtonSupport'}>
          <TxtAst2 className='ButtonSupportText' >Помочь проекту</TxtAst2>
        </ButtonSupport> 
*/}
      <a href={'https://vk.com/rockprotectsanimals'} 
          target={'_blank'} 
          style={{ textDecoration: 'none' }} 
          className={'ButtonSupport'}>
          <TxtContactButton className='ButtonSupportText' >Связаться с нами</TxtContactButton>
      </a>
      <HeadButtonCities1
          className='HeadButton'
          onMouseMove={() => { this.setState({ txtColorFAQ: '#ffcb17' }) }}
          onMouseLeave={() => { this.setState({ txtColorFAQ: '#d4e4d4' }) }} 
          onClick={() => {
            this.props.route.history.push({
              pathname: '/Blog',
              state: { item: this.context.base.faq[0], other: this.context.base.faq, type: 'faq' }
            })
          }
          }
        >
            <Faq style={{ color: this.state.txtColorFAQ }}>FAQ</Faq>

      </HeadButtonCities1>
        <HeadButtonCities1
          className='HeadButton'
        >
          <a href={'https://президентскиегранты.рф/'} target={'_blank'} ><img src={require("../assets/images/pgrants_logo_gp-horizontal_dark.png")} height={'60'} width={'170'}/></a>
        </HeadButtonCities1>
    </Container>
    </div>
  );
}
}
Header.contextType = ThemeContext;
const Container = styled.div``;


const HeadButtonHome = styled.div``;

const MenuHomeTxt3 = styled.span`
  font-family: roboto-700;
  height: 20px;
  flex: 1 1 0%;
  //color: #d4e4d4;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  transition: 2s;
`;

const HeadButtonCities = styled.div``;

const MenuCitiesTxt3 = styled.span`
  font-family: roboto-700;
  height: 20px;
  flex: 1 1 0%;
  color: rgba(228,212,212,1);
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  transition: 2s;
`;

const HeadButtonShop = styled.div`/* 
  height: 48px;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  margin-right: 8px;
  margin-left: 8px;
  padding: 14px;
  border: none;
  cursor: pointer
 */`;

const MenuShopTxt3 = styled.span`
  font-family: roboto-700;
  height: 20px;
  flex: 1 1 0%;
  color: rgba(228,212,212,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  transition: 2s;
`;

const HeadButtonNews = styled.div``;

const MenuNewsTxt3 = styled.span`
  font-family: roboto-700;
  height: 20px;
  flex: 1 1 0%;
  color: rgba(228,212,212,1);
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  transition: 2s;
`;

const HeadButtonLogIn = styled.div``;

const TxtSignIn = styled.div`
  font-family: roboto-700;
  width: 57px;
  height: 20px;
  color: rgba(228,212,212,1);
  font-size: 20px;
  font-weight: 600;
  transition: 2s;
`;


const ButtonSupport = styled.div``;

const TxtAst2 = styled.span``;


const TxtContactButton = styled.span`
  font-family: roboto-500;`;

const HeadButtonCities1 = styled.div``;

const Faq = styled.span`
  font-family: roboto-100;
  color: rgba(255,255,255,1);
  font-size: 20px;
  font-weight: 600;
  left: 14px;
  top: 20px;
  align-self: flex-start;
  text-align: center;
  transition: 2s;
`;

export default Header;
