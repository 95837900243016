import React from "react";
import styled, { css } from "styled-components";
import FontAwesomeIcon from "react-native-vector-icons/dist/FontAwesome"; 
import StyledLink from "../StyledLink";
import { ThemeContext } from '../../contexts';
import axi from '../../functions/axiosf'
//import '../../functions/tinkoff_v2.js'
import Tinkoff from 'react-tinkoff-pay'


let colors = {
  inputValidateComplete: '#50fb5980',
  inputBackGround: '#e6e6e680',
}



class PayBlackOut extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      warning: false,
      errorText: '',
      
    }
  }

  render() {
/* 
    Tinkoff.Link({	
        terminalkey: 'TinkoffBankTest',
        language: 'ru',
        amount: this.props.amount,
        order: this.props.payID,
        description: this.props.calcText,
        name: this.context.user.name,
        email: this.context.user.email,
        phone: this.context.user.phone
    }, link => {
        console.log(link) // => https://securepay.tinkoff.ru/xo7L8v
    })
     */
    let formArr = {	
        terminalkey: 'TinkoffBankTest',
        language: 'ru',
        frame: 'true',
        amount: this.props.amount,
        order: this.props.payID,
        description: this.props.calcText,
        name: this.context.user.name,
        email: this.context.user.email,
        phone: this.context.user.phone
    }
  let styleFormArr = {
    payFormContainer:{
        zIndex: '250', 
        height: '100%', 
        width: '100%', 
        position: 'absolute', 
        left: '0px', 
        top: '0px',
        transition: '1s',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    darkBackground:{
        position: "absolute",
        height: '100vh',
        width: '100vw',
        zIndex: '251',
        backgroundColor: 'rgba(0,0,0,0.8)',
    },
    div_frame:{
        height: '590px', 
        width: '360px', 
        maxWidth: '1200px', 
        position: 'relative', 
        zIndex: '9998', 
        border: '0px',
        transition: '1s'
    },
    payFormIframe:{
        width: '100%', 
        height: '100%', 
        border: 'none',
        borderRadius: '20px',
        left: '0%', 
        position: 'relative', 
        backgroundColor: 'white'
    },
    closeButton:{
        top: '4px', 
        width: '48px', 
        height: '32px', 
        right: '50px', 
        background: 'url("https://securepay.tinkoff.ru/html/payForm/img/close-button.png") no-repeat', 
        position: 'absolute', 
        zIndex: '9999', 
        cursor: 'pointer', 
        border: 'none', 
        visibility: 'visible'
    }
  }
  return (
    <Container {...this.props}
      style={{
        height: this.props.visible ? '100vh' : 0
      }}>
{/* 
      <div 
        style={{
            position: "absolute",
            height: this.props.visible ? '100vh' : 0,
            width: '100vw',
            zIndex: 1,
            backgroundColor: this.context.visibilityLogInBlackOut ? 'rgba(0,0,0,0.8)' : 'transparent',
            transition: '1s',
          }}
          onClick={() => { this.props.toggleVisibilityPayInBlackOut() }}
      />

 */}
      <Tinkoff.Pay 
          form={formArr} 
          onClose={() => this.props.toggleVisibilityPayInBlackOut()} 
          style={styleFormArr}
      />
        {/* <script src="https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js"></script> 
        <form name="TinkoffPayForm" onsubmit="pay(this); return false;">
          <Rect93>
          <TextInput3 class="tinkoffPayRow" type="hidden" name="terminalkey" value={"TinkoffBankTest"}/>
          </Rect93>
          <TextInput3 class="tinkoffPayRow" type="hidden" name="frame" value="true"/>
          <TextInput3 class="tinkoffPayRow" type="hidden" name="language" value="ru"/>
          <TextInput3 class="tinkoffPayRow" type="text" placeholder="Сумма заказа" name="amount" required/>
          <input class="tinkoffPayRow" type="text" placeholder="Номер заказа" name="order" value={}/>
          <input class="tinkoffPayRow" type="text" placeholder="Описание заказа" name="description"/>
          <input class="tinkoffPayRow" type="text" placeholder="ФИО плательщика" name="name"/>
          <input class="tinkoffPayRow" type="text" placeholder="E-mail" name="email"/>
          <input class="tinkoffPayRow" type="text" placeholder="Контактный телефон" name="phone"/>
          <input class="tinkoffPayRow" type="submit" value="Оплатить"/>
        </form>

       <ButtonSupport2
            style={{
            height: (
                this.state.signUpInterface && 
                this.state.nameValidateComplete &&
                this.state.emailValidateComplete &&
                this.state.cityValidateComplete &&
                this.state.password2ValidateComplete
              ) ? 40 : 0
            }}
          onClick={() => { this._signUpEmail()}} 
          >
              <SignUpButtonText>Зарегистрироваться</SignUpButtonText>
          </ButtonSupport2>
*/}

    </Container>
  );
  }
}
PayBlackOut.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const LogInModal = styled.div`
  width: 400px;
  margin-top: 10vh;
  border-radius: 20px;
  overflow: hidden;
  background-color: #090c13;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: space-around;
  align-self: center;
  display: flex;
  box-shadow: 5px 5px 5px  1px rgba(0,0,0,1) ;
  z-index: 255;

  @media (max-width: 450px) {
    width: calc(100vw - 40px);

  }
`;

const Rect93 = styled.div`
transition: 2s;
overflow: hidden;
  height: 40px;
  align-self: stretch;
  border-radius: 20px;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: flex-start;
  display: flex;
`;

const TextInput = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const TextInput3 = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const TextInput4 = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const TextInput1 = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const Rect99 = styled.div`
overflow: hidden;
transition: 2s;
  height: 40px;
  align-self: stretch;
  border-radius: 20px;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: flex-start;
  display: flex;
`;

const TextInput2 = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const LoremIpsum = styled.span`

  overflow: hidden;
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: rgba(220,85,85,1);
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  align-self: stretch;
  transition: 2s;
`;

const Rect97 = styled.div`
  height: 40px;
  transition: 2s;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const ButtonSupport1 = styled.div`
display: flex;
overflow: hidden;
transition: 2s;
  height: 40px;
  background-color: rgba(215,213,21,1);
  flex-direction: row;
  border-radius: 40px;
  //width: 160px;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: nowrap;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
`;

const SignInButtonText = styled.span`
  font-family: roboto-500;
  color: rgba(53,14,14,1);
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  line-height: 40px;
  align-self: center;
  text-align: center;
  padding: 0px;
  margin: 0px;
  width: auto;
`;


const ButtonSupport4 = styled.div`
display: flex;
  height: 40px;
  background-color: rgba(215,213,21,1);
  flex-direction: row;
  border-radius: 40px;
  width: 40px;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: nowrap;
  justify-content: center;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
`;

const ButtonSupport2 = styled.div`
display: flex;
overflow: hidden;
transition: 2s;
cursor: pointer;
  height: 40px;
  width: 340px;
  background-color: rgba(215,213,21,1);
  flex-direction: row;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: nowrap;
  align-self: center;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
`;

const SignUpButtonText = styled.span`
  font-family: roboto-500;
  color: rgba(53,14,14,1);
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  line-height: 40px;
  top: 20px;
  left: 168px;
  align-self: flex-start;
  text-align: center;
  padding: 0px;
  margin: 0px;
  height: 30px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
`;

export default PayBlackOut;
