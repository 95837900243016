import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./icons.js";
import Admin from "./screens/Admin";
import Blog from "./screens/Blog";
import City from "./screens/City";
import Landing from "./screens/Landing";
import Cabinet from "./screens/Cabinet"
import "./style.css";
import { ThemeContext } from './contexts'
import axi from './functions/axiosf';
import axios from 'axios';
import publicIP from 'react-native-public-ip';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      addUser: this.addUser,
      cities: [],
      addCities: this.addCities,
      base: [],
      addBase: this.addBase,
      donates: null,
      addDonates: this.addDonates,
      visibilityLogInBlackOut: false,
      toggleVisibilityLogInBlackOut: this.toggleVisibilityLogInBlackOut,
      visibilityAskQuestionBlackOut: "",
      toggleVisibilityAskQuestionBlackOut: this.toggleVisibilityAskQuestionBlackOut,
      LandingAnchor: 'Top',
      toggleLandingAnchor: this.toggleLandingAnchor,
      ip: null,
      setIp: this.setIp,
      myRegion: {},
      setMyRegion: this.setMyRegion,
      addMessages: this.addMessages,
      messages: [],
      addClientsOrders: this.addClientsOrders,
      clientsOrders: [],
      axiosRefresh: this._axiosRefresh,
    }
  }

  addUser = (u) => {
    this.setState({
      'user': u
    })
    let uString = JSON.stringify(u)
    localStorage.setItem("user", uString);
  }
  addCities = (u) => {
    this.setState({
      'cities': u
    })
    let uString = JSON.stringify(u)
    localStorage.setItem("cities", uString);
  }
  addBase=(u)=>{
    this.setState({
      'base': u
    })
    let uString = JSON.stringify(u)
    localStorage.setItem("base", uString);
  }
  addDonates = (u) => {
    this.setState({
      'donates': u
    })
    let uString = JSON.stringify(u)
    localStorage.setItem("donates", uString);
  }

  toggleLandingAnchor = (u) => {
    this.setState({
      'LandingAnchor': u
    })
  }

  toggleVisibilityLogInBlackOut = () => {
    let tv = !this.state.visibilityLogInBlackOut
    this.setState({ 'visibilityLogInBlackOut': tv})
  }

  toggleVisibilityAskQuestionBlackOut = (city) => {
    this.setState({ 'visibilityAskQuestionBlackOut': city })
  }

  addMessages = (messages)=>{
    this.setState({ messages: messages})
  }

  addClientsOrders = (orders) => {
    this.setState({ clientsOrders: orders})
  }

  componentDidMount() {
    let uString = localStorage.getItem("user");
    if (uString){
      let u = JSON.parse(uString)
      this.setState({
        'user': u
      })
    }
    
  }

  setMyRegion = (data) => {this.setState({myRegion: data})}
  setIp = (ip) => {this.setState({ip: ip})}

  _axiosRefresh() {
    let p = {
      time: +new Date()
    }
    axi("updateAggregatedData.php", "null", p).then((result) => {
      if (result != null) {
        this.addBase(result)
      }
    },
      (e) => { console.log(e) })
  }

render(){
  return (
    <ThemeContext.Provider value={this.state}>
      <Router>
        <Route path="/" exact component={Landing} />
        <Route path="/Admin/" exact component={Admin} />
        <Route path="/Cabinet/" exact component={Cabinet} />
        <Route path="/Blog/" exact component={Blog} />
        <Route path="/City/" exact component={City} />
        <Route path="/Landing/" exact component={Landing} />
      </Router>
    </ThemeContext.Provider>
  );}
}

export default App;
