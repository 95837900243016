import React, { Component } from "react";
import styled, { css } from "styled-components";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import FiltersBlogComponent from "../components/FiltersBlogComponent";
import NewsItem from "../components/NewsItem";
import FooterComponent from "../components/FooterComponent";
import LogInBlackOut from "../components/LogInBlackOut";
import { ThemeContext } from '../contexts';
import moment from 'moment';
import 'moment/locale/ru';

class Blog extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      visibilityLogInBlackOut: 'hidden',
      blog: ['','','']
    }
  }
  render(){
    let thisCity = {}
    if (this.context.base.cities && this.props.location.state){
    this.context.base.cities.map((item, index) => {
      if (this.props.location.state.item !== undefined){
        if (item.idRegion == this.props.location.state.item.idRegion) {
          thisCity = item
        }
      }
    })
    } else { thisCity = {name: ''}}
  return (
    <Container
      style={document.body.clientWidth < 450 ? {
          //height: 'auto',
          //position: "relative",
          overflowY: 'scroll',
          overflowX: 'hidden',
          //justifyContent: 'flex-start',
        } : {
          
        }}
    >
      {(this.props.location.state && this.props.location.state.type =='blog') &&
        <Image1 
          src={(this.props.location.state.item)?this.props.location.state.item.imageUrl:''}
          style={document.body.clientWidth < 450 ? {
            height: '100vh',
            width: 'auto'
          } : {
            
          }}
        />
      }
        <LogInBlackOut
          className='LogInBlackOut'
        ></LogInBlackOut>
       <Header2Stack>
          <Header
              route={
                this.props
              }
            
          ></Header>
          <Link to="/Landing">
            <Button1
            style={{
              height: document.body.clientWidth < 450 ? 80 : 150,
              backgroundImage: 'url(' + require("../assets/images/logo.png") + ")",
            }}/>
          </Link>
        </Header2Stack>      
      <BodyBlog
        style={document.body.clientWidth < 450 ?{
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: 'auto',
            position: "relative",
            width: document.body.clientWidth - 30,
            display: 'block'
          }:{
            flexDirection: 'row'
          }}>

        <ScrollArea
          style={document.body.clientWidth < 450 ? {
            width: document.body.clientWidth-40,
            alignSelf: "",
            //height: 'auto',
            margin: 5
          } : {
            width: 600,
            height: 'calc(100 % - 34px)'
          }}
        >
          {this.props.location.state.type =='blog' ?
            <HeaderArticleTxt
            style={document.body.clientWidth < 450 ? {
              marginRight: 17,
              marginLeft: 17
            }:{}}
            
            >{(this.props.location.state.item) ? this.props.location.state.item.title : 'Новости не найдены ((('}</HeaderArticleTxt>
            :
            <HeaderArticleTxt
            style={document.body.clientWidth < 450 ? {
              marginRight: 17,
              marginLeft: 17
            }:{}}
            >{(this.props.location.state.item) ? this.props.location.state.item.title : 'Вопросы не найдены ((('}</HeaderArticleTxt>
          }
          {this.props.location.state.type =='blog' ?
            <DateArticleTxt>{(this.props.location.state.item) ? (moment(+this.props.location.state.item.date).format('LL') + " : : " + (thisCity.name ? thisCity.name:'общая новость')) : "В указанные даты в данном регионе статей нет"}</DateArticleTxt>
          :
            <DateArticleTxt>{(this.props.location.state.item) ? (moment(+this.props.location.state.item.date).format('LL') + " : : " + (thisCity.name ? thisCity.name : 'общий вопрос')) : "В указанные даты в данном регионе публично вопросы не задавались"}</DateArticleTxt>
          }
          {this.props.location.state.type =='blog' ?
            <ArticleTxt>{(this.props.location.state.item) ? this.props.location.state.item.text : "Попробуйте расширить диапазон дат"}</ArticleTxt>
          :
          ((this.props.location.state.item) ?
            <div>
              <ArticleTxt><b style={{ color: "#ccc" }}>вопрос:</b></ArticleTxt>
              <ArticleTxt> {this.props.location.state.item.question} </ArticleTxt>
              <ArticleTxt><b style={{ color: "#ccc" }}> ответ:</b></ArticleTxt>
              <ArticleTxt>{this.props.location.state.item.answer}</ArticleTxt>
            </div> 
              : 
            <ArticleTxt>{"Попробуйте расширить диапазон дат"}</ArticleTxt>)
          }
       </ScrollArea>
        <ScrollArea2
          style={document.body.clientWidth < 450 ? {
            width: document.body.clientWidth - 40,
            borderBottomRightRadius: 20,
            borderTopRightRadius: 20,
            margin: 5,
            height: 400
          } : {
          }}
        >
        {this.props.location.state.type =='blog' ? 
            this.props.location.state.other.map((item, index)=>
            <NewsItem
              key={index}
              style={{
                width: 300,
                height: 400,
                marginLeft: 0,
                //flex: "0 0 auto",
                marginTop: 0,
                cursor: 'pointer',
              }}
                imageUrl={item.imageUrl}
                title={item.title}
                lid={item.lid}
                onClick={() => { 
                  this.props.history.push({ 
                    pathname: '/Blog', 
                    state: { item: item, other: this.props.location.state.other, type: 'blog' } }) 
                  }}
            ></NewsItem>
            )
            :
            this.props.location.state.other.map((item, index) =>
              <div
                key={index}
                style={{
                  width: 240,
                  height: 200,
                  marginLeft: 0,
                  //flex: "0 0 auto",
                  marginTop: 0,
                  cursor: 'pointer',
                  display: 'flex',
                  backgroundColor: 'rgba(0,0,0,1)',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: 20,
                  borderRadius: 20,
                  overflow: 'hidden',
                  padding: 10
                }}
                lid={item.question}
                onClick={() => {
                  this.props.history.push({
                    pathname: '/Blog',
                    state: { item: item, other: this.props.location.state.other, type:'faq' }
                  })
                }}
              >
                <ArticleTxt> {item.question} </ArticleTxt>
              </div>
            )
          
        }
        </ScrollArea2>
        <FiltersBlogComponent
            style={document.body.clientWidth < 450 ?
            {
              width: document.body.clientWidth - 40,
              height: 400,
              margin: 5,
              borderBottomLeftRadius: 20,
              borderTopLeftRadius: 20,     
            }
            :{
              width: 300,
              //height: document.body.clientHeight-340,
              height: '100%',
              maxHeight: 400,
              borderBottomLeftRadius:  0,
              borderTopLeftRadius:  0,        
            }}
            item={this.props.location.state.item}
            other={this.props.location.state.other}
            type={this.props.location.state.type}
            history={this.props.history}
        ></FiltersBlogComponent>
      </BodyBlog>
      <FooterComponent
        route={
          this.props
        }
        style={document.body.clientWidth < 450 ? {
          position: "relative",
          bottom: 0,
          marginTop: 400,
          display: "none"
        } : {
          position: "fixed",
          //width: '100vw',
           // height: 280,
            
           // bottom: 0,
        }}
          
        ></FooterComponent>
    </Container>
  );}
}
Blog.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  flex-direction: column;
justify-content: space-between;
align-items: center;
  height: 100vh;
  width: 100vw;
`;

const ButtonOverlay = styled.button`
 display: block;
 background: none;
 height: 100%;
 width: 100%;
 border:none
 `;
const Image1 = styled.img`
  position: fixed;
  z-index: -1;
  width: 100vw;
`;

const BodyBlog = styled.div`
  top: 76px;
  position: absolute;
  //margin-top: 76px;
  flex-direction: row;
  bottom: 0px;
  justify-content: center;
  left: 0px;
  right: 0px;
  display: flex;
  height: calc(100% - 240px);
`;

const Button1 = styled.div`
  top: 0px;
  left: 35px;
  width: 100px;
  height: 180px;
  background-color: rgba(230, 230, 230,0.34);
  position: absolute;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
`;

const Header2Stack = styled.div`
  top: 0px;
  left: 0px;
  height: 60px;
  position: absolute;
  right: 0px;
  z-index: 250;
`;

const ScrollArea = styled.div`
  overflow-y: scroll;
  width: 600px;
  //height: calc(100% - 34px);
  background-color: rgba(0,0,0,0.8);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  //margin-top: 76px;
  margin-right: 40px;
  //padding: 17px;
  border-radius: 20px;
  display: flex;
`;

const HeaderArticleTxt = styled.span`
  font-family: roboto-300;
  color: #fff;
  align-self: stretch;
  margin-top: calc(14px + 17px);
  margin-left: 80px;
  margin-right: 80px;
  font-size: 21px;
  font-weight: 600;
  font-style: normal;
  line-height: 26px;
  text-align: center;
`;

const DateArticleTxt = styled.span`
  font-family: roboto-300;
  height: 40px;
  color: rgba(216,214,214,1);
  align-self: stretch;
  opacity: 0.8;
  font-size: 14px;
  font-weight: regular;
  font-style: normal;
  line-height: 26px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  
  margin-left: 17px;
  margin-right: 17px;
`;

const ArticleTxt = styled.p`
  font-family: roboto-300;
  //flex: 0 0 auto;
  color: rgba(216,214,214,1);
  align-self: stretch;
  font-size: 14px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  //display: flex;
  flex-direction: column;
  height: auto;
  margin-left: 17px;
  margin-right: 17px;
  margin-bottom: 15px;
`;

const ScrollArea2 = styled.div`
  overflow-y: scroll;
  background-color: rgba(0,0,0,0.39);
  width: 320px;
  height: 100%;
  //margin-top: 76px;
  //border-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  align-items: center;
`;

export default Blog;
