import React, { Component } from "react";
import styled, { css } from "styled-components"; 
import EntypoIcon from "react-native-vector-icons/dist/Entypo";
import FontAwesomeIcon from "react-native-vector-icons/dist/FontAwesome";
import axi from '../../functions/axiosf';
import russia from '../../assets/maps/russia.json';
import france from '../../assets/maps/france-new.json'
import ukraine from '../../assets/maps/ukraine.json'
import belarus from '../../assets/maps/belarus.json'
let citiesBase = [...russia.layers, ...ukraine.layers, ...belarus.layers, ...france.layers];


function Cart(props) {


  const _deleteOrder = (idOrder)=>{
    let p = {
      uid: props.uid,
      idOrder: idOrder,

    }
    axi("updateUsersCart.php", "DELETE_ORDER", p).then((result) => {
      if (result != null) {
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
          this.context.addUser(null)
        } else {
          if (result.cart != undefined) {
            props.addCart(result.cart)
          }
        }
      }
    },
      (e) => { console.log(e) })
  }

  const _copyOrder = (order) => {
    let p = {
      uid: props.uid,
      cart: order.cart,
      idCity: order.idCity
    }
    axi("updateUsersCart.php", "COPY_ORDER", p).then((result) => {
      if (result != null) {
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
          this.context.addUser(null)
        } else {
          if (result.cart != undefined) {
            props.addCart(result.cart)
          }
        }
      }
    },
      (e) => { console.log(e) })
  }

  const _updateOrder = (order) => {
    let p = {
      uid: props.uid,
      cart: order.cart,
      idOrder: order.idOrder
    }
    axi("updateUsersCart.php", "UPDATE_PROP", p).then((result) => {
      if (result != null) {
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
          this.context.addUser(null)
        } else {
          if (result.cart != undefined) {
            props.addCart(result.cart)
          }
        }       
      }
    },
      (e) => { console.log(e) })
  }


  return (
 
              <RectOrder>
                <TitleRectOrder>Корзина товаров</TitleRectOrder>
                
                {props.cart.map((item, index)=>{
                  if (!item.prop){item.prop=''}
                  if (item.cart){

                    let regionFrom = 'Центральный склад'
                    
                    if (item.cart.idRegion) {
                      citiesBase.map((itemCB, indexCB) => {
                        if (itemCB.id == item.cart.idRegion) {
                          regionFrom = itemCB.name
                        }
                      })
                    }return(
                    <RectGoodInOrder
                    key={index}>
                      <ImgDiv>
                        <ImgGood 
                          src={item.cart.imageUrl}
                        />
                        <GradiendDiv/>
                      </ImgDiv>
                      <ColumnDiv>
                        <Rect1>
                          <span
                            style={{flex: 1}}
                          >{item.cart.title}</span>
                          <DivIcon
                            style={{
                            }}
                            onClick={() => {
                              /*
                              props.cart[props.cart.length] = item
                              props.addCart(props.cart)
                              */
                              _copyOrder(item)
                            }}
                          >
                            <EntypoIcon
                              name="copy"
                              size={20}
                              color="#000"
                              style={{
                                margin: 6,
                              }}
                            />
                          </DivIcon>
                          <DivIcon
                            style={{
                            }}
                            onClick={() => {
                              /*
                              let cartCopy = []
                              props.cart.map((itemCopy, indexCopy) => {
                                if (indexCopy!=index){
                                  cartCopy = [...cartCopy, itemCopy]
                                }
                              })
                              props.addCart(cartCopy)
                              */
                              _deleteOrder(item.idOrder)
                            }}
                          >
                            <EntypoIcon
                              name="cross"
                              size={28}
                              color="#000"
                              style={{
                                margin: 2,
                              }}
                              
                            />
                          </DivIcon>
                        </Rect1>
                        {item.cart.properties.length ?
                          <RectProps>
                            {
                              item.cart.properties.map((item2, index2) => {
                                if (item2 == item.cart.prop){
                                  return(
                                    <SelectedProp
                                      key={index2}>
                                    {item2}
                                    </SelectedProp>
                                  )
                                }else{
                                  return (
                                    <Prop
                                      key={index2}
                                      onClick={()=>{
                                        /*
                                        let copyCart = [...props.cart]
                                        console.log(copyCart[index+""].cart.prop)
                                        copyCart[index+""].cart.prop=item2
                                        console.log(copyCart[index+""])
                                        props.addCart(copyCart)
                                        */
                                        let copyOrder=item
                                        copyOrder.cart.prop = item2
                                        
                                        console.log(copyOrder)
                                        _updateOrder(copyOrder)
                                      }}
                                    >
                                      {item2}
                                    </Prop>
                                  )
                                }
                              })
                            }
                          </RectProps>
                          :
                          <Rect1>
                            <span
                              style={{
                                fontFamily: 'Roboto-300'
                              }}
                            >{" Доставка из: " + regionFrom}</span>
                          </Rect1>
                        }
                        <Rect1
                          style={{
                            height: 40,
                            marginBottom: 8,
                            overflow: 'hidden'
                          }}>
                          <span
                            style={{ 
                              flex: 1,
                              fontFamily: 'Roboto-300'
                            }}
                          >{item.cart.price+" руб."}</span>
                          {(!!item.cart.properties.length) &&
                            <span
                              style={{
                                  flex: 2,
                                  fontFamily: 'Roboto-300'
                              }}
                            >{" Доставка из: " + regionFrom }</span>
                          }
                        </Rect1>
                      </ColumnDiv>  
                    </RectGoodInOrder>
                  )}
                })}

              </RectOrder>
                      
  );
}
/* 
0:
{
address:""

cart:{
author:"3"
idCity:"0"
idGood:"3"
idRegion:""
imageUrl:"https://sun9-63.userapi.com/c1492/u58859226/99027101/x_8c33e308.jpg"
price:"200"
properties:[]
text:"Мир без тебя. Сборник стихов. 2007г. Половина стоимости от продажи этой книги пойдёт на благотворительные нужды. "
title:"сборник стихов Мир без тебя"
}
date:"2147483647"
idCity:"0"
idClient:"1"
idOrder:"1"
place:"0"
 */
const RectOrder = styled.div`
  width: 280px;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  min-height: 450px;
  height: 100%;
  overflow-y: scroll;
  ${'' /* direction: rtl; */}
`;

const TitleRectOrder = styled.span`
  font-family: Roboto-500;
  margin: 8px;
  margin-top: 16px;
  height: 26px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
`;

const RectGoodInOrder = styled.div`
  height: 180px;
  background-color: rgba(8,8,8,0.75);
  flex-direction: row;
  align-self: stretch;
  margin: 8px;
  border-radius: 20px;
  display: flex;
`;

const ImgGood = styled.img`
  display: flex;
  height: 180px;
  width: 100px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  object-fit: cover;
  position: absolute;
  z-index:2;
`;

const GradiendDiv = styled.div`
  display: flex;
  height: 180px;
  width: 60px;
${'' /* 
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
   */}
  object-fit: cover;
  position: absolute;
  z-index:4;
  background-image: linear-gradient(90deg, transparent, rgba(8,8,8,0.8));
  right: 0px;
`;

const ImgDiv = styled.div`
  display: flex;
  position: relative;
  height: 150px;
  width: 100px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  object-fit: cover;
`;

const Rect1 = styled.div`
  font-family: Roboto-500;
  display: flex;
  flex-direction: row;
  color: #c5c5c5;
  margin: 8px;
  align-items: center;
  justify-content: space-between;
`;

const RectProps = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  align-items: flex-start;
  justify-content: center;
flex-wrap: wrap;
  min-height: 40px;
  height: 100%;
  overflow-y: scroll;
`;

const ColumnDiv = styled.div`
  flex: 1 1;
  display: flex;
  width: 198px;
  flex-direction: column;
  justify-content: flex-between;
`;

const Prop = styled.div`
  font-family: Roboto-300;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgba(218,179,0,1);
  border-color: rgba(218,179,0,1);
  border-style: double;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
border-width: 1px;
border-radius: 8px;
margin: 4px;
cursor: pointer;
`;

const SelectedProp = styled.div`
  font-family: Roboto-300;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
border-width: 1px;
border-radius: 8px;
  border-color: rgba(218,179,0,1);
  border-style: double;
  background-color: rgba(218,179,0,1);
margin: 4px;
`;

const DivIcon = styled.div`
  background-color: rgba(218,179,0,1);
  flex: 0;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  margin: 4px;
  box-shadow: 3px 2px 4px 0.44px rgba(0,0,0,1);
`

export default Cart;
