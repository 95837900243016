import React from "react";
import styled, { css } from "styled-components";
import FontAwesomeIcon from "react-native-vector-icons/dist/FontAwesome"; 
import StyledLink from "./StyledLink";
import { ThemeContext } from '../contexts';
import axi from '../functions/axiosf'
import VkAuth from '../functions/VkAuth'; 
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';

let colors = {
  inputValidateComplete: '#50fb5980',
  inputBackGround: '#e6e6e680',
}

class AskQuestionBlackOut extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      warning: false,
      errorText: '',
      visible: false,
      signUpInterface: false,
      isPublic: false,
      text: '',
      textValidateComplete: false,
      city: '',
    }
  }

  _validateText = (text) => {
    if (text.length > 30) {
      this.setState({ text: text, textValidateComplete: true })
    }
    else {
      this.setState({ text: text, textValidateComplete: false })
    }
  }

  _toSend=()=>{
    let p = {
      isPublic: this.state.isPublic,
      uid: this.context.user.id,
      text: this.state.text,
      idCity: this.context.visibilityAskQuestionBlackOut
    }
    axi("newAsk.php", "null", p).then((result) => {
      console.log(result)
      if (result.type === 'nobody') {
        this.setState({ errorText: 'ошибка авторизации, попробуйте войти снова', warning: true, })
      } else {
        this.context.toggleVisibilityAskQuestionBlackOut("")
        this._validateText('')
        alert("Ваше обращение принято, администратор сайта рассмотрит его при первой же возможности")
      }
    },
      (e) => { console.log(e) })
  }


  render() {
  return (
    <Container {...this.props}
      style={{
        height: this.context.visibilityAskQuestionBlackOut ? '100vh' : 0
      }}>
      <div 
        style={{
            position: "absolute",
            height: this.context.visibilityAskQuestionBlackOut ? '100vh' : 0,
            width: '100vw',
            zIndex: 1,
            backgroundColor: this.context.visibilityAskQuestionBlackOut ? 'rgba(0,0,0,0.8)' : 'transparent',
            transition: '1s',
          }}
          onClick={() => { this.context.toggleVisibilityAskQuestionBlackOut('') }}
      />
      <LogInModal
      style={{
          transition: '2s',
          height: this.context.user ? 500 : 300,
      }}
      >
        <div
        style={{
          flexDirection: 'row'
        }}>
          <LoremIpsum
            onClick={() => { this.setState({ isPublic: true }) }} 
            style={{
              fontSize: this.state.isPublic ? 30 : 14,
              borderWidth: this.state.isPublic ? 0 : 2,
              color: this.state.isPublic ? '#fff' : 'rgba(215, 213, 21, 1)',
              borderColor: '#fff',
              borderRadius: 50,
              cursor: 'pointer'
            }}>
            Публичный вопрос
          </LoremIpsum>
          <LoremIpsum
            onClick={() => { this.setState({ isPublic: false }) }} 
            style={{
              color: !this.state.isPublic ? '#fff' : 'rgba(215, 213, 21, 1)',
              fontSize: this.state.isPublic ? 14 : 30,
              borderWidth: this.state.isPublic ? 2 : 0,
              borderColor: '#fff',
              borderRadius: 50,
              marginLeft: 20,
              cursor: 'pointer',
            }}>
            Обратная связь
          </LoremIpsum>
        </div>
        <LoremIpsum
            style={{
              marginLeft: 20,
              height: !this.context.user ? 0 : (this.state.isPublic ? 0 : 88),
            }}>
            Напишите обращение и получите ответ в личном кабинете и, по возможности, через указанный Вами способ связи в личном кабинете
        </LoremIpsum>
        <LoremIpsum
            style={{
              marginLeft: 20,
              height: !this.context.user ? 0 : (this.state.isPublic ? 44: 0),
            }}>
            Задайте вопрос, и ответ на него опубликуется в разделе FAQ
        </LoremIpsum>
        <LoremIpsum
          style={{
            marginLeft: 20,
            height: !this.context.user ? "auto" : 0,
          }}>
         Чтобы написать нам, зарегистрируйтесь или войдите
        </LoremIpsum>
        <Rect93
          style={{
            height: this.context.user ? 250 : 0,
            backgroundColor: this.state.textValidateComplete ? colors.inputValidateComplete : colors.inputBackGround,
          }}>
          <TextInput3 
              placeholder="Сообщение от 30 символов"
              value = {this.state.text}
              onChange={(e) => { this._validateText(e.target.value) }}
              multiline={true}
            ></TextInput3>
        </Rect93>
        {this.context.user   ?
          this.state.textValidateComplete &&
            <ButtonSupport1
              onClick={() => { this._toSend() }}
              style={{
                width: this.state.signUpInterface ?  0:160 ,
                cursor: 'pointer',
              }}>
                <SignInButtonText>Отправить</SignInButtonText>
            </ButtonSupport1>
        :
          <ButtonSupport1
            onClick={() => { 
              this.context.toggleVisibilityLogInBlackOut()
              this.context.toggleVisibilityAskQuestionBlackOut('')
             }}
            style={{
              width: this.state.signUpInterface ? 0 : 160,
              cursor: 'pointer',
            }}>
            <SignInButtonText>Войти</SignInButtonText>
          </ButtonSupport1>
        }
      </LogInModal>
    </Container>
  );
  }
}
AskQuestionBlackOut.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const LogInModal = styled.div`
  width: 400px;
  margin-top: 10vh;
  border-radius: 20px;
  overflow: hidden;
  background-color: #090c13;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: space-around;
  align-self: center;
  display: flex;
  box-shadow: 5px 5px 5px  1px rgba(0,0,0,1) ;
  z-index: 255;

  @media (max-width: 450px) {
    width: calc(100vw - 40px);

  }
`;

const Rect93 = styled.div`
transition: 2s;
overflow: hidden;
  align-self: stretch;
  border-radius: 20px;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: flex-start;
  display: flex;
`;


const TextInput3 = styled.textarea`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 100%;
  height: 235px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
  margin-top: 5px;
`;


const LoremIpsum = styled.span`

  overflow: hidden;
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: rgba(220,85,85,1);
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  align-self: stretch;
  transition: 2s;
`;

const ButtonSupport1 = styled.div`
display: flex;
overflow: hidden;
transition: 2s;
  height: 40px;
  background-color: rgba(215,213,21,1);
  flex-direction: row;
  border-radius: 40px;
  //width: 160px;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: nowrap;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
`;

const SignInButtonText = styled.span`
  font-family: roboto-500;
  color: rgba(53,14,14,1);
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  line-height: 40px;
  align-self: center;
  text-align: center;
  padding: 0px;
  margin: 0px;
  width: auto;
`;


export default AskQuestionBlackOut;
