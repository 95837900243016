import React, {useState} from "react";
import styled, { css } from "styled-components";
import InputMask from 'react-input-mask';

function CartForm(props) {
  let citiesFrom = []
  const [address, setAddress] = useState(props.user.address);
  const [fio, setFio] = useState(props.user.name);
  const [phone, setPhone] = useState(props.user.phone);

  let summ = 0
  let regionsCalc = []
  props.cart.map((item, index)=>{
    summ = summ + +item.cart.price

    if (!citiesFrom.includes(item.cart.idCity)){
      citiesFrom = [...citiesFrom, item.cart.idCity]
      summ = summ + 300
      regionsCalc = [...regionsCalc, {idCity: item.cart.idCity, sum: +item.cart.price + 300}]
    }else{
      regionsCalc.map((it, ind)=>{
        if(it.idCity===item.cart.idCity){
          regionsCalc[ind].sum = regionsCalc[ind].sum + +item.cart.price
        }
      })
    }
  })
   
  let culcText = 'доставка из '+citiesFrom.length+' городов: '+citiesFrom.length*300+' рублей'


  return (          
              <RectForm>
                <TitleFormTxt>Оформление заказа</TitleFormTxt>
                <AddressText
                  className={"inputDiv"}
                  style={{
                    height: 110
                  }}
                  autocomplete='on'
                  multiline={true}
                  value={address}
                  onChange={(e)=>{setAddress(e.target.value)}}
                  placeholder={'Введите адрес доставки. Чем точнее Вы укажете адрес, тем быстрее придёт посылка. Минимум 50 символов'}
                />
                <AddressText
                  className={"inputDiv"}
                  style={{
                    height: 30
                  }}
                  autocomplete='on'
                  multiline={true}
                  value={fio}
                  onChange={(e) => { setFio(e.target.value) }}
                  placeholder={'ФИО получателя. Минимум 10 символов'}
                />
                <InputMask 
                  className={"inputDiv"}
                  style={{
                    margin: 8,
                    width: 120,
                  }}
                  value={phone}
                  autocomplete='on'
                  onChange={(e) => { setPhone(e.target.value) }}
                  mask="+9 (999) 999-99-99" maskChar="_" />
                <FormTxt>доставка из {citiesFrom.length} городов: {citiesFrom.length * 300} руб.</FormTxt>
                <TitleFormTxt 
                  style={{fontSize: 20}}
                >к оплате: {summ} руб.</TitleFormTxt>
                <div
                  className={"ButtonSupport"}
                  style={{
                      marginRight: 0,
                      marginTop: 8,
                    backgroundColor: ( address && address.length > 49 && fio.length > 9 && phone && phone !=='+_ (___) ___-__-__') ?  "#d7d515" : "#aead7d",
                    }}
                  onClick={()=>{
                    if (address && address.length > 49 && fio.length > 9 && phone && phone !== '+_ (___) ___-__-__'){
                      props.placeTheOrders(address, fio, phone, summ, culcText, regionsCalc)
                    }  
                  }}
                >
                  <ButtonPayTxt
                  >Оформить заказ</ButtonPayTxt>
                </div>
              </RectForm>       
  );
}


const RectForm = styled.div`
  width: 280px;
  background-color: rgba(8,3,3,0.04);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  display: flex;
`;

const TitleFormTxt = styled.span`
  font-family: Roboto-500;
  margin: 8px;
  margin-top: 16px;
  height: 26px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 26px;
  font-weight: regular;
  font-style: normal;
  line-height: 26px;
  text-align: center;
`;

const FormTxt = styled.span`
  font-family: Roboto-300;
  margin-top: 16px;
  color: #fffe;
  align-self: stretch;
  font-size: 12px;
  font-weight: regular;
  font-style: normal;
  line-height: 18px;
  text-align: center;
`;

const AddressText = styled.textarea`
  font-family: Roboto-300;
  margin: 8px;
  margin-top: 8px;
  height: 26px;
  background-color: #000a;
  border-radius: 8px;
  width: 220px;
  height: 250px;
  padding: 8px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  ::-webkit-input-placeholder { /* Chrome */
    color: #fff9;
  }
`

const ButtonPayTxt = styled.span`
  font-family: roboto-500;
  color: #350e0e;
  align-self: center;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  text-align: center;
`;

export default CartForm;
