import React, { Component } from "react";
import styled, { css } from "styled-components";
import axi from '../functions/axiosf';
import { ThemeContext } from '../contexts';

class ComponentGoodItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      wm: document.body.clientWidth < 360 ? -160 : -130,
      isSend: false,
    }
  }

  axiNewOrder = () => {
    this.setState({isSend: true})
    let p = {
      uid: this.context.user.id,
      good: this.props.item
    }
    axi("addNewOrder.php", "null", p).then((result) => {
      if (result != null) {
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы, войдите заново')
          this.context.addUser(null)
        } else {
          if (result.type === 'success') {
            if (this.props.updateCabinet) { this.props.updateCabinet() }
            this.props.route.history.push({ pathname: '/Cabinet', state: { tab: "cart" } })
          }
        }
      }
    },
      (e) => { console.log(e) })
  }

   render(){
    return (
    <Container style = {this.props.style}>
      <ImageGood src={this.props.item.imageUrl}
      onClick={()=>{
        this.setState({ wm: 0 })
      }}
      ></ImageGood>
      <Rect
        onClick={() => {
            this.setState({ wm: document.body.clientWidth < 360 ? -160 : -130 })
        }}
        style={{
          marginLeft: document.body.clientWidth < 450 ? this.state.wm : 0,
        }}
        >
          <TxtNameGood>{this.props.item.title}</TxtNameGood>
        <TxtAboutGood>
          {this.props.item.text}
        </TxtAboutGood>
        <Rect2>
          <TxtPrice>{this.props.item.price + ' руб.'}</TxtPrice>
          <ButtonPay
              onClick={() => {this.axiNewOrder()}}
          >
            
              <ButtonPayTxt>Купить</ButtonPayTxt>
            
          </ButtonPay>
        </Rect2>
      </Rect>
    </Container>
  );}
}

ComponentGoodItem.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  overflow: hidden;
  background-color: rgba(192,189,189,1);
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin: 20px;
  border-radius: 20px;
`;


const ImageGood = styled.img`
  width: 200px;
  align-self: stretch;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  object-fit: cover;
`;

const Rect = styled.div`
  flex: 1 1 0%;
  transition: 2s;
  background-color: rgba(230, 230, 230,1);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  padding: 14px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  @media (max-width: 450px) {
    opacity: 0.9;
  }
`;

const TxtNameGood = styled.span`
  font-family: roboto-300;
  width: 202px;
  height: 33px;
  color: rgba(41,41,41,1);
  margin-top: 0px;
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  text-align: center;
`;

const TxtAboutGood = styled.span`
overflow-y: scroll;
  font-family: roboto-300;
  height: 100px;
  color: rgba(41,41,41,1);
  align-self: stretch;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  line-height: 24px;
`;

const Rect2 = styled.div`
  height: 70px;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  display: flex;

`;

const TxtPrice = styled.span`
  font-family: roboto-300;
  width: 97px;
  height: 26px;
  color: rgba(41,41,41,1);
  align-self: center;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 24px;
  text-align: left;
`;

const ButtonPay = styled.div`
  height: 40px;
  flex: 1 1 0%;
  background-color: rgba(218,179,0,1);
  flex-direction: row;
  justify-content: center;
  border-radius: 20px;
  border: none;
  display: flex;
  box-shadow: 5px 5px 5px  0.4px rgba(0,0,0,1) ;
  cursor: pointer;
`;

const ButtonPayTxt = styled.span`
  font-family: roboto-300;
  color: rgba(243,243,243,1);
  align-self: center;
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  text-align: center;
`;

export default ComponentGoodItem;
