import React, { Component } from "react";
import styled, { css } from "styled-components";
import axi from '../../functions/axiosf';
import { ThemeContext } from '../../contexts';
import EntypoIcon from "react-native-vector-icons/dist/Entypo";

class OrdersToDelivery extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      mess: []
    }
  }

  componentDidMount() {
  }


  _wellDelivered(idOrder){
    if (((this.context.user != null) && this.context.user.role == 'root')){
      let p = {
        //city: +item.city.idCity,
        //userID: +item.userID,
        idOrder: idOrder,
        uid: this.context.user.id,
      }
      axi("markingOfShipments.php", "null", p).then((result) => {
        if (result != null) {
          if (result.type === 'nobody') {
            alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
            this.context.addUser(null)
          } else {
            
          }
        }
      },
        (e) => { console.log(e) })
    }
  }


  render(){
    let cartToDelivery = {}
    let paymentsInCity = []
    let sum = {}
    this.props.paymentsIn.map((itemIn,indexIn)=>{
        cartToDelivery[itemIn.idPayIn] = []
        sum[itemIn.idPayIn] = 0
        itemIn.orders.map((idOrder, indexIdOrder)=>{
          this.props.paymentsTo.map((paymentToItem, indexPaymentTo)=>{
            if (paymentToItem.idPayIn == idOrder && paymentToItem.toCity == this.props.idCity){
              sum[itemIn.idPayIn]=paymentToItem.sum
            }
          })
          this.props.deliveries.map((deliveryItem, deliveryIndex)=>{
            if (deliveryItem.idOrder == idOrder && deliveryItem.cart.idCity == this.props.idCity){
              cartToDelivery[itemIn.idPayIn].push(deliveryItem)
              if (paymentsInCity.indexOf( itemIn ) == -1 ){
                paymentsInCity.push(itemIn)
              }
            }
          })
        })
    })  
    return (
      <Container 
        style={document.body.clientWidth < 900 ?
        {
          ...this.props.style,
          width: document.body.clientWidth - 40,
          margin: 0,
        }
        : {...this.props.style}}
        >
      <p className={'widgetTitleText'}>{"Товары на отправку"}</p>
      {paymentsInCity.map((itemInCity,indexInCity)=>{
        return(
        <div
          key={indexInCity}
          style={{
              margin: 10,
              marginBottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: '#0009',
              borderRadius: 10,
              height: cartToDelivery[itemInCity.idPayIn].length*100 + 130,
              width: 270,
              overflow: 'hidden'
          }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'flex-start',
                margin: 5,
                height: 120,
                width: 260,
                }}>
                  {cartToDelivery[itemInCity.idPayIn][0]&&
                  <>
                  {/* 

                    исправить баг когда будем внедлять магазин в реальное использование

                    <b style={{ color: '#e4d4d4' }}>{this.props.clientsBase[cartToDelivery[itemInCity.idPayIn][0].idClient].name}</b>
                  */}

                    <span style={{ color: '#ffcb17' }}>{cartToDelivery[itemInCity.idPayIn][0].address}</span>
                    
                    {/* 

                    исправить баг когда будем внедлять магазин в реальное использование

                    <span style={{ color: '#e4d4d4' }}>{this.props.clientsBase[cartToDelivery[itemInCity.idPayIn][0].idClient].phone}</span>
                     */}
                    <span style={{ color: '#e4d4d4' }}>{"общая сумма с доставкой: " + sum[itemInCity.idPayIn]+" р."}</span>
                  </>
                  }
            </div>
          {cartToDelivery[itemInCity.idPayIn].map((itemCart,indexCart)=>{
            return(
              <RectGoodInOrder
                key={indexCart}
                style={{backgroundColor: +itemCart.delivered ? "rgba(8,8,8,0.75)":"#880b0b8c"}}
                >
                  <ImgDiv>
                    <ImgGood 
                          src={itemCart.cart.imageUrl}
                    />
                  </ImgDiv>
                      <ColumnDiv>
                        <Rect1>
                          <span
                            style={{flex: 1}}
                          >{itemCart.cart.title}</span>
                          {!+itemCart.delivered&&
                            <DivIcon
                              style={{
                              }}
                              onClick={() => {
                                this._wellDelivered(itemCart.idOrder)
                              }}
                            >
                              <EntypoIcon
                                name="check"
                                size={28}
                                color="#000"
                                style={{
                                  margin: 2,
                                }}
                                
                              />
                            </DivIcon>
                          }
                        </Rect1>
                        <Rect1
                          style={{
                            height: 40,
                            marginBottom: 8,
                            marginTop: 0,
                            overflow: 'hidden'
                          }}>
                          <SelectedProp>
                            {itemCart.cart.prop}
                          </SelectedProp>
                          <span
                            style={{ 
                              flex: 1,
                              fontFamily: 'Roboto-300'
                            }}
                          >{"  "+itemCart.cart.price+" руб."}</span>

                        </Rect1>
                </ColumnDiv>  
              </RectGoodInOrder>

            )
          })}
        </div>        
      )})
      }
    </Container>)
  }
}
OrdersToDelivery.contextType = ThemeContext;

const Container = styled.div`
  background-color: rgba(4,4,4,0.5);
  margin: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  align-items: stretch;
  
`;

const Rect1 = styled.div`
  font-family: Roboto-500;
  display: flex;
  flex-direction: row;
  color: #c5c5c5;
  margin: 8px;
  align-items: center;
  justify-content: space-between;
`;
const RectGoodInOrder = styled.div`
  height: 100px;
  background-color: rgba(8,8,8,0.75);
  flex-direction: row;
  align-self: stretch;
  display: flex;
  border-top: 1px dotted #1b1b1b;
`;

const ImgDiv = styled.div`
  display: flex;
  position: relative;
  height: 100px;
  width: 50px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  object-fit: cover;
`;

const ColumnDiv = styled.div`
  flex: 1 1;
  display: flex;
  width: 198px;
  flex-direction: column;
  justify-content: flex-between;
`;

const ImgGood = styled.img`
  display: flex;
  height: 100px;
  width: 50px;
  object-fit: cover;
  position: absolute;
  z-index:2;
`;
const DivIcon = styled.div`
  background-color: rgba(218,179,0,1);
  flex: 0;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  margin: 4px;
  box-shadow: 3px 2px 4px 0.44px rgba(0,0,0,1);
`
const SelectedProp = styled.div`
  font-family: Roboto-300;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgba(218,179,0,1);
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
border-width: 1px;
border-radius: 8px;
  border-color: rgba(218,179,0,1);
  border-style: double;
  background-color: #000;
margin: 4px;
`;
export default OrdersToDelivery;
