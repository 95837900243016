import React, { Component } from "react";
import styled, { css } from "styled-components";
import AdminFAQComponent from "./AdminFAQComponent";
import moment from 'moment';
import 'moment/locale/ru';
import axi from '../functions/axiosf';
import { ThemeContext } from '../contexts';

class FAQComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      questions: []
    }
  }

  componentDidMount() {
    setTimeout(()=>
      this._readQuestions(),
      1000
    )
  }

  _readQuestions = () => {
    let u = 0
    if (this.context.user){
      u=this.context.user.id
    }
    let p = {
        uid: u,
        idCity: this.props.idCity,
      }
    axi("adminReadAsk.php", "null", p).then((result) => {
      console.log(result)
      if (result.type === 'nobody') {
        this.setState({ errorText: 'ошибка авторизации, попробуйте войти снова', warning: true, })
      } else {
        this.setState({ questions: result.questions })
      }
    },
      (e) => { console.log(e) })
  }

  _sendAnswer = (item, answer) => {
    let u = 0
    if (this.context.user) {
      u = this.context.user.id
    }
    let p = {
      uid: u,
      idItem: item.idFAQ,
      answer: answer,
      idCity: this.props.idCity,
    }
    axi("adminWriteAnswer.php", "null", p).then((result) => {
      console.log(result)
      if (result.type === 'nobody') {
        this.setState({ errorText: 'ошибка авторизации, попробуйте войти снова', warning: true, })
      } else {
        this.setState({ questions: result.questions })
      }
    },
      (e) => { console.log(e) })
  }

  _deleteQuestion = (item) => {
    let u = 0
    if (this.context.user) {
      u = this.context.user.id
    }
    let p = {
      uid: u,
      idItem: item.idFAQ,
      idCity: this.props.idCity,
    }
    axi("adminDeleteQuestion.php", "null", p).then((result) => {
      console.log(result)
      if (result.type === 'nobody') {
        this.setState({ errorText: 'ошибка авторизации, попробуйте войти снова', warning: true, })
      } else {
        this.setState({ questions: result.questions })
      }
    },
      (e) => { console.log(e) })
  }

  render(){
  return (
    <Container {...this.props}>
      <Вопросыпосетителей>Вопросы посетителей</Вопросыпосетителей>
      {
        this.state.questions.map((item, index)=>
        <AdminFAQComponent
          style={{
            alignSelf: "stretch"
          }}
          key={index}
          item={item}
          sendAnswer={(answer) => this._sendAnswer(item, answer)}
          deleteQuestion={() => this._deleteQuestion(item)}
        />
        )
      }

    </Container>
  );}
}
FAQComponent.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  background-color: rgba(4,4,4,0.5);
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-x: scroll;
`;

const Вопросыпосетителей = styled.span`
  font-family: Roboto-700;
  color: rgba(210,30,30,1);
  align-self: stretch;
  margin-top: 20px;
  font-size: 26px;
  font-weight: regular;
  font-style: normal;
  text-align: center;
`;

export default FAQComponent;
