import React, { Component, useState  } from "react";
import styled, { css } from "styled-components";
import moment from 'moment';
import 'moment/locale/ru';
let colors = {
  inputValidateComplete: '#50fb5980',
  inputBackGround: '#e6e6e680',
}

//при наличии собаки нужно дописть в начале ссылки mailto:

function AdminFAQComponent(props) {

   
  const [textValidateComplete, toggleTextValidate] = useState(false)
  const [text, setText] = useState('')
  const [answerActive, setAnswerActive] = useState(false)
  const [sendActive, setSendActive] = useState(false)

  const _validateText = (text) => {
    setText(text)
    if (text.length > 30) {
      toggleTextValidate(true) 
    }
    else {
      toggleTextValidate(false)
    }
  }

  const _sendAnsw = () =>{
    setAnswerActive(false)
    setSendActive(true) 
    props.sendAnswer(text)
  }



  let faq = +props.item.isPublic ? "FAQ" :"Личное сообщение"
  return (
    <Container {...props}>
      <NameEmailA 
        href={props.item.authorEmailQuestion} 
        target="_blank" 
        style={{
          height: sendActive ? 0 : 'auto',
        }}>
        {props.item.authorNameQuestion +" :: "+props.item.authorEmailQuestion}  
      </NameEmailA>
      <NameEmailTxt
        style={{
          color: +props.item.isPublic ? "rgba(218,179,0,1)" :"rgba(224,216,216,1)",
          height: sendActive ? 0 : 'auto',
        }}
      >{moment(+props.item.date).format('LL') + " :: " + faq}</NameEmailTxt>

      <NameEmailTxt
        style={{
          color: +props.item.isPublic ? "rgba(218,179,0,1)" : "rgba(224,216,216,1)",
          height: sendActive ? 40 : 0,
        }}
      >{"Ответ отправлен"}</NameEmailTxt>

      <QuestionText
        style={{
          height: sendActive ? 0 : 'auto',
          }}
      >
        {props.item.question}
      </QuestionText>
      <AnswerText
        style={{
          height: answerActive ? 190 : 0,
          backgroundColor: textValidateComplete ? colors.inputValidateComplete : colors.inputBackGround,
        }}>
          <TextInput3
            placeholder="Сообщение от 30 символов"
            value={text}
            onChange={(e) => { _validateText(e.target.value) }}
            multiline={true}
          ></TextInput3>
      </AnswerText>
      <Rect
        style={{
          height: sendActive ? 0 : 40,
        }}
      >

        <Rect3
          style={{
            width: answerActive ? 0 : '50%',
          }}
          onClick={() => { props.deleteQuestion() }}
        >
          <Удалить>Удалить</Удалить>
        </Rect3>
        <Rect2
          style={{
            width: answerActive ? "100%" : 0,
            marginLeft: 1,
            marginRight: 1,
          }}
          onClick={() => { _sendAnsw() }}
        >
          <Ответить>Отправить</Ответить>
        </Rect2>
        <Rect2
          style={{
            width: answerActive ? 0 : '50%',
          }}
          onClick={() => { setAnswerActive(true) }}
        >
          <Ответить>Ответить</Ответить>
        </Rect2>
      </Rect>
    </Container>
  );
}

const Container = styled.div`
height: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px;
  border-radius: 15px;
  border-color: #000000;
  border-width: 2px;
  border-style: solid;

`;
const NameEmailA = styled.a`
  font-family: Roboto-500;
  height: 30px;
  color: rgba(224,216,216,1);
  padding-top: 12px;
  font-size: 18px;
  font-weight: regular;
  font-style: normal;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
overflow: hidden;
`;

const AnswerText = styled.div`
transition: 2s;
overflow: hidden;
  align-self: stretch;
margin: 8px;
  height: 190px;
  border-radius: 20px;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: flex-start;
  display: flex;
`;


const TextInput3 = styled.textarea`
  font-family: roboto-300;
  font-style: normal;
  font-weight: regular;
  color: #000;
  width: 100%;
  height: 180px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
  margin-top: 5px;
`;


const NameEmailTxt = styled.span`
  font-family: Roboto-500;
  height: 30px;
  color: rgba(224,216,216,1);
  padding-top: 12px;
  font-size: 18px;
  font-weight: regular;
  font-style: normal;
  line-height: 18px;
  text-align: center;
overflow: hidden;
`;

const QuestionText = styled.span`
  font-family: Roboto-300;
  color: rgba(218,211,211,1);
  align-self: stretch;
  padding: 15px;
  font-size: 14px;
  font-weight: 100;
  font-style: normal;
  display: flex;
  flex-direction: column;
`;

const Rect = styled.div`
  height: 40px;
  flex-direction: row;
  align-self: stretch;
  display: flex;
  cursor: pointer;
  overflow: hidden; 
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

const Rect3 = styled.div`
  height: 40px;
transition: 2s;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(43,19,19,1) 0%, rgba(125,10,10,1) 38%, rgba(125,10,10,1) 67%, rgba(45,5,5,1) 100%);
  display: flex;
  
  overflow: hidden; 
`;

const Удалить = styled.span`
  font-family: Roboto-500;
  height: 27px;
  color: rgba(224,216,216,1);
  align-self: center;
  font-size: 26px;
  font-weight: regular;
  font-style: normal;
  line-height: 26px;
`;

const Rect2 = styled.div`
transition: 2s;
  height: 40px;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(0deg, rgba(53,49,3,1) 3%, rgba(211,149,0,1) 35%, rgba(239,220,6,1) 75%, rgba(39,36,1,1) 100%);
  display: flex;
  overflow: hidden; 
`;

const Ответить = styled.span`

  font-family: Roboto-500;
  color: rgba(41,39,39,1);
  align-self: center;
  font-size: 26px;
  font-weight: regular;
  font-style: normal;
  line-height: 26px;
`;

export default AdminFAQComponent;
