import React, { Component } from "react";
import styled, { css } from "styled-components";
import EntypoIcon from "react-native-vector-icons/dist/Entypo";

function FormAddCharitableComponent(props) {
  return (
    <Container {...props}>
      <TitleNewFond>Добавить НКО</TitleNewFond>
      <RectFormNewFond>
        <TitleCategory>Категория</TitleCategory>
        <TextInputCategory placeholder="Выберете категорию"></TextInputCategory>
        <TitleName>Название</TitleName>
        <TextInputNameFond placeholder="Введите название НКО"></TextInputNameFond>
        <TitleBriefDescr>Краткое описание</TitleBriefDescr>
        <TextInputBriefDescr
          placeholder="Введите ключевые данные и реквизиты"
          maxLength={255}
          numberOfLines={0}
        ></TextInputBriefDescr>
        <TitleDescr>Подробное описание</TitleDescr>
        <TextInputDescr
          placeholder="Опишите цели, задачи, возможности организации"
          maxLength={4096}
        ></TextInputDescr>
        <EntypoIcon
          name="squared-plus"
          style={{
            color: "rgba(211,149,0,1)",
            fontSize: 40,
            margin: 0,
            marginBottom: 0,
            width: 40,
            height: 40,
            overflow: "visible",
            alignSelf: "center"
          }}
        ></EntypoIcon>
      </RectFormNewFond>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: rgba(6,6,6,0.5);
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
  border-radius: 20px;
`;

const TitleNewFond = styled.span`
  font-family: Roboto;
  height: 26px;
  color: rgba(255,197,54,1);
  align-self: stretch;
  margin: 8px;
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  line-height: 30px;
  text-align: center;
`;

const RectFormNewFond = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  padding: 15px;
  padding-bottom: 15px;
  display: flex;
`;

const TitleCategory = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputCategory = styled.input`
  font-family: Roboto;
  height: 40px;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: regular;
  font-style: normal;
  line-height: 16px;
  border: none;
`;

const TitleName = styled.span`
  font-family: Roboto;
  width: 91px;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputNameFond = styled.input`
  font-family: Roboto;
  height: 40px;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: regular;
  font-style: normal;
  line-height: 16px;
  border: none;
`;

const TitleBriefDescr = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputBriefDescr = styled.textarea`
  font-family: Roboto;
  height: 60px;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: regular;
  font-style: normal;
  line-height: 14px;
  border: none;
`;

const TitleDescr = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputDescr = styled.textarea`
  font-family: Roboto;
  flex: 1 1 0%;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: regular;
  font-style: normal;
  line-height: 14px;
  border: none;
  display: flex;
  flex-direction: column;
`;

export default FormAddCharitableComponent;
