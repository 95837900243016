import React, { Component } from "react";
import styled, { css } from "styled-components";
import axi from '../functions/axiosf';
import { ThemeContext } from '../contexts';
import EntypoIcon from "react-native-vector-icons/dist/Entypo";
import CircularProgress from '@material-ui/core/CircularProgress';

class CitySelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      cities: [],
      waitingCities: []
    }
  }

  componentDidMount() {
    this._axiosRefresh()
  }

  _axiosRefresh() {
    let p = {
      time: +new Date()
    }
    axi("updateAggregatedData.php", "null", p).then((result) => {
      if (result != null) {
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
          this.context.addUser(null)
        } else {
          if (result.cities != undefined) {
            //let rsa = ''
            //for (let i = 0; i < result.cities.length; i++) {}
            this.setState({ cities: [...result.cities] })
          }
        }
      }
    },
      (e) => { console.log(e) })
  }

  _addCity(item){
    let p = {
        city: item,
        uid: this.context.user.id,
      }
    if (((this.context.user != null) && this.context.user.role == 'root')){
      axi("addCityToRoot.php", "null", p).then((result) => {
        if (result != null) {
          if (result.type === 'nobody') {
            alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
            this.context.addUser(null)
          } else {
            if (result.cities != undefined) {
              this.context.addCities(result.cities)
            }
          }
        }
      },
        (e) => { console.log(e) })
    }else{
      axi("addCityToAdmin.php", "null", p).then((result) => {
        if (result != null) {
          if (result.type === 'nobody') {
            alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
            this.context.addUser(null)
          } else {
            if (result.type != undefined) {
              alert('Ваша заявка принята, дождитесь одобрения федеральным администратором')

            }
            this.setState({waitingCities: [...this.state.waitingCities, item.idCity]})
          }
        }
      },
        (e) => { console.log(e) })
    }
  }

  _deleteCity(item){
      let p = {
        city: item, uid: this.context.user.id
      }
      axi("deleteCityToRoot.php", "null", p).then((result) => {
        if (result != null) {
          if (result.type === 'nobody') {
            alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
            this.context.addUser(null)
          } else {
            if (result.cities != undefined) {
              this.context.addCities(result.cities)
            }
          }
        }
      },
        (e) => { console.log(e) })
  }

  render(){
    return <Container style={this.props.style}>
      <p style={{ fontFamily: 'roboto-700',
                  height: 'auto',
                  color: "rgba(228,212,212,1)",
                  fontSize: 19,
                  margin: 12}}>{"Добавление существующего города в админку"}</p>
      {this.state.cities.map((item, index)=>{
        let f=false
        let c = (this.context.cities) ? this.context.cities.length : 0
        for (let i = 0; i <= c; i++) {
          if (this.context.cities[i]){
            if (this.context.cities[i].name == item.name) {f=true}
          }
        }
        if (f){
        return(
          <div
            key={index}
            style={{
              margin: 10,
              marginBottom: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#ffcb17',
              borderRadius: 10,
              height: 35,
              width: 270,
            }}>
              <span style={{ color: '#111', marginLeft: 10,}}>{item.name}</span>

              <EntypoIcon
                name="squared-cross"
                style={{
                  color: "#111",
                  fontSize: 25,
                  margin: 5,
                  cursor: "pointer"
                }}
                onClick={() => { this._deleteCity(item) }}
              />
            </div>
        )}else{
          let isWaiting=false
          if (this.state.waitingCities.indexOf(item.idCity)!==-1){
            isWaiting = true
          }
        return (
          <div
            key={index}
            style={{
              margin: 10,
              marginBottom: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: (isWaiting)?'#333':'#0009',
              borderRadius: 10,
              height: 35,
              width: 270,
            }}>
              <span style={{ 
                color: (isWaiting)?'#ddd':'#ffcb17',
                marginLeft: 10, }}>{item.name}</span>
              {isWaiting?<span style={{ 
                color: '#aaa',
                marginLeft: 4,
                fontSize: 12 }}>{'ждите ответа'}</span>
                :<></>
              }
              {isWaiting?
              <CircularProgress 
                size={25}
                style={{
                  color: "#ffc",
                  margin: 5,
                }}
              />:
              <EntypoIcon
                name="squared-plus"
                style={{
                  color: "#ffcb17",
                  fontSize: 25,
                  margin: 5,
                  cursor: "pointer"
                }}
                onClick={() => { this._addCity(item) }}
              />}
          </div>
        )}
      })
      }
    </Container>
  }
}
CitySelector.contextType = ThemeContext;

const Container = styled.div`
  background-color: rgba(4,4,4,0.5);
  margin: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  align-items: stretch;
`;

export default CitySelector;
