import React, { Component } from "react";
import styled, { css } from "styled-components";

function TabMain(props) {
  return (
    <Container {...props}>
      <FederalTxt>Федеральная</FederalTxt>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: rgba(27,27,27,1);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //margin-right: 15px;
  margin-left: 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
`;

const FederalTxt = styled.span`
  font-family: Roboto-500;
  height: 20px;
  color: rgba(212,212,212,1);
  align-self: stretch;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
`;

export default TabMain;
