import React, { Component } from "react";
import styled, { css } from "styled-components";

function ButtonOrderVolunteer(props) {
  return (
    <Container {...props}>
      <NameOfVolunteer>Имя волонтёра</NameOfVolunteer>
      <CityOfVolunteer>Город</CityOfVolunteer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: rgba(211,149,0,1);
  flex-direction: row;
  justify-content: space-around;
  margin: 8px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 20px;
`;

const NameOfVolunteer = styled.span`
  font-family: Roboto;
  height: 14px;
  flex: 1 1 0%;
  color: rgba(6,6,6,1);
  align-self: center;
  font-size: 14px;
  font-weight: regular;
  font-style: normal;
  line-height: 14px;
  display: flex;
  flex-direction: column;
`;

const CityOfVolunteer = styled.span`
  font-family: Roboto;
  width: 65px;
  height: 14px;
  color: rgba(10,4,4,1);
  align-self: center;
  font-size: 14px;
  font-weight: regular;
  font-style: normal;
  line-height: 14px;
  text-align: right;
`;

export default ButtonOrderVolunteer;
