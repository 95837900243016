import React, { Component } from "react";
import styled, { css } from "styled-components";
import EntypoIcon from "react-native-vector-icons/dist/Entypo";
import FontAwesomeIcon from "react-native-vector-icons/dist/FontAwesome";
import { useHistory } from "react-router-dom";
import { ThemeContext } from '../contexts';

class OneOfCities extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      act: false,
      txtColor1: '#d4e4d4',
      txtColor2: '#d4e4d4',
      txtColor3: '#d4e4d4',
      txtColor4: '#d4e4d4',
      txtColorLogIn: '#d4e4d4',
      txtColorFAQ: '#d4e4d4',
      h: 0,
    }
  }

  componentDidMount() {
    this.setState({h: 251})
  }

  componentWillUnmount(){
    this.setState({ h: 0 })
  }

  render(){
    let widthRectButtons = document.body.clientWidth < 450 ? '100%' : 400
    let widthRectButtonsHide = document.body.clientWidth < 450 ? '100%' : 0
    let paddingRectButtonsHide = document.body.clientWidth < 450 ? 10 : 0
    let fontSizeButton = document.body.clientWidth < 450 ? 22 : 26
  return (
    <Container {...this.props}
      onMouseMove={() => { this.setState({ act: true }) }}
      onMouseLeave={() => { this.setState({ act: false }) }} 
      style={{
        flexDirection: document.body.clientWidth < 450 ? 'column' : 'row',
        height: document.body.clientWidth < 450 ? 'auto' : this.state.h,
        marginRight: document.body.clientWidth < 450 ? 10 : 60,
        marginLeft: document.body.clientWidth < 450 ? 10 : 60,
      }}>
      <Image2 
        onClick={() => {
          this.props.route.history.push({ pathname: '/City', state: this.props.item })
        }} 
        src={this.props.item.cityPhotos[0]}></Image2>
      <ScrollArea
        onClick={() => {
          this.props.route.history.push({ pathname: '/City', state: this.props.item })
        }} >
        <CityName>{this.props.item.name}</CityName>
        <TxtCityAbout>
          {this.props.item.description}
         </TxtCityAbout>
      </ScrollArea>
      <RectButtonsActionsInCity
        style={{
          width: this.state.act ? widthRectButtons : widthRectButtonsHide,
          paddingLeft: this.state.act ? 10 : paddingRectButtonsHide,
          paddingRight: this.state.act ? 10 : paddingRectButtonsHide,}}>
        <ButtonGoToConcert
        onMouseMove ={() => { this.setState({ txtColor1: '#ffcb17'})}} 
        onMouseLeave ={() => { this.setState({ txtColor1: '#d4e4d4' }) }}
          style={{ 
            width: document.body.clientWidth < 360 ? 290 :320,
          }}
        >
          
            <EntypoIcon
              name="ticket"
              style={{
                color: this.state.txtColor1,
                fontSize: 30,
                marginRight: 20,
              }}
            ></EntypoIcon>
          <TxtGoToConcert style={{ 
            color: this.state.txtColor1, 
            fontSize: fontSizeButton,
            }}>Прийти на концерт</TxtGoToConcert>
    
        </ButtonGoToConcert>
        <ButtonDonateToConcert
          onMouseMove={() => { this.setState({ txtColor2: '#ffcb17' }) }}
          onMouseLeave={() => { this.setState({ txtColor2: '#d4e4d4' }) }}
          style={{
            width: document.body.clientWidth < 360 ? 290 : 320,
          }}>
            <FontAwesomeIcon
              name="ruble"
              style={{
                color: this.state.txtColor2,
                fontSize: 30,
                marginRight: 20,
                width: 28,
                height: 26,
                alignSelf: "center"
              }}
            ></FontAwesomeIcon>
          <TxtDonateToConcert style={{
            color: this.state.txtColor2,
            fontSize: fontSizeButton,
             }}>Помочь деньгами</TxtDonateToConcert>
          
        </ButtonDonateToConcert>
        <ButtonGoToVolunteer
          onMouseMove={() => { this.setState({ txtColor3: '#ffcb17' }) }}
          onMouseLeave={() => { this.setState({ txtColor3: '#d4e4d4' }) }}
          style={{
            width: document.body.clientWidth < 360 ? 290 : 320,
          }}>
         
            <EntypoIcon
              name="add-user"
              style={{
                color: this.state.txtColor3,
                fontSize: 28,
                marginRight: 20,
                alignSelf: "center"
              }}
            ></EntypoIcon>
          <TxtGoToVolunteer style={{
            color: this.state.txtColor3,
            fontSize: fontSizeButton,
             }}>Стать волонтёром</TxtGoToVolunteer>
          
        </ButtonGoToVolunteer>
        <ButtonGoToConcert1
          onClick={() => {
            this.context.toggleVisibilityAskQuestionBlackOut(this.props.item.idCity)
          }}
          onMouseMove={() => { this.setState({ txtColor4: '#ffcb17' }) }}
          onMouseLeave={() => { this.setState({ txtColor4: '#d4e4d4' }) }}
          style={{
            width: document.body.clientWidth < 360 ? 290 : 320,
          }}>
            <FontAwesomeIcon
              name="question"
              style={{
                color: this.state.txtColor4,
                fontSize: 30,
                marginRight: 30
              }}
            ></FontAwesomeIcon>
          <TxtAst style={{
            color: this.state.txtColor4,
            fontSize: fontSizeButton,
             }}>Задать вопрос</TxtAst>
          
        </ButtonGoToConcert1>
      </RectButtonsActionsInCity>
    </Container>
  );}
}
OneOfCities.contextType = ThemeContext;

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin: 0px;
  margin-top: 7px;
  border-color: #000000;
  border-width: 0px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  flex-wrap: nowrap;
  border-style: solid;
  transition: 2s;
`;

const Image2 = styled.img`
  height: 251px;
  //border-bottom-left-radius: 40px;
  object-fit: cover;
  cursor: pointer;
`;

const ScrollArea = styled.div`
  overflow-y: scroll;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 14px;
  padding-left: 0px;
  border-bottom-left-radius: 0px;
  left: 0px;
  top: 0px;
  flex-wrap: nowrap;
  background-color: rgba(230, 230, 230,1);
  height: '100%';
  display: flex;
`;

const CityName = styled.span`
  font-family: Roboto-300;
  color: rgba(53,14,14,1);
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  text-align: center;
`;

const TxtCityAbout = styled.span`
  font-family: Roboto-100;
  color: rgba(41,41,41,1);
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  margin: 20px;
  font-size: 18px;
  font-weight: regular;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-align: justify;
  flex: 0 0 auto;
  display: flex;
`;

const RectButtonsActionsInCity = styled.div`
  transition: 2s;
  overflow-x: hidden;
  background-color: rgba(216,214,214,1);
  flex-direction: column;
  justify-content: space-around;
  margin-right: 0px;
  margin-left: 0px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 0px;
  align-items: center;
  height: "100%";
  display: flex;
`;

const ButtonGoToConcert = styled.div`
display: flex;
  height: 45px;
  background-color: rgba(136,11,11,1);
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
  padding-left: 40px;
  border-radius: 40px;
  width: 320px;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
  cursor: pointer;
`;

const TxtGoToConcert = styled.span`
  font-family: roboto-300;
  height: 20px;
  width: auto;
  color: rgba(249,249,249,1);
  font-size: 26px;
  font-weight: 900;
  font-style: normal;
  line-height: 20px;
  
  transition: 2s;
`;

const ButtonDonateToConcert = styled.div`
display: flex;
  height: 45px;
  background-color: rgba(136,11,11,1);
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
  padding-left: 40px;
  border-radius: 40px;
  width: 320px;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
  cursor: pointer;
`;

const TxtDonateToConcert = styled.span`
  font-family: roboto-300;
  height: 20px;
  width: auto;
  color: rgba(249,249,249,1);
  font-size: 26px;
  font-weight: 900;
  font-style: normal;
  line-height: 20px;
  transition: 2s;
`;

const ButtonGoToVolunteer = styled.div`
display: flex;
  height: 45px;
  background-color: rgba(136,11,11,1);
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
  padding-left: 40px;
  border-radius: 40px;
  width: 320px;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
  cursor: pointer;
`;

const TxtGoToVolunteer = styled.span`
  font-family: roboto-300;
  height: 20px;
  color: rgba(249,249,249,1);
  font-weight: 900;
  font-style: normal;
  line-height: 20px;
  transition: 2s;
`;

const ButtonGoToConcert1 = styled.div`
display: flex;
  height: 45px;
  background-color: rgba(136,11,11,1);
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 30px;
  padding-left: 40px;
  border-radius: 30px;
  width: 320px;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
  cursor: pointer;
`;

const TxtAst = styled.span`
  font-family: roboto-300;
  height: 20px;
  color: rgba(232,232,232,1);
  font-size: 26px;
  font-weight: 900;
  font-style: normal;
  line-height: 20px;
  transition: 2s;
`;

export default OneOfCities;
