import React, { Component } from "react";
import styled, { css } from "styled-components";
import moment from 'moment';
import 'moment/locale/ru';

function TicketMessage(props) {
  let faq = +props.item.isPublic ? "FAQ" : "Личное сообщение"
  return (
    <Container>
      <TitleNews1>{props.item.title}</TitleNews1>
      <TitleNews1>
        {(props.item.date) && 
          moment(+props.item.date).format('LL')
        }
      </TitleNews1>
      <ArticleTxt
        style={{
          color: +props.item.isPublic ? "rgba(218,179,0,1)" : "rgba(224,216,216,1)"
        }}
      ><b>
        {props.city ? props.city : 'общий вопрос'}
      </b></ArticleTxt>
      <ArticleTxt
       style={{
        color: +props.item.isPublic ? "rgba(218,179,0,1)" : "rgba(224,216,216,1)"
      }}
      >
        {faq}
      </ArticleTxt>
      <div>
        <ArticleTxt><b style={{ color: "#ccc" }}>вопрос:</b></ArticleTxt>
        <ArticleTxt> {props.item.question} </ArticleTxt>
        <ArticleTxt><b style={{ color: "#ccc" }}> ответ:</b></ArticleTxt>
        <ArticleTxt>{props.item.answer}</ArticleTxt>
      </div>
    </Container>
  );
}                                                   

const Container = styled.div`
  display: flex;
  width: 280px;
  padding: 16px;
  background-color: rgba(0,0,0,1);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  border-radius: 20px;
  border-color: #000000;
  border-width: 0px;
  border-style: solid;
`;

const ScrollArea = styled.div`
  overflow-y: scroll;
  height: 250px;
  background-color: rgba(0,0,0,0.73);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  margin-top: -50px;
  padding: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
`;

const TitleNews1 = styled.span`
  font-family: roboto-300;
  height: auto;
  color: rgba(243,243,243,1);
  align-self: stretch;
  font-size: 26px;
  font-weight: regular;
  font-style: normal;
  text-align: center;
`;

const ArticleTxt = styled.span`
  font-family: roboto-300;
  flex: 0 0 auto;
  color: rgba(176,189,195,1);
  align-self: stretch;
  margin-top: 8px;
  font-size: 18px;
  font-weight: regular;
  font-style: normal;
  line-height: 24px;
  display: flex;
  flex-direction: column;
`;

export default TicketMessage;