import React, { Component } from "react";
import styled, { css } from "styled-components";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import FooterComponent from "../components/FooterComponent";
import moment from 'moment';
import 'moment/locale/ru';
import YouTube from 'react-youtube';

class City extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      concert: {
        cityPhotos:[],
        cityVideos:[],
        concertDay:0,
        description:'',
        fullDescription:'',
        idCity:'',
        idRegion:'',
        name:'',
      }
    }
  }

  componentDidMount(){
        if (this.props.location.state){
          this.setState({ concert: this.props.location.state})
        }
        else{
          //тут будем смотреть IP и показывать тот город, откуда человек заходит
          
        }
  }

  _onReadyVideo(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  render(){
  return (
    <Container
    style={document.body.clientWidth < 450 ?{
        //height:  'auto',
        overflowY: 'scroll',
        overflowX: 'hidden',
        width: document.body.clientWidth,
      } : { overflowY: 'hidden',}
    }>
      
        <Image1
        className='BackImage'
        style={{
          backgroundImage: 'url(' + this.state.concert.cityPhotos[0] + ')',
          zIndex: 0,
          width: "100vw",
          left: 0,
        }}/>
          <BodyCity>
            <RectCity
            //style={{height: 100}}
            >
              <TitleCityTxt>{this.state.concert.name}</TitleCityTxt>
              <DateArticleTxt>{moment(+this.state.concert.concertDay).format('LL')}</DateArticleTxt>
              <RectColumsCity
                style={{ 
                  flexDirection: document.body.clientWidth < 450 ? 'column' : 'row',
                  }}>
{/* 
                <PeopleScrollArea>
                  <PeopleTitleText>Принимают участие</PeopleTitleText>
                  <Rect3>
                    <Rect5></Rect5>
                    <Rect6>
                      <PersonNameTxt>Имя Фамилия</PersonNameTxt>
                      <AboutPersTxt>
                        что делает человек на этом мероприятии
                      </AboutPersTxt>
                    </Rect6>
                  </Rect3>
                  <Rect4></Rect4>
                </PeopleScrollArea>
 */}
                <RectArticle
                    style={document.body.clientWidth < 450 ? {
                      minHeight: 300,
                    } : {}}
                    >
                  <ArticleTxt>
                    {this.state.concert.description}
                  </ArticleTxt>
                  <ArticleTxt1
                  style={document.body.clientWidth < 450 ? {
                    height: 'auto',
                    overflow: 'auto',
                    minHeight: 160,
                  } : {}
                  }>
                    {this.state.concert.fullDescription}
                  </ArticleTxt1>
                </RectArticle>
                {document.body.clientWidth < 450 
                ?
                  <div
                    style={ {
                      height:220,
                      width: document.body.clientWidth-80,
                      overflowX: 'scroll',
                      overflowY: 'hidden',
                    }}>
                    <RectCityMedia
                    style={{ 
                      height: 'auto', 
                      width: this.state.concert.cityPhotos.length * 260,
                      justifyContent: 'space-around'}}>
                      {this.state.concert.cityPhotos.map((item, index) => 
                      <Image2
                        key={index}
                        src={item}
                      ></Image2>
                      )}
                    </RectCityMedia>
                  </div>
                  :
                  <RectCityMedia>
                      {this.state.concert.cityPhotos.map((item, index) =>
                        <Image2
                          key={index}
                          src={item}
                        ></Image2>
                      )}
                  </RectCityMedia>
                }
                {this.state.concert.cityVideos.length &&
                  (document.body.clientWidth < 450
                      ?
                      <div
                        style={{
                          height: 220,
                          width: document.body.clientWidth - 80,
                          overflowX: 'scroll',
                          overflowY: 'hidden',
                        }}>
                        <RectCityMedia
                          style={{
                            height: 'auto',
                            width: this.state.concert.cityPhotos.length * 260,
                            justifyContent: 'space-around'
                          }}>
                          {this.state.concert.cityVideos.map((item, index) =>
                            <YouTube
                              className={'bigVideo'}
                              videoId={item[1]}
                              onReady={this._onReadyVideo}
                              key={index}
                            />
                          )}
                        </RectCityMedia>
                      </div>
                      :
                      <RectCityMedia>
                        {this.state.concert.cityVideos.map((item, index) =>
                          <YouTube
                            className={'bigVideo'}
                            videoId={item[1]}
                            onReady={this._onReadyVideo}
                            key={index}
                          />
                        )}
                      </RectCityMedia>
                  )
                }
              </RectColumsCity>
              <FooterComponent
                style={{
                  width: 1366,
                  height: 250,
                  //position: "absolute",
                  bottom: 0
                }}
                route={this.props} />
            </RectCity>
            {/* <HeaderStack>
              <Header
                style={{
                  top: 0,
                  left: 0,
                  width: 1366,
                  height: 60,
                  position: "absolute"
                }}
                route={this.props}
              ></Header>
              <Link to="/Landing">
                <Button1>
                  <ButtonOverlay></ButtonOverlay>
                </Button1>
              </Link>
            </HeaderStack> */}
          <HeaderComponentStack
            style={{
              height: document.body.clientWidth < 450 ? 0 : 60,
            }}
          >
            <Header
              route={this.props}
            ></Header>
            <Link to="/">
              <Button2
                style={{
                height: document.body.clientWidth < 450 ? 80 : 150,
                backgroundImage: 'url(' + require("../assets/images/logo.png") + ")",
                }}
              >
                <ButtonOverlay></ButtonOverlay>
              </Button2>
            </Link>
          </HeaderComponentStack>
          </BodyCity>
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  background-color: rgba(60,60,60,1);
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const ButtonOverlay = styled.button`
 display: block;
 background: none;
 height: 100%;
 width: 100%;
 border:none
 `;
const Image1 = styled.div`
  height: 100vh;
  ${'' /* top: 0px;
  left: 0px;
  position: absolute;
  right: 0px;
  flex-direction: column;
  display: flex;
  background-size: cover;
  overflow: hidden; */}
`;

const BodyCity = styled.div`
  width: 100vw;
  flex-direction: column;
  flex: 1 1 0%;
  position: relative;
  display: flex;
`;

const RectCity = styled.div`
  flex: 1 1 0%;
  background-color: rgba(0,0,0,0.8);
  flex-direction: column;
  align-items: center;
  //align-self: stretch;
  margin: 0px;
  padding-top: 74px;
  padding-right: 40px;
  //padding-bottom: 260px;
  padding-left: 40px;
  display: flex;
`;

const TitleCityTxt = styled.span`
  font-family: Roboto-700;
  height: 80px;
  color: rgba(218,179,0,1);
  flex-direction: column;
  align-self: center;
  justify-content: space-between;
  margin-top: 14px;
  font-size: 30px;
  font-weight: regular;
  font-style: normal;
  line-height: 26px;
  text-align: center;
`;

const DateArticleTxt = styled.span`
  font-family: Roboto-500;
  height: 40px;
  color: rgba(216,214,214,1);
  align-self: stretch;
  opacity: 0.8;
  font-size: 22px;
  font-weight: 100;
  font-style: normal;
  line-height: 26px;
  text-align: center;
`;

const RectColumsCity = styled.div`
  flex: 1 1 0%;
  flex-direction: row;
  justify-content: space-around;
  display: flex;
`;

const PeopleScrollArea = styled.div`
  overflow-y: scroll;
  background-color: rgba(0,0,0,0.9);
  border-radius: 20px;
  width: 200px;
  height: calc(100vh - 454px);
  align-self: center;
  flex-direction: column;
  left: 0px;
  top: 0px;
  height: 242px;
  align-items: center;
  display: flex;
`;

const PeopleTitleText = styled.span`
  font-family: Roboto-100;
  font-style: normal;
  font-weight: regular;
  color: rgba(218,179,0,1);
  height: 27px;
  width: 135px;
  margin: 15px;
  margin-bottom: 5px;
  align-self: center;
`;

const Rect3 = styled.div`
  margin: 15px;
  margin-bottom: 5px;
  height: 250px;
  flex-direction: column;
  width: 170px;
  left: 75px;
  top: 122px;
  border-radius: 20px;
  flex: 0 0 auto;
  display: flex;
`;

const Rect5 = styled.div`
  flex: 0.5 1 0%;
  display: flex;
  flex-direction: column;
`;

const Rect6 = styled.div`
  flex: 0.5 1 0%;
  background-color: rgba(0,0,0,0.74);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

const PersonNameTxt = styled.span`
  font-family: Roboto-300;
  font-style: normal;
  font-weight: regular;
  color: rgba(248,231,28,1);
  text-align: center;
`;

const AboutPersTxt = styled.span`
  font-family: Roboto-100;
  font-style: normal;
  font-weight: regular;
  color: rgba(195,190,190,1);
  align-self: stretch;
  padding: 14px;
`;

const Rect4 = styled.div`
  margin: 15px;
  margin-bottom: 5px;
  align-self: stretch;
  height: 250px;
  left: 15px;
  width: 170px;
  top: 333px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
`;

const RectArticle = styled.div`
  overflow-y: scroll;
  flex: 1 1 0%;
  flex-direction: column;
  align-self: stretch;
  justify-content: flex-start;
  //margin-right: 20px;
  padding: 20px;
  height: calc(100vh - 494px);
  display: flex;
`;

const ArticleTxt = styled.span`
  font-family: Roboto-500;
  height: auto;
  color: rgba(216,214,214,1);
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  //padding-right: 40px;
  //padding-left: 40px;
  font-size: 22px;
  font-weight: regular;
  font-style: normal;
  line-height: 28px;
`;

const ArticleTxt1 = styled.span`
  font-family: Roboto-100;
  flex: 1 1 0%;
  color: rgba(216,214,214,1);
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  padding-top: 15px;
  //padding-right: 40px;
  padding-bottom: 15px;
  //padding-left: 40px;
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  display: flex;
`;

const RectCityMedia = styled.div`
  overflow-y: scroll;
  flex: 1 1 0%;
  background-color: rgba(0,0,0,0.9);
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin: 8px;
  height: calc(100vh - 454px);
  border-radius: 20px;
  display: flex;
`;

const Image2 = styled.img`
  height: 159px;
  align-self: stretch;
  margin: 8px;
  border-radius: 20px;
  object-fit: cover;
`;

const Button1 = styled.div`
  top: 0px;
  left: 35px;
  width: 100px;
  height: 180px;
  background-color: rgba(230, 230, 230,0.34);
  position: absolute;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border: none;
`;

const Button2 = styled.div`
  top: 0px;
  left: 35px;
  width: 100px;
  background-color: rgba(5, 5, 10,0.34);
  position: absolute;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
`;

const HeaderStack = styled.div`
  top: 0px;
  left: 0px;
  width: 1366px;
  height: 180px;
  position: absolute;
`;

const HeaderComponentStack = styled.div`
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 180px;
  position: fixed;
  z-index: 250;
`;

export default City;
