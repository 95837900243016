import React, { Component } from "react";
import styled, { css } from "styled-components";

function NewsItem(props) {
  return (
    <Container {...props}>
      <Image4 src={props.imageUrl}/>
      <ScrollArea>
        <TitleNews1>{props.title}</TitleNews1>
        <TxtNews1>
          {props.lid}
        </TxtNews1>
      </ScrollArea>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: rgba(0,0,0,1);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px;
  border-radius: 20px;
  border-color: #000000;
  border-width: 0px;
  border-style: solid;
`;

const Image4 = styled.img`
  width: 300px;
  height: 200px;
  flex: 1 1 0%;
  align-self: stretch;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  object-fit: contain;
  display: flex;
  flex-direction: column;
`;

const ScrollArea = styled.div`
  overflow-y: scroll;
  height: 250px;
  background-color: rgba(0,0,0,0.73);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  margin-top: -50px;
  padding: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
`;

const TitleNews1 = styled.span`
  font-family: roboto-300;
  height: auto;
  color: rgba(243,243,243,1);
  align-self: stretch;
  font-size: 26px;
  font-weight: regular;
  font-style: normal;
  text-align: center;
`;

const TxtNews1 = styled.span`
  font-family: roboto-300;
  flex: 0 0 auto;
  color: rgba(176,189,195,1);
  align-self: stretch;
  margin-top: 8px;
  font-size: 18px;
  font-weight: regular;
  font-style: normal;
  line-height: 24px;
  display: flex;
  flex-direction: column;
`;

export default NewsItem;