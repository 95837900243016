import React, { Component } from "react";
import styled, { css } from "styled-components";
import DataSelector from "./DataSelector";
import EntypoIcon from "react-native-vector-icons/dist/Entypo";
import { ThemeContext } from '../contexts';
import axi from '../functions/axiosf';
import russia from '../assets/maps/russia.json';
import ItemWithUser from './ui/ItemWithUser'
let citiesBase = russia.layers;

class RootWidgetUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: null,
      users: [],
      c: [],
      selectedUser: null,
      thisIsAdmin: false,
      titleWarning: false,
      textWarning: false,
    }
  }

  componentDidMount(){
    if (this.context.user){
      this.setState({
        date: +new Date()
      }) }
  }
  _readCitiesOtherUser(usID){
    let p = {
      uid: this.context.user.id,
      user: +usID
    }
    axi("readCitiesOtherUser.php", "null", p).then((result) => {
      if (result.type === 'nobody') {
        alert('Вы заходили под этим аккаунтом с другого устройства. Для подтверждения, что это Вы, войдите заново')
        this.context.addUser(null)
      } else {
        if (result.type == "error") {
          alert('Ошибка чтения городов для пользователя ' + usID)
        } else {
          if (result.cities != undefined) {
            console.log(result.cities)
            this.setState({ c: result.cities })
          }
        }
      }
    },
      (e) => { console.log('ошибка чтения городов', e) })
  }
  _deleteCity(item) {
    let p = {
      uid: this.context.user.id,
      user: +this.state.selectedUser,
      idCity: item.idCity
    }
    axi("deleteCityOtherUser.php", "null", p).then((result) => {
      if (result.type === 'nobody') {
        alert('Вы заходили под этим аккаунтом с другого устройства. Для подтверждения, что это Вы, войдите заново')
        this.context.addUser(null)
      } else {
        if (result.type == "error") {
          alert('Ошибка удаления городов для пользователя ' + this.state.selectedUser)
        } else {
          this._readCitiesOtherUser(+this.state.selectedUser)
        }
      }
    },
      (e) => { console.log('ошибка удаления городов', e) })
  }
  _addCity(item) {
    let p = {
      uid: this.context.user.id,
      user: +this.state.selectedUser,
      idCity: item.idCity
    }
    axi("addCityOtherUser.php", "null", p).then((result) => {
      if (result.type === 'nobody') {
        alert('Вы заходили под этим аккаунтом с другого устройства. Для подтверждения, что это Вы, войдите заново')
        this.context.addUser(null)
      } else {
        if (result.type == "error") {
          alert('Ошибка добавления городов для пользователя ' + this.state.selectedUser)
        } else {
          this._readCitiesOtherUser(+this.state.selectedUser)
        }
      }
    },
      (e) => { console.log('ошибка добавления городов', e) })
  }

  render(){
  return (
    <Container
      style={{...this.props.style, backgroundImage: 'url:"'+this.state.imageUrl+'"'}}
    >
      <RectCityсolumnDescription
        style={{
          direction: 'rtl',
          width: document.body.clientWidth < 900 ?
            (this.state.thisIsAdmin ? 140 : document.body.clientWidth - 28) :
            (this.state.thisIsAdmin ? 290 : 560),
          overflowY: 'scroll',
          overflowX: 'hidden',
          borderRadius: 20,
          borderTopRightRadius: this.state.thisIsAdmin ? 0 : 20,
          borderBottomRightRadius: this.state.thisIsAdmin ? 0 : 20,
        }}
      >
      {this.props.users.map((item,index)=>{
          return(
          <ItemWithUser
            onClick={()=>{this.setState({selectedUser: index})}}
            key={index}
            item={item}
            width={
                document.body.clientWidth < 900 ?
                (this.state.thisIsAdmin ? 140 : document.body.clientWidth-28):
                (this.state.thisIsAdmin ? 290 : 560)
              }
            backColor={(this.state.selectedUser == item.userID)? '#444d': '#0009'}
            axiGetAllUsers={()=>this.props.axiGetAllUsers()}
            openBlind={(r)=>{
                this.setState({ 
                    thisIsAdmin: (r.role == 'admin')?true:false,
                    selectedUser: r.userID
                  })
                if(r.role == 'admin'){
                  this._readCitiesOtherUser(r.userID)
                }
            }}
            selected={(this.state.selectedUser == item.userID)}
            openAdmin={this.state.thisIsAdmin}
          />)
        })}
      </RectCityсolumnDescription>
      <RectCityсolumnDescription
      style={{
          width: document.body.clientWidth < 900 ?
            (this.state.thisIsAdmin ? document.body.clientWidth - 168 : 0) :
            (this.state.thisIsAdmin? 270:0),
          overflowY: 'scroll',
          overflowX: 'hidden',
          borderRadius: 20,
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          backgroundColor: '#444d'
      }}>
        {this.context.base.cities && this.context.base.cities.map((item, index) => {
          let f = false
          let ci = (this.state.c) ? this.state.c.length : 0
          for (let i = 0; i <= ci ; i++) {
            if (this.state.c[i]) {
              if (this.state.c[i].name == item.name) { f = true }
            }
          }
          return (<div
            key={index}
            style={{
              margin: 10,
              marginBottom: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: f ? '#ffcb17' : '#0009',
              borderRadius: 10,
              height: document.body.clientWidth < 900 ? 'auto' :35,
              width: document.body.clientWidth < 900 ? 'auto' :240,
            }}>
            <span style={{ 
              color: f ? '#111' : '#ffcb17',
               marginLeft: 10,
                }}>{item.name}</span>
            <EntypoIcon
              name={f ? "squared-cross" : "squared-plus"}
              style={{
                color: f ? '#111' : '#ffcb17',
                fontSize: 25,
                margin: 5,
                cursor: "pointer"
              }}
              onClick={() => { 
                if(f){
                  this._deleteCity(item) 
                }else{
                  this._addCity(item)
                }
              }}
            />
          </div>
          )
        })}  
      </RectCityсolumnDescription>
    </Container>
  )}
}

RootWidgetUsers.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  background-color: rgba(4,4,4,0.5);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  transition: 2s;
  border-bottom-width: 2px;
  border-color: #444d;
`;

const RectCityсolumnDescription = styled.div`
  width: 240px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  display: flex;
  transition: all 2s;
`;

export default RootWidgetUsers;
