import React, { Component } from "react";
import styled, { css } from "styled-components";
import EntypoIcon from "react-native-vector-icons/dist/Entypo";
import { ThemeContext } from '../../contexts';
import axi from '../../functions/axiosf';
import russia from '../../assets/maps/russia.json';
let citiesBase = russia.layers;

class AvaIcon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }

  render(){
  return (
        <HeadAva 
          style={this.props.style}
          onClick={() => {
            this.props.onClick()
          }} 
        />
  )}
}

const HeadAva = styled.div`
  width: 50px;
  height: 50px;
  //background-color: rgba(230, 230, 230,1);
  border-radius: 50px;
  border: 1px;
  background-size: cover;
  cursor: pointer;
  background-position: center center;
`;

AvaIcon.contextType = ThemeContext;
export default AvaIcon;
