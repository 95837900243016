import React, { Component } from "react";
import styled, { css } from "styled-components";
import Header from "../components/Header";
import TabMain from "../components/TabMain";
import TabReg from "../components/TabReg";
import TabPlus from "../components/TabPlus";
import AdminStatComponent from "../components/AdminStatComponent";
import Chat from "../components/Chat";
import CitySelector from "../components/CitySelector";
import ApprovingMessages from "../components/ApprovingMessages";
import OrdersToDelivery from "../components/admin/OrdersToDelivery"
import FAQComponent from "../components/FAQComponent";
import AddAboutCityComponent from "../components/AddAboutCityComponent";
import AddNewsComponent from "../components/AddNewsComponent";
import AddGoodComponent from "../components/AddGoodComponent";
import FormAddCharitableComponent from "../components/FormAddCharitableComponent";
import RootWidgetUsers from "../components/RootWidgetUsers"
import { render } from "react-dom";
import { ThemeContext } from '../contexts';
import LogInBlackOut from "../components/LogInBlackOut";
import axi from '../functions/axiosf';

class Admin extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tab: 'root',
      approvingMessages: [],
      deliveries: [],
      updateDeliveries: this.updateDeliveries,
      paymentsIn: [],
      updatePaymentsIn: this.updatePaymentsIn,
      paymentsTo: [],
      updatePaymentsTo: this.updatePaymentsTo,
      users: [],
      clientsBase: [],
      warning: false
    }
  }

  updateDeliveries(u){
    this.setState({deliveries: u})
  }

  updatePaymentsIn(u){
    this.setState({paymentsIn: u})
  }

  updatePaymentsTo(u){
    this.setState({paymentsTo: u})
  }

  componentDidMount() {
    //console.log(this.props)
    if (this.context.user == null) {
      this.props.history.push('/') 
    }else{
      setTimeout(() => this._axiosRefresh(this.context.user.id), 100)
      this.myUpdateInterval = setInterval(() => this._axiosRefresh(this.context.user.id), 60000)
      if (this.context.user.role != "root") { 
        this.setState({ tab: 'new'})
      }else{
          this.axiGetAllUsers()
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.myUpdateInterval);
  }

  _setApprovingMessages(m){
    this.setState({ approvingMessages: m })
  }

  _axiosRefresh(uid){
    this.setState({ warning: false })
    let p = {
      uid: uid,
      timeStatStart: this.state.timeStatStart,
      timeStatEnd: this.state.timeStatEnd,
    }
    axi("updateAdminData.php", "null", p).then((result) => {
      if(result!=null){
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
          this.context.addUser(null)
        } else {
          if (result.cities != undefined) {
            this.context.addCities(result.cities)
          }
          if (result.donates != undefined) {
            this.context.addDonates(result.donates)
          }
          if (result.approved != undefined) {
            this.setState({ approvingMessages: result.approved})
          }
          if (result.deliveries != undefined) {
            this.setState({ deliveries: result.deliveries})
          }
          if (result.paymentsIn != undefined) {
            this.setState({ paymentsIn: result.paymentsIn})
          }
          if (result.paymentsTo != undefined) {
            this.setState({ paymentsTo: result.paymentsTo})
          }
          if (result.clientsBase != undefined) {
            this.setState({ clientsBase: result.clientsBase})
          }
          //this.context.toggleVisibilityLogInBlackOut()
        }
      }
    },
      (e) => { console.log(e) })
  }

  axiGetAllUsers() {
    let p = {
      uid: this.context.user.id
    }
    axi("axiGetAllUsers.php", "null", p).then((result) => {
      if (result.type === 'nobody') {
        alert('Вы заходили под этим аккаунтом с другого устройства. Для подтверждения, что это Вы, войдите заново')
        this.context.addUser(null)
      } else {
        if (result.type == "error") {
          alert('Ошибка чтения всех пользователей ' + this.state.name)
        } else {
          if (result.users != undefined) {
            this.setState({ users: result.users })
            /* 
            result.users.map((userItem, userIndex)=>{

            })
            */
          }
        }
      }
    },
      (e) => { console.log('ошибка чтения всех пользователей', e) })
  }

  render(){
    let marginFullBody = document.body.clientWidth < 900 ? 3 : 30
    let borderRadiusFullBody = document.body.clientWidth < 900 ? 20 : 30
    let componentWidth = document.body.clientWidth-28
    let ScrollArea2Width = document.body.clientWidth < 900 ? document.body.clientWidth - 15 : document.body.clientWidth - 60
    return (
    <Container> 
      <LogInBlackOut
        className='LogInBlackOut'
      ></LogInBlackOut>
      <Header
        route={
          this.props
        }
      ></Header>
      <div style={{height: 50}}></div>
      <ScrollArea>
        {((this.context.user != null) && this.context.user.role == 'root') &&
        <TabMain
          onClick={() => { this.setState({ tab: 'root' }) }}
          style={{
            alignSelf: "stretch",
            backgroundColor: this.state.tab == 'root' ? 'rgba(27,27,27,1)' : 'rgba(27,27,27,0.5)',
          }}
        ></TabMain>
        }
        {(this.context.cities != null)  && 
            this.context.cities.map((item, index) => {
              return(
              <TabReg
                key={index}
                style={{
                  alignSelf: "stretch",
                  flex: "0 0 auto",
                  backgroundColor: this.state.tab == item.idCity ? 'rgba(27,27,27,1)' : 'rgba(27,27,27,0.5)'
                }}
                title={item.name}
                onClick={() => { 
                  this.setState({ tab: 'props' })
                  setTimeout(() => this.setState({ tab: +item.idCity }), 50)
                  }}
              ></TabReg>)
            })
        }
        <TabPlus
          onClick={() => { this.setState({ tab: 'new' }) }}
          style={{
            alignSelf: "stretch",
            flex: "0 0 auto"
          }}
        ></TabPlus>
      </ScrollArea>
      {this.state.tab == 'root' &&
            <ScrollArea2
            style={{
              marginRight: marginFullBody,
              marginBottom: marginFullBody,
              marginLeft: marginFullBody,
              borderTopRightRadius: borderRadiusFullBody,
              borderBottomRightRadius: borderRadiusFullBody,
              borderBottomLeftRadius: borderRadiusFullBody,
              width: ScrollArea2Width,
            }}
            >
{/*             
              <AdminStatComponent
                style={{
                  width: document.body.clientWidth < 900 ? componentWidth : 800,
                  height: document.body.clientWidth < 900 ? 'auto' : 500,
                  alignSelf: "flex-start",
                  margin: 20,
                  marginRight: document.body.clientWidth < 900 ? 3 : 15,
                  marginLeft: document.body.clientWidth < 900 ? 3 : 15,
                  padding: 6
                }}
              ></AdminStatComponent>
 */}
              {this.state.approvingMessages!=[] &&
                <ApprovingMessages
                  style={{
                    width: document.body.clientWidth < 900 ? componentWidth : 300,
                    height: document.body.clientWidth < 900 ? 'auto' : 500,
                    margin: 20,
                    marginRight: document.body.clientWidth < 900 ? 3 : 15,
                    marginLeft: document.body.clientWidth < 900 ? 3 : 15,
                    padding: document.body.clientWidth < 900 ? 3 : 15,
                    flex: "0 0 auto",
                    padding: 6
                  }}
                  mess={this.state.approvingMessages}
                  setApprovingMessages={(m)=>this._setApprovingMessages(m)}
                ></ApprovingMessages>
              }              
              <RootWidgetUsers
                style={{
                  width: document.body.clientWidth < 900 ? componentWidth : 560,
                  height: document.body.clientWidth < 900 ? 300 :  500,
                  margin: document.body.clientWidth < 900 ? 3 : 20,
                  flex: "0 0 auto"
                }}
                tab={this.state.tab}
                users={this.state.users}
                axiGetAllUsers={() => this.axiGetAllUsers()}
              />
            <FAQComponent
                style={{
                  width: document.body.clientWidth < 900 ? componentWidth : 480,
                  height: document.body.clientWidth < 900 ? 'auto' : 500,
                  margin: 20,
                  marginRight: document.body.clientWidth < 900 ? 3 : 15,
                  marginLeft: document.body.clientWidth < 900 ? 3 : 15,
                  padding: 0,
                  borderRadius: 20,
                  flex: "0 0 auto"
                }}
                idCity="0"
              />
            <AddNewsComponent
              style={{
                width: document.body.clientWidth < 900 ? componentWidth : 560,
                height: document.body.clientWidth < 900 ? 'auto' : 500,
                margin: document.body.clientWidth < 900 ? 3 : 20,
                flexDirection: document.body.clientWidth < 900 ? 'column' : 'row',
                flex: "0 0 auto"
              }}
              tab={this.state.tab}
              clickAddFunc={'addNews.php'}
            ></AddNewsComponent>
            <AddGoodComponent
              style={{
                width: document.body.clientWidth < 900 ? componentWidth : 560,
                height: document.body.clientWidth < 900 ? 'auto' : 500,
                margin: document.body.clientWidth < 900 ? 3 : 20,
                flexDirection: document.body.clientWidth < 900 ? 'column' : 'row',
                flex: "0 0 auto"
              }}
              tab={this.state.tab}
              clickAddFunc={'addGoods.php'}
            ></AddGoodComponent>
              <FormAddCharitableComponent
                style={{
                  width: document.body.clientWidth < 900 ? componentWidth : 400,
                  height: document.body.clientWidth < 900 ? 'auto' : 500,
                  flex: "0 0 auto"
                }}
              ></FormAddCharitableComponent>
            </ScrollArea2>
      }
      {(typeof(this.state.tab) == "number") &&
        <ScrollArea2
          style={{
            marginRight: marginFullBody,
            marginBottom: marginFullBody,
            marginLeft: marginFullBody,
            borderTopRightRadius: borderRadiusFullBody,
            borderBottomRightRadius: borderRadiusFullBody,
            borderBottomLeftRadius: borderRadiusFullBody,
            width: ScrollArea2Width,
          }}>
          <FAQComponent
            style={{
              width: document.body.clientWidth < 900 ? componentWidth : 480,
              height: document.body.clientWidth < 900 ? 'auto' : 500,
              margin: 20,
              marginRight: document.body.clientWidth < 900 ? 3 : 15,
              marginLeft: document.body.clientWidth < 900 ? 3 : 15,
              padding: 0,
              borderRadius: 20,
              flex: "0 0 auto"
            }}
            idCity={this.state.tab}
          />
          <OrdersToDelivery
                  style={{
                    width: document.body.clientWidth < 900 ? componentWidth : 300,
                    height: document.body.clientWidth < 900 ? 'auto' : 500,
                    margin: 20,
                    marginRight: document.body.clientWidth < 900 ? 3 : 15,
                    marginLeft: document.body.clientWidth < 900 ? 3 : 15,
                  }}
                  idCity={this.state.tab}
                  deliveries={this.state.deliveries}
                  paymentsIn={this.state.paymentsIn}
                  paymentsTo={this.state.paymentsTo}
                  updateDeliveries={(u)=>this.updateDeliveries(u)}
                  updatePaymentsIn={(u)=>this.updatePaymentsIn(u)}
                  updatePaymentsTo={(u)=>this.updatePaymentsTo(u)}
                  clientsBase={this.state.clientsBase}
          ></OrdersToDelivery>
          <AddNewsComponent
            style={{
              width: document.body.clientWidth < 900 ? componentWidth : 560,
              height: document.body.clientWidth < 900 ? 'auto' : 500,
              margin: document.body.clientWidth < 900 ? 3 : 20,
              flexDirection: document.body.clientWidth < 900 ? 'column' : 'row',
              flex: "0 0 auto"
            }}
            tab={this.state.tab}
            clickAddFunc={'addNews.php'}
          />
          <AddGoodComponent
            style={{
              width: document.body.clientWidth < 900 ? componentWidth : 560,
              height: document.body.clientWidth < 900 ? 'auto' : 500,
              margin: document.body.clientWidth < 900 ? 3 : 20,
              flexDirection: document.body.clientWidth < 900 ? 'column' : 'row',
              flex: "0 0 auto"
            }}
            tab={this.state.tab}
            clickAddFunc={'addGoods.php'}
          />
          <AddAboutCityComponent
            style={{
              width: document.body.clientWidth < 900 ? componentWidth : 640,
              height: document.body.clientWidth < 900 ? 'auto' : 500,
              margin: document.body.clientWidth < 900 ? 3 : 20,
              flexDirection: document.body.clientWidth < 900 ? 'column' : 'row',
              flex: "0 0 auto"
            }}
            tab = {this.state.tab}
            clickAddFunc={'editedCity.php'}
          />
          
        </ScrollArea2>
      }
      {this.state.tab == 'new' &&
        <ScrollArea2
          style={{
            marginRight: marginFullBody,
            marginBottom: marginFullBody,
            marginLeft: marginFullBody,
            borderTopRightRadius: borderRadiusFullBody,
            borderBottomRightRadius: borderRadiusFullBody,
            borderBottomLeftRadius: borderRadiusFullBody,
            backgroundColor: 'rgba(136,11,11,1)',
            width: ScrollArea2Width,
          }}
        >
        {this.context.user != null &&
          <CitySelector
            style={{
              width: document.body.clientWidth < 900 ? componentWidth : 300,
              height: this.context.user.role == 'root'?500:'94%',
              margin: document.body.clientWidth < 900 ? 3 : 20,
              marginRight: 15,
              marginLeft: 15,
            }}>
          </CitySelector>
          }
          {((this.context.user != null) && this.context.user.role == 'root') &&
            <AddAboutCityComponent
              style={{
                width: document.body.clientWidth < 900 ? componentWidth : 800,
                height: document.body.clientWidth < 900 ? 'auto' : 500,
                margin: document.body.clientWidth < 900 ? 3 : 20,
                flexDirection: document.body.clientWidth < 900 ? 'column' : 'row',
                flex: "0 0 auto"
              }} 
              tab = {this.state.tab}
              clickAddFunc={'addNewCity.php'}
            ></AddAboutCityComponent>
          }
        </ScrollArea2>
      }
    </Container>
  );
  }
}
Admin.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  background-color: rgba(60,60,60,1);
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const ScrollArea = styled.div`
  overflow-x:   auto;
  height: 60px;
  flex-direction: row;
  align-self: stretch;
  justify-content: flex-start;
  margin-top: 15px;
  display: flex;
`;

const ScrollArea2 = styled.div`
  overflow-y: scroll;
  flex: 1 1 0%;
  background-color: rgba(27,27,27,1);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
  height: 1135px;
  display: flex;
`;

const TextInput = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 17px;
  line-height: 18px;
  border: none;
  background: transparent;
`;
export default Admin;
