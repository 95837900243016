import React, { Component } from "react";
import styled, { css } from "styled-components";
import DataSelector from "./DataSelector";
import EntypoIcon from "react-native-vector-icons/dist/Entypo";
import { ThemeContext } from '../contexts';
import axi from '../functions/axiosf';
import russia from '../assets/maps/russia.json';
import YouTube from 'react-youtube';
let citiesBase = russia.layers;

class AddNewsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      idRegion: '',
      idCity: 0,
      title: '',
      date: null,
      lid: '',
      text: '',
      imageUrl: '',
      titleWarning: false,
      textWarning: false,
    }
  }

  componentDidMount(){
    if (typeof(this.props.tab) == "number") {
      for(let i=0; i<this.context.cities.length; i++){
        if (+this.context.cities[i].idCity == this.props.tab) { 
          this.setState({
            idCity: +this.props.tab,
            idRegion: this.context.cities[i].idRegion,
          }) 
        }
      }
    }
    this.setState({
      date: +new Date()
    }) 
  }

  axiAdd(url){
    if(!this.state.success)
    {
      let p ={
        ...this.state,
        uid: this.context.user.id
      }
      axi(url, "null", p).then((result) => {
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с другого устройства. Для подтверждения, что это Вы, войдите заново')
          this.context.addUser(null)
        } else {
          if(result.type == "error"){
            alert('Ошибка добавдения новости '+this.state.name)
          }else{
            alert('новость "' + this.state.title + '" добавлена успешно')
          }
        }
        if (url == 'addNews.php'){
          this.setState({
            title: '',
            date: null,
            lid: '',
            text: '',
            imageUrl: '',
            success: true,
          })
        }

      },
        (e) => { console.log('ошибка создание новой новости', e) })
    } 
  }

 
  _delPhoto = (item, index) => {
    //let copy = this.state.cityPhotos
    //copy.splice(index, 1)
    this.setState({ cityPhotos: ''})
  }

  _delVideo = (item, index) => {
    //let copy = this.state.cityVideos
    //copy.splice(index, 1)
    this.setState({ cityVideos: '' })
  }

  _onReadyVideo(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  _getYoutubeID(link) {
    return link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
  }

  render(){
  return (
    <Container
      style={{...this.props.style, backgroundImage: 'url:"'+this.state.imageUrl+'"'}}
    >
      <RectCityсolumnDescription
        style={document.body.clientWidth < 900 ?
          {
            width: document.body.clientWidth - 58,
          }
          : {}}>
        <p style={{
          fontFamily: 'roboto-700',
          height: 'auto',
          color: "rgba(228,212,212,1)",
          fontSize: 19,
          margin: 12,
        }}>{"Написание новой статьи"}</p>
        <TitleRegion>Заголовок статьи</TitleRegion>
        <TextInputRegion
          style={{ backgroundColor: this.state.titleWarning && '#ff00004f' }}
          placeholder="Введите заголовок от 10 до 255 символов"
          value={this.state.title} 
          onChange={(e) => {
            let textWarning = true
            if (e.target.value.length > 9) { textWarning = false }
            this.setState({ title: e.target.value, titleWarning: textWarning })
          }}
        ></TextInputRegion>
        <TitleDateConcert>Дата</TitleDateConcert>
        <DataSelector
          style={{
            width: 235,
            height: 35,
            alignSelf: "center",
            marginTop: 8,
            marginBottom: 8
          }}
          colorBack={'#fff7'}
          setDate={(concertDay) => this.setState({ date: concertDay})}
          concertDay={this.state.date}
        ></DataSelector>
        <TitlePhoto>Фото</TitlePhoto>
        <TextInputCity
          placeholder="вставьте ссылку на фото"
          value={this.state.imageUrl}
          onChange={(e) => { this.setState({ imageUrl: e.target.value }) }}
        ></TextInputCity>
        <TitleBriefDescription>Краткое описание</TitleBriefDescription>
        <TextInputBriefDescription
          placeholder="Введите ЛИД новости, который будет отображаться в виджете"
          maxLength={255}
          numberOfLines={0}
          value={this.state.lid}
          onChange={(e) => { this.setState({ lid: e.target.value }) }}
        ></TextInputBriefDescription>
      </RectCityсolumnDescription>
      <RectCityсolumnDescription
        style={document.body.clientWidth < 900 ?
          {
            width: document.body.clientWidth - 58,
          }
          : {}}>
        
        <TitleFullDescription>Статья</TitleFullDescription>
        <TextInputFullDescription
          style={{ backgroundColor: this.state.textWarning && '#ff00004f' }}
          placeholder="Подробный текст статьи. будет отображаться при открытии статьи в отдельной странице. Минимальный обьём текста 300 символов"
          maxLength={4096}
          value={this.state.text}
          onChange={(e) => {
            let textWarning = true
            if (e.target.value.length > 300) { textWarning = false }
            this.setState({ text: e.target.value, textWarning: textWarning })
          }}
        ></TextInputFullDescription>
        <div
          className={'ButtonSupport'} 
          style={document.body.clientWidth < 900 ?
          {
            width:  document.body.clientWidth - 88,
            marginRight: 0,
          }
          :{}
          }
          onClick={() => {
            let f = false, fr = false
            if (this.state.title.length > 10) {
              fr = true
            } else { this.setState({ titleWarning: true }) }
            if (this.state.text.length > 300) {
              f = true
            }
            else { this.setState({ textWarning: true }) }
            if (f && fr) { this.axiAdd(this.props.clickAddFunc) }
          }}
        >
          <span className='ButtonSupportText' >Опубликовать статью</span>

        </div>
      </RectCityсolumnDescription>
    </Container>
  )}
}

AddNewsComponent.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  background-color: rgba(4,4,4,0.5);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
`;

const RectCityсolumnDescription = styled.div`
  width: 250px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 15px;
  display: flex;
`;

const TitleRegion = styled.span`
  font-family: Roboto-500;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputRegion = styled.input`
  font-family: Roboto-300;
  height: 24px;
  background-color: #000000a6 ;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 16px;
  line-height: 16px;
  border: none;
`;

const TextInputCity = styled.input`
  font-family: Roboto-300;
  height: 24px;
  background-color: #000000a6;align-self: stretch;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 16px;
  line-height: 16px;
  border: none;
`;

const TitleDateConcert = styled.span`
  font-family: Roboto-500;
  width: 256px;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TitleBriefDescription = styled.span`
  font-family: Roboto-500;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputBriefDescription = styled.textarea`
  font-family: Roboto-300;
  height: 108px;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  line-height: 14px;
  border: none;
`;

const TitleFullDescription = styled.span`
  font-family: Roboto-500;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
  margin-top: 46px;
`;

const TextInputFullDescription = styled.textarea`
  font-family: Roboto-300;
  height: 350px;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  line-height: 14px;
  border: none;
`;

const RectCityсolumnMedia = styled.div`
  width: 300px;
  flex-direction: column;
  align-self: stretch;
  justify-content: flex-start;
  margin: 0px;
  padding: 15px;
  display: flex;
`;

const TitlePhoto = styled.span`
  font-family: Roboto-500;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const RectPhotos = styled.div`
  flex: 1 1 0%;
  height: 100px;
  flex-direction: row;
  //align-self: stretch;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
      overflow-y: scroll;
`;

const RectPhoto = styled.div`
  width: 120px;
  height: 80px;
  background-color: rgba(4,4,4,1);
  border-radius: 8px;
  margin-bottom: 10px;
`;

const TitleVideo = styled.span`
  font-family: Roboto-300;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 20px;
  text-align: left;
`;

const RectVideos = styled.div`
  height: 120px;
  //width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  overflow-x: scroll;
`;


export default AddNewsComponent;
