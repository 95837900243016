import React from "react";
import styled, { css } from "styled-components";
import FontAwesomeIcon from "react-native-vector-icons/dist/FontAwesome"; 
import StyledLink from "./StyledLink";
import { ThemeContext } from '../contexts';
import axi from '../functions/axiosf'
import VkAuth from '../functions/VkAuth'; 
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';

let colors = {
  inputValidateComplete: '#50fb5980',
  inputBackGround: '#e6e6e680',
}

class LogInBlackOut extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      warning: false,
      errorText: '',
      signUpInterface: false,
      name: '',
      nameValidateComplete: false,
      email: '',
      emailValidateComplete: false,
      city: '',
      cityValidateComplete: false,
      password: '',
      password2: '', 
      password2ValidateComplete: false,
    }
  }

  handleVkResponse = (data) => {
    console.log(data)
    this._signInOauth(data.session.user.href, 'vk', data.session.user.first_name + data.session.user.last_name, data.response[0].photo_400_orig, data.session.sig)
  }

  responseFacebook = (data) => {
    console.log(data);
    this._signInOauth(data.userID, 'fb', data.name, data.picture.data.url, data.accessToken)
  }

  responseGoogle = (data) => {
    console.log(data); 
    this._signInOauth(data.vw.profileObj.email, 'gl', data.vw.profileObj.name, data.vw.profileObj.imageUrl, data.vw.accessToken)
  }

  _validateName = (text) => {
  if (text.length > 3) {
    this.setState({ name: text, nameValidateComplete: true })
  }
  else {
    this.setState({ name: text, nameValidateComplete: false })
  }
}

  _validateCity = (text) => {
    if (text.length > 3) {
      this.setState({ city: text, cityValidateComplete: true })
    }
    else {
      this.setState({ city: text, cityValidateComplete: false })
    }
  }

  _validateEmail = (text) => {
    this.setState({ email: text })
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ emailValidateComplete: false })
    }
    else {
      this.setState({ emailValidateComplete: true })
    }

  }

  _validatePassword = (text, suffix1, suffix2) => {
    if ((text == this.state['password'+suffix1]) && (text != '')) {
      this.setState({ ['password' + suffix2]: text, password2ValidateComplete: true })
    }
    else {
      this.setState({ ['password' + suffix2]: text, password2ValidateComplete: false })
    }
  }


  _signInOauth = (email, socialNetwork, name, avatar, token) => {
    this.setState({ warning: false })
    let p = {
      email: email,
      socialNetwork: socialNetwork,
      name: name,
      avatar: avatar,
      token: token,
    }
    axi("signInOauth.php", "null", p).then((result) => {
      if (result.type === 'nobody') {
        alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
        this.context.addUser(null)
      } else {
        this.context.addUser({
          id: token,
          name: name,
          email: email,
          avatar: avatar,
          role: result.role,
        })
        if (result.cities != undefined) {
          this.context.addCities(result.cities)
        }
        if (result.donates != undefined) {
          this.context.addDonates(result.donates)
        }
        if (result.user.role == 'admin' || result.user.role == 'root') {
          this.props.route.history.push('/Admin')
        }
        this.context.toggleVisibilityLogInBlackOut()
      }
    },
      (e) => { console.log(e) })
  }

  _signInEmail = () => {
    this.setState({ warning: false })
    let p = {
      email: this.state.email,
      password: this.state.password,
    }
    axi("signInEmail.php", "null", p).then((result) => {
      let authRes = result
      console.log(authRes)
      if (result.type === 'nobody') {
        this.setState({ errorText: 'Не существует предложенной вами пары почты и пароля', warning: true,})
      } else {
        this.context.addUser(result.user)
        if (result.cities != undefined) { 
          this.context.addCities(result.cities)
        }
        if (result.donates != undefined) {
          this.context.addDonates(result.donates)
        }
        if (result.user.role == 'admin' || result.user.role == 'root') {
          this.props.route.history.push('/Admin') 
        }
        this.context.toggleVisibilityLogInBlackOut()
      }
    },
      (e) => { console.log(e) })
  }
  _signUpEmail = () => {
    this.setState({ warning: false })
    let p = {
      name: this.state.name,
      city: this.state.city,
      email: this.state.email,
      password: this.state.password,
    }
    axi("signUpEmail.php", "null", p).then((result) => {
      let authRes = result
      console.log(authRes)
      if (result.type === 'hasAlready') {
        this.setState({ errorText: 'Пользователь с таким eMail уже зарегистрирован', warning: true, })
      } else {
        this.context.addUser({
          id: result.id,
          name: this.state.name,
          city: this.state.city,
          email: this.state.email,
        })
        if (result.user.role == 'admin') { this.props.route.history.push('/Admin') }
        this.context.toggleVisibilityLogInBlackOut()
      }
    },
      (e) => { console.log(e) })
  }


  render() {
  return (
    <Container {...this.props}
      style={{
        height: this.context.visibilityLogInBlackOut ? '100vh' : 0
      }}>
      <div 
        style={{
            position: "absolute",
            height: this.context.visibilityLogInBlackOut ? '100vh' : 0,
            width: '100vw',
            zIndex: 1,
            backgroundColor: this.context.visibilityLogInBlackOut ? 'rgba(0,0,0,0.8)' : 'transparent',
            transition: '1s',
          }}
          onClick={() => { this.context.toggleVisibilityLogInBlackOut() }}
      />
      <LogInModal
      style={{
          transition: '2s',
          height: this.state.signUpInterface ? 600 : 400,
      }}
      >
        <div
        style={{
          flexDirection: 'row'
        }}>
          <LoremIpsum
            onClick={() => { this.setState({ signUpInterface: true }) }} 
            style={{
              transition: '2s',
              fontSize: this.state.signUpInterface ? 30 : 14,
              borderWidth: this.state.signUpInterface ? 0 : 2,
              color: this.state.signUpInterface ? '#fff' : 'rgba(215, 213, 21, 1)',
              borderColor: '#fff',
              borderRadius: 50,
              cursor: 'pointer'
            }}>
            Регистрация
          </LoremIpsum>
          <LoremIpsum
            onClick={() => { this.setState({ signUpInterface: false }) }} 
            style={{
              color: !this.state.signUpInterface ? '#fff' : 'rgba(215, 213, 21, 1)',
              transition: '2s',
              fontSize: this.state.signUpInterface ? 14 : 30,
              borderWidth: this.state.signUpInterface ? 2 : 1,
              borderColor: '#fff',
              borderRadius: 50,
              marginLeft: 20,
              cursor: 'pointer',
            }}>
            Вход
          </LoremIpsum>
        </div>
        <Rect93
          style={{
            height: this.state.signUpInterface ? 40 : 0,
            backgroundColor: this.state.nameValidateComplete ? colors.inputValidateComplete : colors.inputBackGround,
          }}>
          <TextInput3 
          placeholder="Фамилия Имя Отчество"
          value = {this.state.name}
            onChange={(e) => { this._validateName(e.target.value) }}></TextInput3>
        </Rect93>
        <Rect93
        style={{
            backgroundColor: this.state.emailValidateComplete ? colors.inputValidateComplete : colors.inputBackGround,
        }}
        >
          <TextInput 
            placeholder="Электронная почта"
            value={this.state.email}
            onChange={(e) => {
              this._validateEmail(e.target.value ) 
              }}
          />
        </Rect93>

        <Rect93
          style={{
            height: this.state.signUpInterface ? 40 : 0,
            backgroundColor: this.state.cityValidateComplete ? colors.inputValidateComplete : colors.inputBackGround,
            }}
          >
          <TextInput4 
            placeholder="Город"
            value={this.state.city}
            onChange={(e) => { this._validateCity(e.target.value) }}
          ></TextInput4>
        </Rect93>
        <Rect99
          style={{
            backgroundColor: this.state.password2ValidateComplete ? colors.inputValidateComplete : colors.inputBackGround,
          }}>
          <TextInput1 
            placeholder="Пароль" 
            type="password"
            value={this.state.password}
            onChange={(e) => {
              this._validatePassword(e.target.value, "2", '')
            }}></TextInput1>
        </Rect99>
        <Rect99
          style={{
            height: this.state.signUpInterface ? 40 : 0,
            backgroundColor: this.state.password2ValidateComplete ? colors.inputValidateComplete : colors.inputBackGround,
            }}>
          <TextInput2
            placeholder="Повторите пароль" 
            type="password"
            value={this.state.password2}
            onChange={(e) => {
              this._validatePassword(e.target.value, "", '2')
            }}
          ></TextInput2>
        </Rect99>
        <LoremIpsum
        style={{
            height: this.state.warning ? 44 : 0,
            //visibility: this.state.warning ? 'visible' : 'hidden',
          }}>
          {this.state.errorText}
        </LoremIpsum>
        <Rect97>
            <ButtonSupport1
              onClick={() => { this._signInEmail() }}
              style={{
                width: this.state.signUpInterface ?  0:160 ,
                cursor: 'pointer',
                //visibility: this.state.warning ? 'visible' : 'hidden',
              }}>
                <SignInButtonText>Войти</SignInButtonText>
            </ButtonSupport1>
          <VkAuth
            apiId="7526466" callback={this.handleVkResponse} 
          />
          <GoogleLogin
            clientId="274918411754-8pl8jk3uokbcbetscsje0l3qr9e05ob7.apps.googleusercontent.com"
            render={renderProps => (
              <div
                className='ButtonAuth'
                onClick={renderProps.onClick}
              >
                <FontAwesomeIcon
                  name="google"
                  style={{
                    color: "rgba(53,14,14,1)",
                    fontSize: 40
                  }}
                ></FontAwesomeIcon>
              </div>
            )}
            autoLoad={false}
            onSuccess={(response) => this.responseGoogle(response)}
            onFailure={(response) => this.responseGoogle(response)}
          />

          <FacebookLogin
            appId="604148803569457"
            autoLoad={false}
            fields="name,email,picture"
            callback={(response) => this.responseFacebook(response)}
            render={(renderProps) => (
            <div
              style={{
                marginRight: this.state.signUpInterface ? 75 : 0,
              }}
                className='ButtonAuth'
              onClick={renderProps.onClick}
            >
                <FontAwesomeIcon
                  name="facebook-f"
                  style={{
                    color: "rgba(53,14,14,1)",
                    fontSize: 40
                  }}
                ></FontAwesomeIcon>
            </div>)}
          />
        </Rect97>
        <ButtonSupport2 
            style={{
            height: (
                this.state.signUpInterface && 
                this.state.nameValidateComplete &&
                this.state.emailValidateComplete &&
                this.state.cityValidateComplete &&
                this.state.password2ValidateComplete
              ) ? 40 : 0
            }}
          onClick={() => { this._signUpEmail()}} 
          >
              <SignUpButtonText>Зарегистрироваться</SignUpButtonText>
          </ButtonSupport2>
      </LogInModal>
    </Container>
  );
  }
}
LogInBlackOut.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const LogInModal = styled.div`
  width: 400px;
  margin-top: 10vh;
  border-radius: 20px;
  overflow: hidden;
  background-color: #090c13;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: space-around;
  align-self: center;
  display: flex;
  box-shadow: 5px 5px 5px  1px rgba(0,0,0,1) ;
  z-index: 255;

  @media (max-width: 450px) {
    width: calc(100vw - 40px);

  }
`;

const Rect93 = styled.div`
transition: 2s;
overflow: hidden;
  height: 40px;
  align-self: stretch;
  border-radius: 20px;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: flex-start;
  display: flex;
`;

const TextInput = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const TextInput3 = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const TextInput4 = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const TextInput1 = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const Rect99 = styled.div`
overflow: hidden;
transition: 2s;
  height: 40px;
  align-self: stretch;
  border-radius: 20px;
  flex-direction: row;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: flex-start;
  display: flex;
`;

const TextInput2 = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  border: none;
  background: transparent;
`;

const LoremIpsum = styled.span`

  overflow: hidden;
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: rgba(220,85,85,1);
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  align-self: stretch;
  transition: 2s;
`;

const Rect97 = styled.div`
  height: 40px;
  transition: 2s;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const ButtonSupport1 = styled.div`
display: flex;
overflow: hidden;
transition: 2s;
  height: 40px;
  background-color: rgba(215,213,21,1);
  flex-direction: row;
  border-radius: 40px;
  //width: 160px;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: nowrap;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
`;

const SignInButtonText = styled.span`
  font-family: roboto-500;
  color: rgba(53,14,14,1);
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  line-height: 40px;
  align-self: center;
  text-align: center;
  padding: 0px;
  margin: 0px;
  width: auto;
`;


const ButtonSupport4 = styled.div`
display: flex;
  height: 40px;
  background-color: rgba(215,213,21,1);
  flex-direction: row;
  border-radius: 40px;
  width: 40px;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: nowrap;
  justify-content: center;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
`;

const ButtonSupport2 = styled.div`
display: flex;
overflow: hidden;
transition: 2s;
cursor: pointer;
  height: 40px;
  width: 340px;
  background-color: rgba(215,213,21,1);
  flex-direction: row;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  flex-wrap: nowrap;
  align-self: center;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
`;

const SignUpButtonText = styled.span`
  font-family: roboto-500;
  color: rgba(53,14,14,1);
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  line-height: 40px;
  top: 20px;
  left: 168px;
  align-self: flex-start;
  text-align: center;
  padding: 0px;
  margin: 0px;
  height: 30px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
`;

export default LogInBlackOut;
