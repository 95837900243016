import React, { Component } from "react";
import styled, { css } from "styled-components";
import DataSelector from "./DataSelector";
import EntypoIcon from "react-native-vector-icons/dist/Entypo";
import { ThemeContext } from '../contexts';
import axi from '../functions/axiosf';
import russia from '../assets/maps/russia.json';
import YouTube from 'react-youtube';
let citiesBase = russia.layers;

class AddGoodComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      idRegion: '',
      idCity: 0,
      title: '',
      price: null,
      text: '',
      newTag: '',
      properties: [],
      imageUrl: '',
      titleWarning: false,
      textWarning: false,
    }
  }

  componentDidMount(){
    if (typeof(this.props.tab) == "number") {
      for(let i=0; i<this.context.cities.length; i++){
        if (+this.context.cities[i].idCity == this.props.tab) { 
          this.setState({
            idCity: +this.props.tab,
            idRegion: this.context.cities[i].idRegion,
          }) 
        }
      }
    }
  }

  axiAdd(url){
    if(!this.state.success)
    {
      let p ={
        ...this.state,
        uid: this.context.user.id
      }
      axi(url, "null", p).then((result) => {
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с другого устройства. Для подтверждения, что это Вы, войдите заново')
          this.context.addUser(null)
        } else {
          if(result.type == "error"){
            alert('Ошибка добавдения новости '+this.state.name)
          }else{
            alert('товар "' + this.state.title + '" добавлен успешно')
          }
        }
        if (url == 'addGoods.php'){
          this.setState({
            title: '',
            price: 0,
            text: '',
            properties: [],
            imageUrl: '',
            success: true,
          })
        }

      },
        (e) => { console.log('ошибка создания нового товара', e) })
    } 
  }

 
  _delPhoto = (item, index) => {
    //let copy = this.state.cityPhotos
    //copy.splice(index, 1)
    this.setState({ cityPhotos: ''})
  }

  _delVideo = (item, index) => {
    //let copy = this.state.cityVideos
    //copy.splice(index, 1)
    this.setState({ cityVideos: '' })
  }

  _onReadyVideo(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  _getYoutubeID(link) {
    return link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
  }

  _addTag = () =>{
    this.setState({ properties: [this.state.newTag, ...this.state.properties], newTag: ''})
  }

  _deleteTag = (item, index) =>{
    let np =[]
    let i= 0
    while(this.state.properties[i]){
      if (i != index) {
        np = [...np, this.state.properties[i]]
      }
      i++
    }
    this.setState({ properties: np})
  }

  render(){
  return (
    <Container
      style={{...this.props.style, backgroundImage: 'url:"'+this.state.imageUrl+'"'}}
    >
      <RectCityсolumnDescription
        style={document.body.clientWidth < 900 ?
          {
            width: document.body.clientWidth - 58,
          }
          : {}}>
        <p style={{
          fontFamily: 'roboto-700',
          height: 'auto',
          color: "rgba(228,212,212,1)",
          fontSize: 19,
          margin: 12,
        }}>{"Создание нового товара"}</p>
        <TitleRegion>Название товара</TitleRegion>
        <TextInputRegion
          style={{ backgroundColor: this.state.titleWarning && '#ff00004f' }}
          placeholder="Введите название товара от 10 до 255 символов"
          value={this.state.title} 
          onChange={(e) => {
            let textWarning = true
            if (e.target.value.length > 9) { textWarning = false }
            this.setState({ title: e.target.value, titleWarning: textWarning })
          }}
        ></TextInputRegion>
        <div 
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignSelf: 'stretch'
          }}>
          <TitleDateConcert>Цена</TitleDateConcert>
          <TextInputRegion
            style={{ 
              backgroundColor: this.state.titleWarning && '#ff00004f',
              width: 40,
              marginLeft: 8,
              marginRight: 8, }}
            placeholder=""
            max={1000000} 
            value={this.state.price}
            type={'number'}
            onChange={(e) => {
              this.setState({ price: e.target.value })
            }}
          ></TextInputRegion>
          <b style={{ color: '#aaa' }}> руб.</b>
        </div>

        <TitleFullDescription>Описание товара</TitleFullDescription>
        <TextInputFullDescription
          style={{ backgroundColor: this.state.textWarning && '#ff00004f' }}
          placeholder="Подробное описание товара. Будет отображаться на главной в карточке товара. Минимальный обьём текста 100 символов"
          maxLength={4096}
          value={this.state.text}
          onChange={(e) => {
            let textWarning = true
            if (e.target.value.length > 100) { textWarning = false }
            this.setState({ text: e.target.value, textWarning: textWarning })
          }}
        ></TextInputFullDescription>

      </RectCityсolumnDescription>
      <RectCityсolumnDescription
        style={document.body.clientWidth < 900 ?
          {
            width: document.body.clientWidth - 58,
          }
          : {}}
      >
        <TitlePhoto>Фото</TitlePhoto>
        <TextInputCity
          placeholder="вставьте ссылку на фото"
          value={this.state.imageUrl}
          onChange={(e) => { this.setState({ imageUrl: e.target.value }) }}
        ></TextInputCity>
        <TitleBriefDescription>Варианты свойств товара</TitleBriefDescription>
        <spane style={{color: '#aaa'}}>Добавьте все возможные варианты свойств товара.
         Если в отпускаемом товаре несколько категорий свойств перечисляйте их все в 
         пределах одного общего варианта. Пример для футболок: [синяя L], [красная S], [чёрная L]
         </spane>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            height: 140,
            width: 250,
            flexWrap: 'wrap',
            overflowY: 'scroll',
            display: 'flex'
          }}>
          <div className="inputConteiner150">
            <input
              style={{
                width: this.state.newTag.length*8,
                minWidth: 50,
                maxWidth: 197,
                height: 24,
                backgroundColor: '#0000',
                color: '#fff',
                margin: 3,
                borderWidth: 0
              }}
              value={this.state.newTag}
              onChange={(e) => this.setState({ newTag: e.target.value })} 
              onKeyPress={(e) => {
                if (e.key == 'Enter') {
                  this._addTag()
                }
              }}
            ></input>
            <EntypoIcon
              name="squared-plus"
              style={{
                color: "#ffcb17",
                fontSize: 24,
                margin: 3,
                cursor: "pointer"
              }}
              onClick={() => { this._addTag() }}
            />
          </div>
          {
            this.state.properties.map((item, index)=>{
              return(  
                <div className={"tegConteiner"} key={index}>
                  <span style={{ margin: 3, maxWidth: 197}}>{item}</span>
                  <EntypoIcon
                    name="squared-cross"
                    style={{
                      color: "#111",
                      fontSize: 24,
                      margin: 3,
                      cursor: "pointer"
                    }}
                    onClick={() => { this._deleteTag(item, index) }}
                  />
                </div>
              )
            })
          }

        </div>
        <div
          className={'ButtonSupport'}
          style={document.body.clientWidth < 900 ?
            {
              width: document.body.clientWidth - 88,
              alignSelf: 'center',
              marginRight: 0,
            }
            : {}
          }
          onClick={() => {
            let f = false, fr = false
            if (this.state.title.length > 10) {
              fr = true
            } else { this.setState({ titleWarning: true }) }
            if (this.state.text.length > 100) {
              f = true
            }
            else { this.setState({ textWarning: true }) }
            if (f && fr) { this.axiAdd(this.props.clickAddFunc) }
          }}
        >
          <span className='ButtonSupportText' >Добавить товар</span>

        </div>
      </RectCityсolumnDescription>
    </Container>
  )}
}

AddGoodComponent.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  background-color: rgba(4,4,4,0.5);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
`;

const RectCityсolumnDescription = styled.div`
  width: 250px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 15px;
  display: flex;
`;

const TitleRegion = styled.span`
  font-family: Roboto-500;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputRegion = styled.input`
  font-family: Roboto-300;
  height: 24px;
  background-color: #000000a6 ;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: regular;
  font-style: normal;
  line-height: 16px;
  border: none;
`;


const TextInputCity = styled.input`
  font-family: Roboto-300;
  height: 24px;
  background-color: #000000a6;align-self: stretch;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: regular;
  font-style: normal;
  line-height: 16px;
  border: none;
`;

const TitleDateConcert = styled.span`
  font-family: Roboto-500;
  width: 256px;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TitleBriefDescription = styled.span`
  font-family: Roboto-500;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;


const TitleFullDescription = styled.span`
  font-family: Roboto-500;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const TextInputFullDescription = styled.textarea`
  font-family: Roboto-300;
  height: 200px;
  background-color: #000000a6;
  color: rgba(209,209,209,1);
  ::-webkit-input-placeholder {color:rgba(49,49,49,1);}
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: regular;
  font-style: normal;
  line-height: 14px;
  border: none;
`;

const RectCityсolumnMedia = styled.div`
  width: 300px;
  flex-direction: column;
  align-self: stretch;
  justify-content: flex-start;
  margin: 0px;
  padding: 15px;
  display: flex;
`;

const TitlePhoto = styled.span`
  font-family: Roboto-500;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
  margin-top: 46px;
`;

const RectPhotos = styled.div`
  flex: 1 1 0%;
  height: 100px;
  flex-direction: row;
  //align-self: stretch;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
      overflow-y: scroll;
`;

const RectPhoto = styled.div`
  width: 120px;
  height: 80px;
  background-color: rgba(4,4,4,1);
  border-radius: 8px;
  margin-bottom: 10px;
`;

const TitleVideo = styled.span`
  font-family: Roboto;
  height: 20px;
  color: rgba(218,179,0,1);
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: left;
`;

const RectVideos = styled.div`
  height: 120px;
  //width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  overflow-x: scroll;
`;


export default AddGoodComponent;
