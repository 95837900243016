import React, { Component } from "react";
import styled, { css } from "styled-components";
import RectFilterFromComponent from "./RectFilterFromComponent";
import RectFilterToComponent from "./RectFilterToComponent";
import { ThemeContext } from '../contexts';
import russia from '../assets/maps/russia.json';
import france from '../assets/maps/france-new.json'
import ukraine from '../assets/maps/ukraine.json'
import belarus from '../assets/maps/belarus.json'
let citiesBase = [...russia.layers, ...ukraine.layers, ...belarus.layers, ...france.layers];



class FiltersBlogComponent extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      actRegions: [],
      currentRegion: 'Глобальные',
      currentRegionId: '',
      regionSelector: false,
      fromDate: 0,
      toDate: 2147483640000,
    }
  }

  componentDidMount(){
    setTimeout(() => {
      this.setActRegions()
    }, 2000)
  }

  setActRegions = () => {
    let actRegions = [{ id: '', name: 'Глобальные' }]
    let f = false
    let current = 'Глобальные'
    let tp = "faq"
    if (this.props.type) {
      if (this.props.type) {   
    if (this.props.type) {
      tp = this.props.type
        tp = this.props.type     
      tp = this.props.type
    }
      } 
    }
    citiesBase.map((item, index) => {
      f = false
      this.context.base[tp].map((jtem, jndex) => {
        if (item.id == jtem.idRegion && !f) {
          actRegions = [...actRegions, item]
          f = true
        }
      })
      if (this.props.item) {
        if (item.id == this.props.item.idRegion) {
          if (item.id == this.props.item.idRegion) { 
        if (item.id == this.props.item.idRegion) {
          current = item.name
        }
      }
        }  
      }
    })
    this.setState({ actRegions: actRegions, currentRegion: (current != '') ? current : 'Глобальные' })
    return (actRegions)
  }

  _gotoRegion = (item) => {
    this.setState({ regionSelector: false, currentRegion: item.name, currentRegionId: item.id})
    let news = []
    this.context.base[this.props.type].map((itemB, index) => {
      if ((itemB.idRegion == item.id) && (+itemB.date >= this.state.fromDate) && (+itemB.date <= (this.state.toDate + 86400000))) {
        news = [itemB, ...news]
      }
    })
    this.props.history.push({
      pathname: '/Blog',
      state: { item: news[0], other: news, type: this.props.type}
    }) 
  }

  _gotoFAQ=()=>{
    this.props.history.push({
      pathname: '/Blog',
      state: { item: this.context.base.faq[0], other: this.context.base.faq, type: 'faq' }
    })
    setTimeout(() => {
      let oldCurrentRegionId = this.state.currentRegionId
      let oldCurrentRegion = this.state.currentRegion
      let newActRegions = this.setActRegions()
      let nar = 0
      let narFlag = false
      while (newActRegions[nar]){
        if (newActRegions[nar].id == oldCurrentRegionId){
          narFlag=true
        }
        nar++
      }
      if (narFlag){
        this._gotoRegion({ id: oldCurrentRegionId, name: oldCurrentRegion })
      }else{
        this._gotoRegion({ id: '', name: 'Глобальные' })
      }
      
    }, 100)
  }

  _gotoNews = () => {
    this.props.history.push({
      pathname: '/Blog',
      state: { item: this.context.base.blog[0], other: this.context.base.blog, type: 'blog' }
    })
    setTimeout(() => {
      let oldCurrentRegionId = this.state.currentRegionId
      let oldCurrentRegion = this.state.currentRegion
      let newActRegions = this.setActRegions()
      let nar = 0
      let narFlag = false
      while (newActRegions[nar]) {
        if (newActRegions[nar].id == oldCurrentRegionId) {
          narFlag = true
        }
        nar++
      }
      if (narFlag) {
        this._gotoRegion({ id: oldCurrentRegionId, name: oldCurrentRegion })
      } else {
        this._gotoRegion({ id: '', name: 'Глобальные' })
      }
    }, 100)
  }

  _goFromDate=(cD) => {
    this.setState({ fromDate: cD })
    let news = []
    this.context.base[this.props.type].map((itemB, index) => {
      if (itemB.idRegion == this.state.currentRegionId && +itemB.date >= cD && +itemB.date <= this.state.toDate + 86400000) {
        news = [itemB, ...news]
      }
    })
    this.props.history.push({
      pathname: '/Blog',
      state: { item: news[0], other: news, type: this.props.type }
    }) 
   }

  _goToDate = (cD) => {
    this.setState({ toDate: cD })
    let news = []
    this.context.base[this.props.type].map((itemB, index) => {
      if (itemB.idRegion == this.state.currentRegionId && +itemB.date <= cD + 86400000 && +itemB.date >= this.state.fromDate) {
        news = [itemB, ...news]
      }
    })
    this.props.history.push({
      pathname: '/Blog',
      state: { item: news[0], other: news, type: this.props.type }
    })  }

  render(){

    let tD = this.props.other[this.props.other.length - 1] ? this.props.other[this.props.other.length - 1].date : +new Date()
    let fD = this.props.other[0] ? this.props.other[0].date : +new Date()

  return (
    <Container style={this.props.style}>
      <GroupFilterQn>
        <ButtonFilterQ
          style={{ backgroundColor: this.props.type == 'faq' ? 'rgba(215,213,21,1)' : 'rgba(230, 230, 230,1)',}}
          onClick={() => { this._gotoFAQ() }}
        >
            <Вопросы>Вопросы</Вопросы>
        </ButtonFilterQ>
        <ButtonFilterNews
          style={{ backgroundColor: this.props.type == 'blog' ? 'rgba(215,213,21,1)' : 'rgba(230, 230, 230,1)', }}
          onClick={() => { this._gotoNews() }}
          >
            <Новости>Новости</Новости>
        </ButtonFilterNews>
      </GroupFilterQn>
      <RectFilterCity
          style={this.state.regionSelector?{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }:{}}
        onClick={() => { this.setState({ regionSelector: !this.state.regionSelector})}}
        >
        <RegionSelector>{this.state.currentRegion}</RegionSelector>
      <div
        style={{
          height: 0,
          overflow: 'show',
          zIndex: 5,
          cursor: 'pointer',
          marginTop: 0,
          width: 272,
        }}>
          {this.state.regionSelector && <div
            style={{
              height: 20,
            }}/>}
          <div style={{
            height: 'auto',
            maxHeight: 350,
            overflow: 'auto',
            cursor: 'pointer',
            backgroundColor: 'rgba(230, 230, 230,1)',
            width: 272,
          }}>

          {this.state.regionSelector && this.state.actRegions.map((item, index)=>
            <RectFilterCity
              key={index}
              style={{
                borderRadius: 0,
                //borderBottomRightRadius: 0,
                marginRight:0,
                marginLeft: 0,
              }}
              onClick={() => { this._gotoRegion(item) }}
            >
              <RegionSelector>{item.name}</RegionSelector>
            </RectFilterCity>
          )}
        </div>    
      </div>
      </RectFilterCity>
      <RectFilterFromComponent
        style={{
          height: 45,
          alignSelf: "stretch",
          marginRight: 14,
          marginLeft: 14,
          paddingRight: 14
        }}
        date={(this.state.fromDate > 0) ? this.state.fromDate : fD}
        setDate={(concertDay) => { this._goFromDate(concertDay)}}
      ></RectFilterFromComponent>
      <RectFilterToComponent
        style={{
          height: 46,
          alignSelf: "stretch",
          marginRight: 14,
          marginLeft: 14,
          marginBottom: 20,
          paddingRight: 14,
          paddingLeft: 0
        }}
        date={(this.state.toDate < 2147483640000) ? this.state.toDate : tD}
        setDate={(concertDay) => { this._goToDate(concertDay)}}
      ></RectFilterToComponent>
    </Container>
  );}
}

FiltersBlogComponent.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  background-color: rgba(0,0,0,0.39);
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
`;

const ButtonOverlay = styled.button`
 display: block;
 background: none;
 height: 100%;
 width: 100%;
 border:none
 `;
const GroupFilterQn = styled.div`
  height: 46px;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 14px;
  margin-bottom: 0px;
  margin-left: 14px;
  display: flex;
`;

const ButtonFilterQ = styled.div`
  flex: 1 1 0%;
  background-color: rgba(230, 230, 230,1);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: none;
  display: flex;
`;

const Вопросы = styled.span`
  font-family: Roboto-500;
  height: 21px;
  color: #121212;
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: center;
`;

const ButtonFilterNews = styled.div`
  flex: 1 1 0%;
  background-color: rgba(215,213,21,1);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  margin-left: 8px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: none;
  display: flex;
`;

const Новости = styled.span`
  font-family: Roboto-500;
  height: 21px;
  color: #121212;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: center;
`;

const RectFilterCity = styled.div`
  height: 46px;
  background-color: rgba(230, 230, 230,1);
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  margin-top: 0px;
  margin-right: 14px;
  margin-bottom: 0px;
  margin-left: 14px;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  transition: 1s;
  :hover{
    background-color: rgba(215,213,21,1);
  }
`;

const RegionSelector = styled.span`
  font-family: Roboto-500;
  height: 22px;
  color: #121212;
  align-self: stretch;
  font-size: 20px;
  font-weight: regular;
  font-style: normal;
  line-height: 20px;
  text-align: center;
`;

export default FiltersBlogComponent;