import React, { Component } from "react";
import styled, { css } from "styled-components";
import DataSelector from "./DataSelector";

function RectFilterToComponent(props) {
  return (
    <Container {...props}>
      <TxtDateTo>По</TxtDateTo>
      <DataSelector
        style={{
          width: 211,
          alignSelf: "stretch"
        }}
        colorBack={'#fff7'}
        setDate = {(concertDay) => props.setDate(concertDay)}
        concertDay={+props.date}
      ></DataSelector>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-right: 14px;
  margin-bottom: 0px;
  margin-left: 14px;
`;

const TxtDateTo = styled.span`
  font-family: Roboto-700;
  width: 38px;
  height: 26px;
  color: rgba(216,214,214,1);
  font-size: 26px;
  line-height: 26px;
`;

export default RectFilterToComponent;
