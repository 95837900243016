import React, { Component } from "react";
import styled, { css } from "styled-components";

function RectLineStatDonats(props) {
  return (
    <Container {...props}>
      <DivTitleRegion>
        <TextTitleRegion>Великий Новгород</TextTitleRegion>
      </DivTitleRegion>
      <DivTitleFond>
        <TextTitleFond>{props.text1 || "Фонд"}</TextTitleFond>
      </DivTitleFond>
      <DivTitleNum>
        <TextTitleNum>7600 руб</TextTitleNum>
      </DivTitleNum>
      <Rect></Rect>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
`;

const DivTitleRegion = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  align-self: center;
  display: flex;
`;

const TextTitleRegion = styled.span`
  font-family: Roboto;
  flex: 1 1 0%;
  color: rgba(210,202,202,1);
  flex-direction: column;
  align-self: center;
  justify-content: space-between;
  font-weight: regular;
  font-style: normal;
  text-align: left;
  display: flex;
`;

const DivTitleFond = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  align-self: center;
  display: flex;
`;

const TextTitleFond = styled.span`
  font-family: Roboto;
  height: 14px;
  color: rgba(210,202,202,1);
  align-self: stretch;
  font-weight: regular;
  font-style: normal;
  text-align: left;
`;

const DivTitleNum = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  align-self: center;
  display: flex;
`;

const TextTitleNum = styled.span`
  font-family: Roboto;
  height: 14px;
  color: rgba(210,202,202,1);
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  font-weight: regular;
  font-style: normal;
  text-align: right;
`;

const Rect = styled.div`
  top: 23px;
  left: 34px;
  width: 0px;
  height: -2px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

export default RectLineStatDonats;
