import React, { Component } from "react";
import styled, { css } from "styled-components";
import RectFilterFromComponent from "./RectFilterFromComponent";
import RectFilterToComponent from "./RectFilterToComponent";
import RectLineStatDonats from "./RectLineStatDonats";
import ButtonOrderVolunteer from "./ButtonOrderVolunteer";
import LineCityToGoComponent from "./LineCityToGoComponent";

class AdminStatComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      timeStart: 0,
      timeStop: 0,
    }
  }

  render(){
  return (
    <Container style={this.props.style}>
      <TitleDivStat>
        <TitleStatTxt>Статистика</TitleStatTxt>
        <RectFilterFromComponent
          style={{
            width: 272,
            height: 41,
            marginRight: 0,
            marginLeft: 0
          }}
          setDate={(concertDay) => this.setState({ timeStart: concertDay })}
        ></RectFilterFromComponent>
        <RectFilterToComponent
          style={{
            width: 278,
            height: 41,
            marginRight: 0,
            marginLeft: 0
          }}
          setDate={(concertDay) => this.setState({ timeStop: concertDay })}
        ></RectFilterToComponent>
      </TitleDivStat>
      <Rect3Colums>
        <RectDonats>
          <TitleDonats>Пожертвования</TitleDonats>
          <RectLineStatDonats
            style={{
              height: 45,
              alignSelf: "stretch",
              marginTop: 6,
              marginBottom: 0
            }}
            text1=""
          ></RectLineStatDonats>
          <RectLineStatDonats
            style={{
              height: 45,
              alignSelf: "stretch"
            }}
          ></RectLineStatDonats>
          <RectLineStatDonats
            style={{
              height: 45,
              alignSelf: "stretch"
            }}
          ></RectLineStatDonats>
          <RectLineStatDonats
            style={{
              height: 45,
              alignSelf: "stretch"
            }}
          ></RectLineStatDonats>
          <RectLineStatDonats
            style={{
              height: 45,
              alignSelf: "stretch"
            }}
          ></RectLineStatDonats>
        </RectDonats>
        <RectNewVolunteers>
          <TitleNewVolunteers>Заявки от {"\n"}Волонтёров</TitleNewVolunteers>
          <ButtonOrderVolunteer
            style={{
              height: 32,
              alignSelf: "stretch",
              boxShadow: "2px 5px 5px  0px rgba(0,0,0,1) "
            }}
          ></ButtonOrderVolunteer>
          <ButtonOrderVolunteer
            style={{
              height: 32,
              alignSelf: "stretch",
              boxShadow: "2px 5px 5px  0px rgba(0,0,0,1) "
            }}
          ></ButtonOrderVolunteer>
          <ButtonOrderVolunteer
            style={{
              height: 32,
              alignSelf: "stretch",
              boxShadow: "2px 5px 5px  0px rgba(0,0,0,1) "
            }}
          ></ButtonOrderVolunteer>
        </RectNewVolunteers>
        <RectVisitors>
          <TitleVisitors>Придут на концерт</TitleVisitors>
          <LineCityToGoComponent
            style={{
              height: 45,
              alignSelf: "stretch"
            }}
          ></LineCityToGoComponent>
          <LineCityToGoComponent
            style={{
              height: 45,
              alignSelf: "stretch"
            }}
          ></LineCityToGoComponent>
          <LineCityToGoComponent
            style={{
              height: 45,
              alignSelf: "stretch"
            }}
          ></LineCityToGoComponent>
          <LineCityToGoComponent
            style={{
              height: 45,
              alignSelf: "stretch"
            }}
          ></LineCityToGoComponent>
        </RectVisitors>
      </Rect3Colums>
    </Container>
  );}
}

const Container = styled.div`
  display: flex;
  background-color: rgba(4,4,4,0.5);
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
  border-radius: 20px;
`;

const TitleDivStat = styled.div`
  height: 60px;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-around;
  padding-right: 0px;
  padding-left: 0px;
  display: flex;
`;

const TitleStatTxt = styled.span`
  font-family: Roboto;
  width: 170px;
  height: 22px;
  color: rgba(255,197,54,1);
  align-self: center;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  line-height: 22px;
  text-align: right;
`;

const Rect3Colums = styled.div`
  flex: 1 1 0%;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-around;
  margin-top: 0px;
  align-items: flex-start;
  display: flex;
`;

const RectDonats = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  margin: 8px;
  padding-right: 0px;
  padding-left: 0px;
  display: flex;
`;

const TitleDonats = styled.span`
  font-family: Roboto;
  height: 45px;
  color: rgba(255,197,54,1);
  align-self: stretch;
  font-size: 22px;
  font-weight: regular;
  font-style: normal;
  text-align: center;
`;

const RectNewVolunteers = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  margin: 8px;
  margin-right: 0px;
  margin-left: 0px;
  border-color: rgba(190,188,188,1);
  border-width: 0px;
  border-right-width: 2px;
  border-left-width: 2px;
  border-style: solid;
  display: flex;
`;

const TitleNewVolunteers = styled.span`
  font-family: Roboto;
  height: 45px;
  color: rgba(255,197,54,1);
  align-self: stretch;
  font-size: 22px;
  font-weight: regular;
  font-style: normal;
  line-height: 22px;
  text-align: center;
`;

const RectVisitors = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  margin: 8px;
  display: flex;
`;

const TitleVisitors = styled.span`
  font-family: Roboto;
  height: 45px;
  color: rgba(255,197,54,1);
  align-self: stretch;
  font-size: 22px;
  font-weight: regular;
  font-style: normal;
  text-align: center;
`;

export default AdminStatComponent;
