import React, { Component } from "react";
import styled, { css } from "styled-components";

class DataSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      daysPicker: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      mPicker: [1,2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      yPicker: [2018,2019,2020,2021,2022,2023,2024,2025],
      dSelector: [],
      mSelector: [],
      ySelector: [],
      d: null,
      m: null,
      y: null,
      heightItem: 0,
    }
  }

componentDidMount(){
}

  _changeD = (value) => {
    if (+value < 32 /* && +value > 0 */){
      let incl = []
          this.state.daysPicker.map((item, index) => {
            let itemStr = item + ''
            if (itemStr.includes(value+'')) {
              incl = [...incl, item]
            }
          })
      if (incl.length > 1) {
        this.setState({ d: value, dSelector: incl })
      }else{
        this.setState({ d: value, dSelector: [] })
      }
    }
    let m = (this.props.concertDay) ? new Date(this.props.concertDay).getMonth() + 1 : this.state.m
    let y = (this.props.concertDay) ? new Date(this.props.concertDay).getFullYear() : this.state.y
    this._setDate(value, m, y)
  }

  _clickD = () => {
    let incl = []
    this.state.daysPicker.map((item, index) => {
      let itemStr = item+''
      if (itemStr.includes(this.state.d+'')) {
        incl = [...incl, item]
      }
    })
    if (this.state.d == null) { incl = this.state.daysPicker}
    this.setState({ dSelector: incl })
  }

  _selectedD = (item) => {
    this.setState({ d: item, dSelector: [] })    
    let m = (this.props.concertDay) ? new Date(this.props.concertDay).getMonth() + 1 : this.state.m
    let y = (this.props.concertDay) ? new Date(this.props.concertDay).getFullYear() : this.state.y
    this._setDate(item, m, y)
  }

  _changeM = (value) => {
    if (+value < 13 /* && +value > 0 */) {
      let incl = []
      this.state.mPicker.map((item, index) => {
        let itemStr = item + ''
        if (itemStr.includes(value + '')) {
          incl = [...incl, item]
        }
      })
      if (incl.length > 1) {
        this.setState({ m: value, mSelector: incl })
      } else {
        this.setState({ m: value, mSelector: [] })
      }
    }
    let d = (this.props.concertDay) ? new Date(this.props.concertDay).getDate() : this.state.d
    let y = (this.props.concertDay) ? new Date(this.props.concertDay).getFullYear() : this.state.y
    this._setDate(d, value,  y)
  }

  _clickM = () => {
    let incl = []
    this.state.mPicker.map((item, index) => {
      let itemStr = item + ''
      if (itemStr.includes(this.state.m + '')) {
        incl = [...incl, item]
      }
    })
    if (this.state.m == null) { incl = this.state.mPicker }
    this.setState({ mSelector: incl })
  }

  _selectedM = (item) => {
    this.setState({ m: item, mSelector: [] })
    let d = (this.props.concertDay) ? new Date(this.props.concertDay).getDate() : this.state.d
    let y = (this.props.concertDay) ? new Date(this.props.concertDay).getFullYear() : this.state.y
    this._setDate(d, item, y)
  }

  _changeY = (value) => {

    if (+value < 3000  && +value > 0 ) {
      let incl = []
      this.state.yPicker.map((item, index) => {
        let itemStr = item + ''
        if (itemStr.includes(value + '')) {
          incl = [...incl, item]
        }
      })
      if (incl.length > 1) {
        this.setState({ y: value, ySelector: incl })
      } else {
        this.setState({ y: value, ySelector: this.state.yPicker }) //чтобы можно было выбрать год, когда зависает набор
      }
    }
  

    this._setDate(this.state.d, this.state.m, value)
  }

  _clickY = () => {
    let incl = []
    this.state.yPicker.map((item, index) => {
      let itemStr = item + ''
      if (itemStr.includes(this.state.y + '')) {
        incl = [...incl, item]
      }
    })
    if (this.state.y == null) { incl = this.state.yPicker }
    this.setState({ ySelector: incl })
  }

  _selectedY = (item) => {
    this.setState({ y: item, ySelector: [] })
    let d = (this.props.concertDay) ? new Date(this.props.concertDay).getDate() : this.state.d
    let m = (this.props.concertDay) ? new Date(this.props.concertDay).getMonth() + 1 : this.state.m
    this._setDate(d, m, item)
  }

  _setDate = (d, m, y) => {
    let concertDay = +new Date(y, m-1, d);
    console.log(concertDay)
    this.props.setDate(concertDay)
  }

  render(){
    return (
      <Container style={this.props.style}>
        <RectFromDateInput
          colorBack={this.props.colorBack}
        >
          <TextFromDateInput 
            placeholder="01" 
            type={'number'}
            min={1}
            max={31}
            value={(this.props.concertDay) ? new Date(this.props.concertDay).getDate() : this.state.d}
            onChange={(e)=>{this._changeD(e.target.value)}}
            onFocus={() => { this._clickD() }}
            onBlur={() => { setTimeout(()=>{ this.setState({ dSelector: [] }) }, 500) }}
          />
          <div
            className={'selectionCityCont'}
            style={{
              maxHeight: 0,
              backgroundColor: '#5559',
              width: '100%',
              justifyContent: 'row',

            }}
          >
            {this.state.dSelector.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: ((this.state.heightItem == index) ? 30 : 25),
                    height: document.body.clientWidth > 640 ? ((this.state.heightItem==index)?35:20): 35,
                    margin: 1,
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: (this.state.d == item) && '#ffcb17',
                  }}
                  className={'selectionCityDiv'}
                  onClick={() => {
                    this._selectedD(item)
                  }}
                  onMouseMove={() => {
                    this.setState({ heightItem: index})
                  }}
                  onMouseLeave={() => {
                    this.setState({ heightItem: null })
                  }}
                >
                  <p 
                    className={'selectionCityText'}
                    style={{ color: (this.state.d == item) && '#000', textAlign: 'center', marginLeft: 0 }}
                  >{item}</p>
                </div>
              )
            })}
          </div>
        </RectFromDateInput>
        <RectFromMonthInput
          colorBack={this.props.colorBack}
        >
          <TextFromMonthInput 
            placeholder="04" 
            maxLength={2}
            type={'number'}
            min={1}
            max={12} 
            value={(this.props.concertDay) ? new Date(this.props.concertDay).getMonth() + 1 : this.state.m}
            onChange={(e) => { this._changeM(e.target.value) }}
            onFocus={() => { this._clickM() }}
            onBlur={() => { setTimeout(() => { this.setState({ mSelector: [] }) }, 500) }}/>
          <div
            className={'selectionCityCont'}
            style={{
              maxHeight: 0,
              backgroundColor: '#5559',
              width: '100%',
            }}
          >
            {this.state.mSelector.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: 50,
                    height: document.body.clientWidth > 640 ? ((this.state.heightItem == index) ? 40 : 20) : 34,
                    marginTop: 1,
                    marginBottom: 0,
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: (this.state.m == item) && '#ffcb17',
                  }}
                  className={'selectionCityDiv'}
                  onClick={() => {
                    this._selectedM(item)
                  }}
                  onMouseMove={() => {
                    this.setState({ heightItem: index })
                  }}
                  onMouseLeave={() => {
                    this.setState({ heightItem: null })
                  }}
                >
                  <p 
                    className={'selectionCityText'}
                    style={{ color: (this.state.m == item) && '#000', textAlign: 'center', marginLeft: 0 }}
                  >{item}</p>
                </div>
              )
            })}
          </div>
        </RectFromMonthInput>
        <RectFromYearInput
          colorBack={this.props.colorBack}
        >
          <TextFromYearthInput 
            placeholder="2020"
            type={'number'}
            min={2020}
            max={2025} 
            value={(this.props.concertDay) ? new Date(this.props.concertDay).getFullYear() : this.state.y}
            onChange={(e) => { this._changeY(e.target.value) }}
            onFocus={() => { this._clickY() }}
            onBlur={() => { setTimeout(() => { this.setState({ ySelector: [] }) }, 500) }}/>
          <div
            className={'selectionCityCont'}
            style={{
              maxHeight: 0,
              backgroundColor: '#5559',
              width: '100%',
            }}
          >
            {this.state.ySelector.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: 50,
                    height: document.body.clientWidth > 640 ? ((this.state.heightItem == index) ? 40 : 20) : 34,
                    marginTop: 1,
                    marginBottom: 0,
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: (this.state.y == item) && '#ffcb17',
                  }}
                  className={'selectionCityDiv'}
                  onClick={() => {
                    this._selectedY(item)
                  }}
                  onMouseMove={() => {
                    this.setState({ heightItem: index })
                  }}
                  onMouseLeave={() => {
                    this.setState({ heightItem: null })
                  }}
                >
                  <p 
                    className={'selectionCityText'}
                    style={{ color: (this.state.y == item) && '#000', textAlign: 'center', marginLeft: 0 }}
                  >{item}</p>
                </div>
              )
            })}
          </div>
        </RectFromYearInput>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RectFromDateInput = styled.div`
  width: 60px;
  background-color: rgba(230, 230, 230,1);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  background-color: ${props => props.colorBack };
`;

const TextFromDateInput = styled.input`
  font-family: Roboto-500;
  height: 21px;
  color: #121212;
  align-self: stretch;
  padding-left: 8px;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  border: none;
  background: transparent;
`;

const RectFromMonthInput = styled.div`
  width: 60px;
  background-color: rgba(230, 230, 230,1);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  margin-left: 8px;
  display: flex;
  background-color: ${props => props.colorBack };
`;

const TextFromMonthInput = styled.input`
  font-family: Roboto-500;
  height: 21px;
  color: #121212;
  align-self: stretch;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  border: none;
  background: transparent;
`;

const RectFromYearInput = styled.div`
  width: 90px;
  background-color: rgba(230,230,230,1);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  margin-left: 8px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  background-color: ${props => props.colorBack };
`;

const TextFromYearthInput = styled.input`
  font-family: Roboto-500;
  height: 21px;
  color: #121212;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  border: none;
  background: transparent;
`;

export default DataSelector;
