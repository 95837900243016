import React, { Component } from "react";
import styled, { css } from "styled-components";
import EntypoIcon from "react-native-vector-icons/dist/Entypo";
import { ThemeContext } from '../../contexts';
import axi from '../../functions/axiosf';
import russia from '../../assets/maps/russia.json';
import AvaIcon from './AvaIcon'
let citiesBase = russia.layers;

class ItemWithUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }

  toggleAdmin(role){
    this.toggleRoleForUser(role)
  }

  toggleRoleForUser(role){
    let p = {
      uid: this.context.user.id,
      user: +this.props.item.userID,
      role: role
    }
    axi("toggleRoleForUser.php", "null", p).then((result) => {
      if (result.type === 'nobody') {
        alert('Вы заходили под этим аккаунтом с другого устройства. Для подтверждения, что это Вы, войдите заново')
        this.context.addUser(null)
      } else {
        if (result.type == "error") {
          alert('Ошибка смены роли для пользователя ' + this.props.item.name)
        } else {
          this.props.axiGetAllUsers()
        }
      }
    },
      (e) => { console.log('ошибка смены роли', e) })
  }


  getCities(){
      this.props.openBlind(this.props.item)
  }

  render(){
  return (
    <div
      style={{
            display: 'flex',
            width: this.props.width-16,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: this.props.backColor,
            alignSelf: 'stretch',
            height: document.body.clientWidth < 900 ? 
              90:
              (this.props.openAdmin ? 90 : 60),
            transition: 'all 2s',
            marginTop: 8,
    }}
      onClick={() => { this.getCities()}}
    >
        <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          flexWrap: 'wrap',
          alignItems: 'center',
          width: document.body.clientWidth < 900 ?
            100:
            (this.props.openAdmin ? 100 : 210),
          height: document.body.clientWidth < 900 ?
            90:
            (this.props.openAdmin ? 90 : 60),
          transition: 'all 2s',
          overflow: 'hidden'
        }}
        >
        <span
          style={{
            backgroundColor: (this.props.item.role=='root')?'#ffcb17': '#fffc',
            margin: 4,
            borderRadius: 8,
            cursor: 'pointer',
            padding: 4,height:25,
          }}
          onClick={()=>{this.toggleAdmin('root')}}
        >Директор</span>        
        <span
          style={{
            backgroundColor: (this.props.item.role=='admin')?'#ffcb17': '#fffc',
            margin: 4,
            borderRadius: 8,
            cursor: 'pointer',
            padding: 4, height: 25,
          }}
          onClick={()=>{this.toggleAdmin('admin')}}
        >Админ</span>
        <span
          style={{
            backgroundColor: (this.props.item.role == '') ? '#ffcb17' : '#fffc',
            margin: 4,
            borderRadius: 8,
            cursor: 'pointer',
            padding: 4, height: 25,
          }}
          onClick={() => { this.toggleAdmin('') }}
        >Х</span>
        </div>
        <p
          style={{
            color: '#fff',
            width: document.body.clientWidth < 900 ?
             (this.props.openAdmin ? 0 : this.props.width - 206):
             (this.props.openAdmin ? this.props.width - 156 : this.props.width - 206),
            transition: 2,
            overflowX: 'hidden',
            marginLeft: document.body.clientWidth < 900 ? 0: 10,
            textAlign: 'left',
            transition: 'all 2s',
          }}
        >{this.props.item.name}</p>
        <AvaIcon 
        onClick={() => {}}
        style={{
          transition:'all 2s',
          borderRadius: 0,
          width: this.props.openAdmin ? 30 : 60,
          height: this.props.openAdmin ? 90 : 60,
          borderColor: this.state.txtColorLogIn,
          backgroundImage: this.props.item.ava ? 'url(' + this.props.item.ava + ')' : 'url("https://yt3.ggpht.com/a/AATXAJxe7LZyFkx72Rt-xxiJswJwp-3UKM0VrPl_hoqe=s900-c-k-c0xffffffff-no-rj-mo")'
        }}
        />
    </div>
  )}
}

ItemWithUser.contextType = ThemeContext;
export default ItemWithUser;
