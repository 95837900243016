import React, { Component } from "react";
import styled, { css } from "styled-components";

function LineCityToGoComponent(props) {
  return (
    <Container {...props}>
      <TxtCitiToGo>Великий новгород</TxtCitiToGo>
      <TxtNumToGo>67 человек</TxtNumToGo>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TxtCitiToGo = styled.span`
  font-family: Roboto;
  width: 85px;
  color: rgba(255,255,255,1);
  align-self: center;
  margin-right: 8px;
  margin-left: 8px;
  font-weight: regular;
  font-style: normal;
`;

const TxtNumToGo = styled.span`
  font-family: Roboto;
  width: 91px;
  height: 14px;
  color: rgba(255,255,255,1);
  margin-right: 8px;
  margin-left: 8px;
  font-weight: regular;
  font-style: normal;
  text-align: right;
`;

export default LineCityToGoComponent;
