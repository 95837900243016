import React, { Component } from "react";
import styled, { css } from "styled-components";
import { ThemeContext } from '../contexts'

class FooterComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      txtColorHome: '#d4e4d4',
      txtColorCities: '#d4e4d4',
      txtColorShop: '#d4e4d4',
      txtColorNews: '#d4e4d4',
      txtColorLogIn: '#d4e4d4',
      txtColorFAQ: '#d4e4d4',
    }
  }

  _goTo = (to) => {
    this.context.toggleLandingAnchor(to)
    if (this.props.scrollLand) {
      this.props.scrollLand()
    }
    this.props.route.history.push('/')
  }
  render() {
  return (
    <Container {...this.props} 
    style={{
      flexDirection: document.body.clientWidth < 450 ? 'column' : 'row',
      justifyContent: document.body.clientWidth < 450 ? 'flex-end' : 'space-around',
      position: this.props.style.position && this.props.style.position,
    }}
    
    >
      <FooterLogo
        style={{
                height: document.body.clientWidth < 450 ? 120 : 130,
                backgroundImage: 'url(' + require("../assets/images/logo.png")+")",
              }}/>
      <FooterMenu>
        <FootButtonNews 
          className='menuFooterButton'
          onMouseMove={() => { this.setState({ txtColorNews: '#ffcb17' }) }}
          onMouseLeave={() => { this.setState({ txtColorNews: '#d4e4d4' }) }}
          onClick={() => {
            this._goTo('News')
          }}
        >
          
          <MenuNewsTxt2 className='menuFooterButtonText' style={{ color: this.state.txtColorNews }}>Новости</MenuNewsTxt2>
          
        </FootButtonNews>
        <FootButtonShop className='menuFooterButton'
          onMouseMove={() => { this.setState({ txtColorShop: '#ffcb17' }) }}
          onMouseLeave={() => { this.setState({ txtColorShop: '#d4e4d4' }) }}
          onClick={() => {
            this._goTo('Shop')
          }}
        >
          
          <MenuShopTxt2 className='menuFooterButtonText' style={{ color: this.state.txtColorShop }}>Витрина</MenuShopTxt2>
          
        </FootButtonShop>
        <FootButtonCities 
          className='menuFooterButton'
          onMouseMove={() => { this.setState({ txtColorCities: '#ffcb17' }) }}
          onMouseLeave={() => { this.setState({ txtColorCities: '#d4e4d4' }) }}
          onClick={() => {
            this._goTo('Map')
          }}>
          
          <MenuCitiesTxt2 className='menuFooterButtonText' style={{ color: this.state.txtColorCities }}>Города</MenuCitiesTxt2>
          
        </FootButtonCities>
        <FootButtonHome 
        className='menuFooterButton'
          onMouseMove={() => { this.setState({ txtColorHome: '#ffcb17' }) }}
          onMouseLeave={() => { this.setState({ txtColorHome: '#d4e4d4' }) }}
          onClick={() => { this._goTo('Top') }}>
          
          <MenuHomeTxt2 className='menuFooterButtonText' style={{ color: this.state.txtColorHome }}>Главная</MenuHomeTxt2>
          
        </FootButtonHome>
      </FooterMenu>
      <FootContacts>
        <ContactsTitleTxt>Контакты</ContactsTitleTxt>
        <ContactsTxt>
          БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ПОДДЕРЖКИ ТВОРЧЕСТВА И СОДЕЙСТВИЯ РЕШЕНИЮ ПРОБЛЕМ ЗАЩИТЫ ЖИВОТНЫХ И ЭКОЛОГИИ &quot;ИСКУССТВО В ЗАЩИТУ ПРИРОДЫ&quot;  (c) 2020г.
          <p style={{marginTop: 2, marginBottom: 2}}>Адрес: 600000, РОССИЯ, ВЛАДИМИРСКАЯ ОБЛ, Г ВЛАДИМИР, УЛ НИКИТСКАЯ, Д 25; </p>
          <a href={'tel:+79157793587'} style={{ color: "#fff", textDecoration: 'none' }} >телефон: 89157793587</a>
        </ContactsTxt>
      </FootContacts>
      <FooterLegal>
        <LegalTitleTxt>Правовая информация</LegalTitleTxt>
        <LegalTxt>
          <a>Политика конфедециальности</a>{"\n"}
          <a>пользовательское соглашение</a> {"\n"}
          <a target="_blank" style={{ color: "#fff", textDecoration: 'none' }} href="https://vk.com/doc58859226_570718483?hash=e1d56d6af7b409ecbb&dl=789e1008c6b55d75d0">Положение о фестивале</a>
        </LegalTxt>
      </FooterLegal>
    </Container>
  );
  }
}
FooterComponent.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  //flex-direction: row;
  //justify-content: space-around;
    width: 100vw;
    height: auto;
   // position: fixed;
    bottom: 0px;
`;

const FooterLogo = styled.div`
  width: 150px;
  background-color: rgba(255,255,255,0.8);
  border-radius: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
`;

const FooterMenu = styled.div`
width: 150px;
height: 130px;
padding: 10px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  justify-content: space-around;
  margin-right: 0px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: rgba(0,0,0,0.6);
  display: flex;
`;

const FootButtonNews = styled.div``;

const MenuNewsTxt2 = styled.span``;

const FootButtonShop = styled.div``;

const MenuShopTxt2 = styled.span``;

const FootButtonCities = styled.div``;

const MenuCitiesTxt2 = styled.span``;

const FootButtonHome = styled.div``;

const MenuHomeTxt2 = styled.span``;

const FootContacts = styled.div`
  width: 450px;
  height: 116px;
  background-color: rgba(0,0,0,0.6);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  padding: 17px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  @media (max-width: 450px) {
    width: calc(100vw - 45px);
  }
`;

const ContactsTitleTxt = styled.span`
  font-family: roboto-500;
  flex: 1 1 0%;
  color: rgba(230,222,222,1);
  align-self: stretch;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 2px;
  display: flex;
  flex-direction: column;
`;

const ContactsTxt = styled.span`
  font-family: roboto-300;
  flex: 1 1 0%;
  color: rgba(230,222,222,1);
  align-self: stretch;
  font-size: 12px;
  font-weight: 100;
  font-style: normal;
  line-height: 14px;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
`;

const FooterLegal = styled.div`
  width: 350px;
height: 108px;
  background-color: rgba(0,0,0,0.6);
  flex-direction: column;
  align-self: flex-start;
  padding: 25px;
  padding-top: 17px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  @media (max-width: 450px) {
    width: calc(100vw - 45px);
  }
`;

const LegalTitleTxt = styled.span`
  font-family: roboto-500;
  flex: 1 1 0%;
  color: rgba(230,222,222,1);
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 2px;
  display: flex;
`;

const LegalTxt = styled.span`
  font-family: roboto-300;
  width: 300px;
  flex: 1 1 0%;
  color: rgba(230,222,222,1);
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 100;
  font-style: normal;
  line-height: 25px;
  letter-spacing: 1px;
  display: flex;
`;

export default FooterComponent;
