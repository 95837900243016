import React, { Component } from "react";
import styled, { css } from "styled-components";
import Header from "../components/Header";
import TabReg from "../components/TabReg";
import TicketMessage from "../components/TicketMessage"
import { ThemeContext } from '../contexts';
import LogInBlackOut from "../components/LogInBlackOut";
import PayBlackOut from "../components/cart/PayBlackOut"
import Cart from "../components/cart/Cart"
import CartForm from "../components/cart/CartForm"
import OtherGoods from "../components/cart/OtherGoods"
import axi from '../functions/axiosf';

class Cabinet extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tab: 'mess',
      approvingMessages: [],
      cart: [],
      warning: false,
      visiblePayBlackOut: false,
      payID: 0,
      amount: 1,
      calcText:'',
    }
  }

  componentDidMount() {
    //console.log(this.props)
    if (this.context.user == null) {
      this.props.history.push('/') 
    }else{
      setTimeout(() => this._axiosRefresh(this.context.user.id), 100)
      this.myUpdateInterval = setInterval(() => this._axiosRefresh(this.context.user.id), 60000)
    //тут нужно прочесть передаваемое значение от истории , чтобы выбрать актуальную вкладку
      if (this.props.location.state.tab){
        this.setState({ tab: this.props.location.state.tab})
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.myUpdateInterval);
  }

  _setApprovingMessages(m){
    this.setState({ approvingMessages: m })
  }

  _axiosRefresh(uid){
    this.setState({ warning: false })
    let p = {
      uid: uid,
      //timeStatStart: this.state.timeStatStart,
      //timeStatEnd: this.state.timeStatEnd,
    }
    axi("updateCabinetData.php", "null", p).then((result) => {
      if(result!=null){
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
          this.context.addUser(null)
        } else {
          if (result.faq != undefined) {
            this.context.addMessages(result.faq)
          }
          if (result.cart != undefined) {
            this.setState({ cart: result.cart})
          }
          if (result.orders != undefined) {
            this.context.addClientsOrders(result.orders)
          }
        }
      }
    },
      (e) => { console.log(e) })
  }

  _updateOrders(address, fio, phone, sum, calcText, regionsCalc){
    let p = {
      uid: this.context.user.id,
      cart: this.state.cart,
      lengthCart: this.state.cart.length,
      address: address,
      fio: fio,
      phone: phone,
      sum: sum,
      calcText: calcText,
      regionsCalc: regionsCalc
    }
    axi("updateUsersCart.php", "UPDATE_CONTACTS", p).then((result) => {
      if (result != null) {
        if (result.type === 'nobody') {
          alert('Вы заходили под этим аккаунтом с дргого устройства. Для подтверждения, что это Вы войдите заново')
          this.context.addUser(null)
        } else {
          if (result.type === 'sumOk') {
            //тут открываем модальное окно формой оплаты тинькофф
            //result.payId !=0
            if (result.payId !=0){
              this.setState({
                payID: result.payId, 
                visiblePayBlackOut: true,
                amount: sum,
                calcText: calcText,
                
              })
            }
          }
        }
      }
    },
      (e) => { console.log(e) })
  }

  render(){
    let marginFullBody = document.body.clientWidth < 900 ? 3 : 30
    let borderRadiusFullBody = document.body.clientWidth < 900 ? 20 : 30
    let componentWidth = document.body.clientWidth-28
    let ScrollArea2Width = document.body.clientWidth < 900 ? document.body.clientWidth - 15 : document.body.clientWidth - 60
    return (
    <Container> 
      <LogInBlackOut
        className='LogInBlackOut'
      ></LogInBlackOut>      
      {this.state.visiblePayBlackOut &&
        <PayBlackOut
          className='LogInBlackOut'
          visible={this.state.visiblePayBlackOut}
          payID={this.state.payID}
          amount={this.state.amount}
          calcText={this.state.calcText}
          toggleVisibilityPayInBlackOut={()=>{this.setState({visiblePayBlackOut: false})}}
        ></PayBlackOut>
      }
      <Header
        route={
          this.props
        }
      ></Header>
      <div style={{height: 50}}></div>
      <ScrollArea>
     
       

          <TabReg
            style={{
              alignSelf: "stretch",
              flex: "0 0 auto",
              backgroundColor: this.state.tab == 'mess' ? 'rgba(27,27,27,1)' : 'rgba(27,27,27,0.5)'
            }}
            title={"Сообщения"}
            onClick={() => { 
              this.setState({ tab: 'mess' })
            }}
          ></TabReg>
          <TabReg
            style={{
              alignSelf: "stretch",
              flex: "0 0 auto",
              backgroundColor: this.state.tab == 'cart' ? 'rgba(27,27,27,1)' : 'rgba(27,27,27,0.5)'
            }}
            title={"Корзина"}
            onClick={() => {
              this.setState({ tab: 'cart' })
            }}
          ></TabReg>
          <TabReg
            style={{
              alignSelf: "stretch",
              flex: "0 0 auto",
              backgroundColor: this.state.tab == 'history' ? 'rgba(27,27,27,1)' : 'rgba(27,27,27,0.5)'
            }}
            title={"История"}
            onClick={() => {
              this.setState({ tab: 'history' })
            }}
          ></TabReg>
            
      </ScrollArea>
      {this.state.tab == 'mess' &&
            <ScrollArea2
            style={{
              marginRight: marginFullBody,
              marginBottom: marginFullBody,
              marginLeft: marginFullBody,
              borderTopRightRadius: borderRadiusFullBody,
              borderBottomRightRadius: borderRadiusFullBody,
              borderBottomLeftRadius: borderRadiusFullBody,
              width: ScrollArea2Width,
            }}
            >
              {this.context.messages&&
                this.context.messages.map((item, index)=>{
                  let cityName = ""
                  this.context.base.cities.map((it,id)=>{
                    if (it.idCity == item.idCity) {cityName=it.name}
                  })
                  return(
                  <TicketMessage
                    key={index}
                    item={item}
                    city={cityName}
                  />)
                })
              }

            </ScrollArea2>
      }
      {this.state.tab == 'cart' &&
        <ScrollArea2
          style={{
            marginRight: marginFullBody,
            marginBottom: marginFullBody,
            marginLeft: marginFullBody,
            borderTopRightRadius: borderRadiusFullBody,
            borderBottomRightRadius: borderRadiusFullBody,
            borderBottomLeftRadius: borderRadiusFullBody,
            width: ScrollArea2Width,
          }}>
            <Cart
              cart={this.state.cart}
              addCart={(cart)=>{this.setState({cart: cart})}}
              uid={this.context.user.id}
            />
            <CartForm
            cart={this.state.cart}
            addCart={(cart) => { this.setState({ cart: cart }) }}
            user={this.context.user}
            placeTheOrders={(address, fio, phone, summ, culcText, regionsCalc) => { this._updateOrders(address, fio, phone, summ, culcText, regionsCalc)}}
            />
            <OtherGoods
              route={this.props}
              updateCabinet={() => this._axiosRefresh(this.context.user.id)}
            />   
        </ScrollArea2>
      }
      {this.state.tab == 'history' &&
        <ScrollArea2
          style={{
            marginRight: marginFullBody,
            marginBottom: marginFullBody,
            marginLeft: marginFullBody,
            borderTopRightRadius: borderRadiusFullBody,
            borderBottomRightRadius: borderRadiusFullBody,
            borderBottomLeftRadius: borderRadiusFullBody,
            width: ScrollArea2Width,
          }}
        >
        
        </ScrollArea2>
      }
    </Container>
  );
  }
}
Cabinet.contextType = ThemeContext;

const Container = styled.div`
  display: flex;
  background-color: rgba(60,60,60,1);
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`;

const ScrollArea = styled.div`
  overflow-x:   auto;
  height: 60px;
  flex-direction: row;
  align-self: stretch;
  justify-content: flex-start;
  margin-top: 15px;
  display: flex;
`;

const ScrollArea2 = styled.div`
  overflow-y: scroll;
  flex: 1 1 0%;
  background-color: rgba(27,27,27,1);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
  height: 1135px;
  display: flex;
`;

const TextInput = styled.input`
  font-family: roboto-500;
  font-style: normal;
  font-weight: regular;
  color: #121212;
  width: 340px;
  height: 40px;
  font-size: 17px;
  line-height: 18px;
  border: none;
  background: transparent;
`;
export default Cabinet;
